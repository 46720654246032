import React, { useState, useEffect } from "react"
import moment from "moment"
import useVenueStore from "../../data/venueStore"
import useTeamStore from "../../data/teamStore"

import homepageService from "../../data/homepageService"
import useSeasonStore from "../../data/seasonStore"

import Modal from "../Modal/Modal"
import Game from "../../pages/Game/Game"

export default function RecentGames({
    currentRecentGame,
    setCurrentRecentGame,
}) {
    const activeSeason = useSeasonStore.getState().activeSeason
    const [recentGames, setRecentGames] = useState([])
    const [currentRecentGamesPage, setCurrentRecentGamesPage] = useState(1)
    useEffect(() => {
        let isMounted = true

        homepageService.getRecentGames(currentRecentGamesPage).then(games => {
            if (isMounted) {
                setRecentGames(games)
            }
        })
        return () => {
            isMounted = false
        }
    }, [activeSeason])

    useEffect(() => {
        setRecentGames([
            { venue: "Loading" },
            { venue: "Loading" },
            { venue: "Loading" },
            { venue: "Loading" },
            { venue: "Loading" },
        ])
        homepageService.getRecentGames(currentRecentGamesPage).then(games => {
            setRecentGames(games)
        })
    }, [currentRecentGamesPage])

    return (
        <div className="home__recent-games">
            <h2>Recent Games</h2>
            <div className="home__recent-games-list">
                {recentGames?.map((game, index) => (
                    <div
                        key={index}
                        className={`home__recent-game ${index === 0 && "gray"}`}
                        onClick={() => setCurrentRecentGame(game.id)}
                    >
                        <h3 className="home__recent-game-venue">
                            {game.venue !== "Loading"
                                ? useVenueStore
                                      .getState()
                                      .venues.find(
                                          venue => venue.id === game.venue
                                      )?.name || "Retired Venue"
                                : "Loading"}
                        </h3>
                        <p className="home__recent-game-city-state">
                            {game.venue !== "Loading"
                                ? `${
                                      useVenueStore
                                          .getState()
                                          .venues.find(
                                              venue => venue.id === game.venue
                                          )?.city || "Retired"
                                  }, ${
                                      useVenueStore
                                          .getState()
                                          .venues.find(
                                              venue => venue.id === game.venue
                                          )?.state || "Location"
                                  }`
                                : "Loading"}
                        </p>
                        <span className="home__recent-game-date">
                            <span className="home__recent-game-date-month">
                                {moment(game.date).format("MMM")}
                            </span>
                            <span className="home__recent-game-date-day">
                                {game.date && moment(game.date).format("DD")}
                            </span>
                        </span>
                        <ul className="home__recent-game-standings">
                            {game.scores?.map((score, index) => (
                                <li
                                    key={index}
                                    className={`home__recent-game-standing home__recent-game-standing__${
                                        index === 0
                                            ? "first"
                                            : index === 1
                                            ? "second"
                                            : "third"
                                    }`}
                                >
                                    <span className="home__recent-game-standing-place">
                                        {index + 1} -{" "}
                                    </span>
                                    <span className="home__recent-game-standing-team">
                                        {
                                            useTeamStore
                                                .getState()
                                                .teams.find(
                                                    team =>
                                                        team.id === score.team
                                                )?.name
                                        }
                                    </span>
                                    <span className="home__recent-game-standing-score">
                                        {score.score}
                                    </span>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
            <div className="home__recent-game-pagination">
                <button
                    className={`home__recent-game-pagination-btn home__recent-game-pagination-prev splash-bg splash-border ${
                        currentRecentGamesPage === 1 && "disabled"
                    }`}
                    disabled={currentRecentGamesPage === 1}
                    onClick={() =>
                        setCurrentRecentGamesPage(currentRecentGamesPage - 1)
                    }
                >
                    Prev
                </button>
                <button
                    className="home__recent-game-pagination-btn home__recent-game-pagination-next splash-bg splash-border"
                    onClick={() =>
                        setCurrentRecentGamesPage(currentRecentGamesPage + 1)
                    }
                    disabled={currentRecentGamesPage >= 100}
                >
                    Next
                </button>
            </div>
            <div className="home__recent-game-pagination-details">
                <span className="home__recent-game-pagination-page">
                    Page {currentRecentGamesPage}
                </span>
            </div>

            {currentRecentGame !== 0 && (
                <Modal
                    title="Game Details"
                    onClose={() => {
                        setCurrentRecentGame(0)
                    }}
                    className="game-modal small-header"
                >
                    <Game id={currentRecentGame} />
                </Modal>
            )}
        </div>
    )
}
