import React from "react"
import "./AnswerSheet.css"
import useOneGameStore from "../../data/oneGameStore"
import useGameStore from "../../data/gameStore"
import useTeamStore from "../../data/teamStore"
import useCategoryStore from "../../data/categoryStore"
import { Link } from "react-router-dom"

import { gameOptions } from "../../utils/dateUtils"
import TeamPage from "../Team"

const AnswerSheet = ({ scoreCard, onClose, legacy, isMember }) => {
    const gameScores =
        useOneGameStore
            .getState()
            .game?.teams?.find(team => team.team === scoreCard)?.scores || []
    const gameRounds = useOneGameStore.getState().game?.rounds || []

    return (
        <div className="answer-sheet-content">
            <header className="answer-sheet-header">
                {isMember && <h1>Answer Sheet</h1>}
                <Link className="visit-team-page" to={`/team/${scoreCard}`}>
                    Visit Team Page
                </Link>
            </header>
            {isMember && (
                <div className="rounds-container">
                    {gameRounds.map((round, index) => (
                        <div key={index} className="answer-sheet-round">
                            <h3
                                className={`answer-sheet-round-title ${useCategoryStore
                                    .getState()
                                    .categories.find(
                                        category =>
                                            category.id === round.category
                                    )
                                    ?.name.toLowerCase()
                                    .replace(" ", "-")}-background`}
                            >
                                {" "}
                                Round {round.round} -{" "}
                                {
                                    useCategoryStore
                                        .getState()
                                        .categories.find(
                                            category =>
                                                category.id === round.category
                                        )?.name
                                }{" "}
                                -{" "}
                                {gameScores.find(gs => gs.round === round.round)
                                    ? gameScores
                                          .find(gs => gs.round === round.round)
                                          .questions.filter(q => q.correct)
                                          .length
                                    : 0}{" "}
                                /{" "}
                                {legacy
                                    ? gameOptions.legacyQuestionsPerRound[
                                          round.round - 1
                                      ]
                                    : gameOptions.questionsPerRound[
                                          round.round - 1
                                      ]}
                            </h3>
                            <ul>
                                {gameScores.find(gs => gs.round === round.round)
                                    ? gameScores
                                          .find(gs => gs.round === round.round)
                                          .questions.map((question, i) => (
                                              <li
                                                  className={`answer-sheet-question ${
                                                      question.correct
                                                          ? "correct"
                                                          : "incorrect"
                                                  }`}
                                                  key={i}
                                              >
                                                  {question.question}
                                              </li>
                                          ))
                                    : null}
                            </ul>
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}

export default AnswerSheet
