import React, { useEffect, useState } from "react"

import useCategoryStore from "../../../data/categoryStore"
import useGameStore from "../../../data/gameStore"
import useTemplateStore from "../../../data/templateStore"
import useOneGameStore from "../../../data/oneGameStore"

export default function Question() {
    const template = useTemplateStore(state => state.template)
    const localGame = JSON.parse(localStorage.getItem("smt-game"))
    const currentSlide = localGame?.currentSlide
    const [question, setQuestion] = React.useState(null)
    const [fontSize, setFontSize] = useState("7vw") // Default font size
    const categories = useCategoryStore(state => state.categories)
    const currentRound = useOneGameStore(state =>
        state.getCurrentRoundBySlide(currentSlide)
    )
    const category =
        (localGame.rounds && localGame.rounds[currentRound - 1]?.category) || 0

    const currentQuestion =
        currentSlide -
        useOneGameStore(
            state => state.rounds.find(r => r.round === currentRound)?.slide
        ) -
        1

    useEffect(() => {
        if (
            template &&
            template.questions &&
            template.questions[category] &&
            template.questions[category][currentQuestion]
        ) {
            const qObj = template.questions[category][currentQuestion]

            setQuestion(qObj)

            // Adjust font size based on the question length
            const textLength = qObj.question.length
            if (textLength > 150) {
                setFontSize("5vw") // Smaller font for long text
            } else if (textLength > 100) {
                setFontSize("6vw")
            } else if (textLength > 50) {
                setFontSize("7vw")
            } else {
                setFontSize("7vw") // Default size
            }
        }
    }, [currentQuestion, category, template])

    return question ? (
        <div className="slide question-slide">
            <span className="question-slide-number">
                {currentQuestion + 1}:
            </span>
            <h1 className="question-slide-title">
                {categories.find(c => c.id === category)?.name}
            </h1>
            <div
                className={`question-slide-question ${categories
                    .find(c => c.id === category)
                    ?.name.toLowerCase()
                    .replace(" ", "-")}-background-transparent`}
            >
                <span
                    className="question-slide-question-text"
                    style={{ fontSize: fontSize, lineHeight: fontSize }}
                >
                    {question.question}
                </span>
            </div>
        </div>
    ) : (
        <div className="slide question-slide">
            <span className="question-slide-number">?:</span>
            <h1 className="question-slide-title">No Category</h1>
            <div className="question-slide-question">
                <span className="question-slide-question-text">
                    No Question
                </span>
            </div>
        </div>
    )
}
