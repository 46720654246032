import { useState, useEffect } from "react"
import { isLegacyGame } from "../../utils/dateUtils"

/**
 * Custom hook to determine the status of the game.
 *
 * @param {object} game - The game object.
 * @returns {object} An object containing the legacy and live status of the game.
 */
const useGameStatus = (game,venue) => {
    const [legacy, setLegacy] = useState(false)
    const [live, setLive] = useState(false)
    const [upcoming, setUpcoming] = useState(false)

    useEffect(() => {
        if (game) {
            setLegacy(isLegacyGame(game))
            setLive(game.status !== "finalized")
        } else {
            setLegacy(false)
            setLive(venue !== false)
            setUpcoming(venue !== false)
        }
    }, [game])

    return { legacy, live, upcoming }
}

export default useGameStatus
