import React from "react"

export default function HowItWorks() {
    return (
        <div className="slide how-it-works-slide">
            <h1 className="slide-title">How It Works</h1>
            <div className="slide-black-box">
                <ul className="slide-rules-list">
                    <li>Seven rounds of intense trivia competition</li>
                    <li>
                        The rounds will be presented as a choice between
                        <strong> History</strong>, <strong>Pop Culture</strong>,{" "}
                        <strong>Science</strong>, <strong>Places</strong>,{" "}
                        <strong>Sports</strong>, <strong>Movies</strong>,{" "}
                        <strong>Music</strong>, and a <strong>Mystery</strong>{" "}
                        category.
                    </li>
                    <li>All correct answers are worth 1 point each</li>
                    <li>
                        If you miss a question, don’t worry! They will continue
                        to cycle on the screen!
                    </li>
                </ul>
            </div>
        </div>
    )
}
