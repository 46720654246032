import React, { useState } from "react"
import { RiCoinLine } from "react-icons/ri"
import { GiTwoCoins, GiCoins } from "react-icons/gi"
import { BiSolidCoinStack } from "react-icons/bi"
import { FaCoins } from "react-icons/fa6"
import { SiMinutemailer } from "react-icons/si"
import {
    PaymentForm,
    CreditCard,
    GooglePay,
} from "react-square-web-payments-sdk"
import { getFunctions, httpsCallable } from "firebase/functions"

import dataService from "../../data/dataService"

import moment from "moment"

import useTransactionStore from "../../data/transactionStore"

import "./PurchaseCredits.css"

const PurchaseCredits = ({
    user,
    userData,
    setGameCredits,
    gameCredits,
    setShowPurchaseModal,
    newHost = false,
}) => {
    const [purchaseAmount, setPurchaseAmount] = useState(0)
    const [creditsForPurchase, setCreditsForPurchase] = useState(0)
    const [purchasing, setPurchasing] = useState(false)

    const squareApplicationID = process.env.REACT_APP_SQUARE_APPLICATION_ID
    const squareLocationID = process.env.REACT_APP_SQUARE_LOCATION_ID

    const sandbox = false

    const functions = getFunctions()
    // connectFunctionsEmulator(functions, "localhost", 5001)

    const purchaseCredits = async (credits, amount, token) => {
        try {
            const note = `${user.uid} - ${userData.name}`
            const processResult = await processPayment(amount, note, token)
            if (processResult.success) {
                setGameCredits(isNaN(gameCredits) ? 0 : gameCredits + credits)
                setShowPurchaseModal(false)
                // Update user data in the database
                dataService.saveField(
                    userData.id,
                    "gameCredits",
                    gameCredits + credits,
                    "users",
                    () => {}
                )
                dataService.insertIntoCollection("transactions", {
                    receiver: user.uid,
                    giver: "~~POOL~~",
                    amount,
                    credits,
                    date: moment().format("YYYY-MM-DD"),
                    time: moment().format("HH:mm:ss"),
                    type: "purchase",
                    status: processResult.payment.status,
                    note: processResult.payment.id,
                })
                setCreditsForPurchase(0)
                setPurchaseAmount(0)
                setPurchasing(false)
                useTransactionStore
                    .getState()
                    .getAllTransactionsByUser(user.uid)
                if (
                    !userData.hasOwnProperty("role") ||
                    userData?.role === "user"
                ) {
                    await dataService.saveField(
                        user.id,
                        "role",
                        "host",
                        "users",
                        () => {}
                    )
                    alert(
                        'You are now a host! Click "OK" to continue to your host dashboard.'
                    )
                    window.location.href = "/host-dashboard?help=true"
                }
            } else {
                console.log("Payment failed", processResult)
                setPurchasing(false)
                alert("Payment failed. Please try again.")
            }
        } catch (error) {
            console.log("Try / catch failed", error)
            setPurchasing(false)
            alert("Payment failed. Please try again.")
        }
    }

    const processPayment = async (amount, note, token) => {
        try {
            if (token.status === "OK") {
                const processPaymentFunction = httpsCallable(
                    functions,
                    sandbox ? "processPaymentDev" : "processPayment"
                )
                const result = await processPaymentFunction({
                    amount: amount,
                    sourceId: token.token,
                    note: note,
                })
                return result.data
            } else {
                throw new Error("Tokenization failed")
            }
        } catch (error) {
            console.error("Error processing payment:", error)
            throw error
        }
    }

    return (
        <>
            <h2>Purchase Credits</h2>
            <div className="purchase-options">
                <button
                    onClick={() => {
                        setPurchaseAmount(30)
                        setCreditsForPurchase(1)
                    }}
                >
                    <span className="game-credit-label">1 Credit</span>{" "}
                    <RiCoinLine
                        className={creditsForPurchase === 1 ? "current" : ""}
                    />
                    <span className="game-credit-amount">$30</span>
                </button>
                <button
                    onClick={() => {
                        setPurchaseAmount(110)
                        setCreditsForPurchase(4)
                    }}
                >
                    <span className="game-credit-label">4 Credits</span>{" "}
                    <GiTwoCoins
                        className={creditsForPurchase === 4 ? "current" : ""}
                    />
                    <span className="game-credit-amount">$110</span>
                </button>
                <button
                    onClick={() => {
                        setPurchaseAmount(320)
                        setCreditsForPurchase(12)
                    }}
                >
                    <span className="game-credit-label">12 Credits</span>{" "}
                    <BiSolidCoinStack
                        className={creditsForPurchase === 12 ? "current" : ""}
                    />
                    <span className="game-credit-amount">$320</span>
                </button>
                <button
                    onClick={() => {
                        setPurchaseAmount(600)
                        setCreditsForPurchase(24)
                    }}
                >
                    <span className="game-credit-label">24 Credits</span>{" "}
                    <FaCoins
                        className={creditsForPurchase === 24 ? "current" : ""}
                    />
                    <span className="game-credit-amount">$600</span>
                </button>
                <button
                    onClick={() => {
                        setPurchaseAmount(1200)
                        setCreditsForPurchase(52)
                    }}
                >
                    <span className="game-credit-label">52 Credits</span>{" "}
                    <GiCoins />
                    <span className="game-credit-amount">$1200</span>
                </button>
                <button
                    onClick={() =>
                        (window.location.href =
                            "mailto:admin@triviatakeover.live?subject=CUSTOM%20CREDITS")
                    }
                >
                    <span className="game-credit-label">??? Credits</span>{" "}
                    <SiMinutemailer />
                    <span className="game-credit-amount">Custom</span>
                </button>
            </div>
            {purchaseAmount > 0 && (
                <>
                    <p className="purchase-title">
                        Purchasing: {creditsForPurchase} Game Credits for $
                        {purchaseAmount}
                    </p>
                    <div className="purchase-credits-payment-form">
                        {purchasing && (
                            <span className="purchasing-blocker">
                                Purchasing...
                            </span>
                        )}
                        <PaymentForm
                            applicationId={squareApplicationID}
                            cardTokenizeResponseReceived={(
                                token,
                                verifiedBuyer
                            ) => {
                                setPurchasing(true)
                                purchaseCredits(
                                    creditsForPurchase,
                                    purchaseAmount,
                                    token
                                )
                            }}
                            createPaymentRequest={() => ({
                                countryCode: "US",
                                currencyCode: "USD",
                                total: {
                                    amount: `${purchaseAmount}`,
                                    label: "Total",
                                },
                            })}
                            locationId={squareLocationID}
                        >
                            {/* <ApplePay /> */}
                            <GooglePay />
                            <CreditCard />
                        </PaymentForm>
                    </div>
                </>
            )}
        </>
    )
}

export default PurchaseCredits
