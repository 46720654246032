import React from "react"

import useCategoryStore from "../../../data/categoryStore"
import useOneGameStore from "../../../data/oneGameStore"
import useTeamStore from "../../../data/teamStore"

export default function CategorySelection({
    view = "slideshow",
    handleCategoryClick,
    handleTeamClick = () => {},
}) {
    const game = useOneGameStore(state => state.game)
    const currentRound = useOneGameStore.getState().getCurrentRound()
    const lowestScoringTeam =
        game?.rounds?.find(r => r.round === currentRound)?.team || {}
    const matchingTeam = game?.teams.find(t => t.team === lowestScoringTeam)
    const alreadySelectedCategories =
        (game?.rounds && game?.rounds.map(round => round.category)) || []

    return (
        <div className="slide category-selection-slide">
            {useCategoryStore
                .getState()
                .categories.filter(c => c.active)
                .map((category, index) => {
                    return (
                        <div
                            key={index}
                            className={`category-selection-item ${category.name
                                .toLowerCase()
                                .replace(" ", "-")}-background-transparent ${
                                alreadySelectedCategories.includes(category.id)
                                    ? "disabled"
                                    : ""
                            }`}
                            onClick={() => {
                                handleCategoryClick(
                                    category.id,
                                    lowestScoringTeam.team
                                )
                            }}
                        >
                            <span className="category-selection-name">
                                {category.name}
                            </span>
                        </div>
                    )
                })}
            <div className="category-selection-item selected-team">
                <span
                    className="selected-team-name"
                    onClick={() => {
                        if (view === "liveScoring") {
                            handleTeamClick()
                        }
                    }}
                >
                    {matchingTeam?.displayName
                        ? matchingTeam?.displayName
                        : useTeamStore
                              .getState()
                              .teams.find(t => t.id === lowestScoringTeam)
                              ?.name}
                </span>
            </div>
        </div>
    )
}
