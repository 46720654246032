import create from "zustand"
import dataService from "./dataService"
import zukeeper from "zukeeper"

const useGameStore = create(
    zukeeper((set, get) => ({
        gameID: false,
        game: {},
        gameScores: [],
        gameTeams: [],
        gameRounds: [],
        scores: [],
        liveScoring: false,
        setLiveScoring: liveScoring => {
            set({ liveScoring })
        },
        getGame: (gameID, cb) => {
            dataService.getGame(gameID).then(game => {
                if (game?.teams) {
                    // this is the new game object format
                    set({
                        gameID: gameID,
                        game: game,
                        gameScores: game.teams.map(team => team.scores).flat(),
                        gameTeams: game.teams,
                        gameRounds: game.rounds.sort(
                            (a, b) => a.round - b.round
                        ),
                        scores: game.teams.map(team => {
                            return {
                                team: team.team,
                                displayName: team.displayName,
                                score: team.scores.reduce((acc, round) => {
                                    // Calculate the number of correct answers in each round
                                    const correctAnswers =
                                        round.questions.reduce(
                                            (roundAcc, question) => {
                                                return (
                                                    roundAcc +
                                                    (question.correct ? 1 : 0)
                                                )
                                            },
                                            0
                                        )
                                    // Add the correct answers from this round to the overall score
                                    return acc + correctAnswers
                                }, 0),
                                venue: game.venue,
                                date: game.date,
                                season: game.season,
                            }
                        }),
                    })
                    cb(game.rounds.length)
                } else {
                    dataService.getGameDetails(gameID).then(data => {
                        set({
                            gameID: gameID,
                            game: data.game,
                            gameScores: data.gameScores,
                            gameTeams: data.gameTeams,
                            gameRounds: data.gameRounds.sort(
                                (a, b) => a.round - b.round
                            ),
                        })
                        dataService.getScoresForGame(data.game).then(scores => {
                            set({ scores })
                            cb(data.gameRounds.length)
                        })
                    })
                }
            })
        },
        resetGame: () => {
            set({
                gameID: false,
                game: {},
                gameScores: [],
                gameTeams: [],
                gameRounds: [],
                scores: [],
            })
        },
        getScoresForGame: async game => {
            const scores = await dataService.getScoresForGame(game)
            set({ scores })
        },
        getGameFromVenueSeasonDateHost: async (venue, season, date) => {
            const game = await dataService.getGameFromVenueSeasonDateHost(
                venue,
                season,
                date
            )
            set({ game })
        },
        nextSlide: () => {
            dataService.saveField(
                get().gameID,
                "currentSlide",
                get().game.currentSlide + 1,
                "games",
                () => {}
            )
            set(state => {
                const game = {
                    ...state.game,
                    currentSlide: state.game.currentSlide + 1,
                }
                return { game }
            })
        },
        prevSlide: () => {
            dataService.saveField(
                get().gameID,
                "currentSlide",
                get().game.currentSlide - 1,
                "games",
                () => {}
            )
            set(state => {
                const game = {
                    ...state.game,
                    currentSlide: state.game.currentSlide - 1,
                }
                return { game }
            })
        },
    }))
)

window.store = useGameStore

export default useGameStore
