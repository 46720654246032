import React from "react"

export default function Rules() {
    return (
        <div className="slide rules-slide">
            <h1 className="slide-title">How It Works</h1>
            <div className="slide-black-box">
                <ul className="slide-rules-list">
                    <li>
                        Answer sheets are provided for you, collaborate with
                        your team during the allotted time per round to arrive
                        at your answer, then turn in your answer sheet.
                    </li>
                    <li>
                        Please, no outside materials are to be used during
                        trivia rounds. To maintain the integrity of the game and
                        ensure a fun time for all players, phones, textbooks,
                        notebooks, etc. are strictly forbidden during trivia
                        rounds.
                    </li>
                    <li>
                        <strong>
                            The trivia host is always right, and all of my
                            decisions are final.
                        </strong>
                    </li>
                </ul>
            </div>
        </div>
    )
}
