import create from "zustand"
import {
    collection,
    getDocs,
    query,
    where,
    orderBy,
    or,
    updateDoc,
} from "firebase/firestore"
import { db } from "../firebase"

const useTransactionStore = create(set => ({
    transactions: [],
    setTransactions: transactions => set({ transactions }),
    getTransactions: async () => {
        const q = query(collection(db, "transactions"), orderBy("date", "desc"))
        const querySnapshot = await getDocs(q)
        const transactions = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }))
        set({ transactions })
    },
    getReceiverTransactionsByUser: async userId => {
        const q = query(
            collection(db, "transactions"),
            where("receiver", "==", userId),
            orderBy("date", "desc")
        )
        const querySnapshot = await getDocs(q)
        const transactions = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }))
        set({ transactions })
    },
    getGiverTransactionsByUser: async userId => {
        const q = query(
            collection(db, "transactions"),
            where("giver", "==", userId),
            orderBy("date", "desc")
        )
        const querySnapshot = await getDocs(q)
        const transactions = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }))
        set({ transactions })
    },
    getAllTransactionsByUser: async userId => {
        const q = query(
            collection(db, "transactions"),
            or(where("receiver", "==", userId), where("giver", "==", userId)),
            orderBy("date", "desc")
        )
        const querySnapshot = await getDocs(q)
        const transactions = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }))
        set({ transactions })
    },
    addCreditsToUser: async (uid, credits) => {
        const q = query(collection(db, "users"), where("uid", "==", uid))
        const querySnapshot = await getDocs(q)
        querySnapshot.forEach(async doc => {
            const existingCredits = doc.data().gameCredits
                ? doc.data().gameCredits
                : 0
            const userRef = doc.ref
            await updateDoc(userRef, {
                gameCredits: Number(existingCredits) + Number(credits),
            })
        })
    },
}))

export default useTransactionStore
