import create from "zustand"
import {
    collection,
    getDocs,
    query,
    where,
    orderBy,
    doc,
    updateDoc,
} from "firebase/firestore"
import { db } from "../firebase"

const useScoreStore = create(set => ({
    scores: [],
    setScores: scores => set({ scores }),
    mostRecentScore: null,
    getMostRecentScoreWithoutAGame: async season => {
        const q = query(
            collection(db, "scores"),
            orderBy("date", "desc"),
            where("season", "==", season)
        )
        const querySnapshot = await getDocs(q)
        const scores = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }))
        set({
            mostRecentScore: scores.filter(
                score => score.game === undefined || score.game === null
            )[0],
        })
    },
    getOtherScoresWithSameVenueSeasonDateHost: async (
        venue,
        season,
        date
    ) => {
        const q = query(
            collection(db, "scores"),
            where("venue", "==", venue),
            where("season", "==", season),
            where("date", "==", date),
        )
        const querySnapshot = await getDocs(q)
        const scores = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }))
        set({ scores })
    },
    updateScoreWithGameID: async (scoreID, gameID) => {
        const scoreRef = doc(db, "scores", scoreID)
        await updateDoc(scoreRef, { game: gameID })
    },
}))

export default useScoreStore
