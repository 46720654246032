import React, { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import {
    auth,
    logInWithEmailAndPassword,
    signInWithGoogle,
} from "../../firebase"
import { FcGoogle } from "react-icons/fc"
import { useAuthState } from "react-firebase-hooks/auth"
import "./Login.css"
import useUserStore from "../../data/userStore"
import useHeaderStore from "../../data/headerStore"

export default function Login() {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [user, loading, error] = useAuthState(auth)
    const navigate = useNavigate()
    const searchParams = new URLSearchParams(window.location.search)
    const redirectTo = searchParams.get("redirectTo")
    const [currentFeature, setCurrentFeature] = useState(0)

    const features = [
        {
            img: "catMedals",
            description:
                "Unranked, Silver, Bronze, Gold, Platinum, Diamond, Mastery",
        },
        { img: "catAccuracy", description: "Accuracy Percentage by Category" },
        { img: "recentGames", description: "Recent Games with Rankings" },
    ]

    // now rotate the features every 5 seconds

    useEffect(() => {
        useHeaderStore.getState().setShowHeader(false)
        return () => useHeaderStore.getState().setShowHeader(true)
    }, [redirectTo])

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentFeature(
                currentFeature === features.length - 1 ? 0 : currentFeature + 1
            )
        }, 5000)
        return () => clearInterval(interval)
    }, [currentFeature, features.length])

    useEffect(() => {
        if (loading) {
            // maybe trigger a loading screen
            return
        }
        if (user) {
            useUserStore.getState().setCurrentUser(user)
            navigate(redirectTo || "/")
        }
    }, [user, loading, navigate, redirectTo])
    return (
        <div className="login">
            {error && <div>Error: {error}</div>}
            <div className="login__container gray">
                <div
                    className="login_left"
                    style={{
                        backgroundImage: `url(/images/newFeatures/${features[currentFeature].img}.jpg)`,
                    }}
                >
                    {/* <h2 className="recently-added-features">
                        Recently Added Features
                    </h2> */}
                    <span className="new-feature-description">
                        {features[currentFeature].description}
                    </span>
                </div>
                <div className="login_right">
                    <div className="login__spacer">
                        <h2>Login</h2>
                        <p className="register-text">
                            Don't have an account?{" "}
                            <Link
                                to={`/register${
                                    redirectTo
                                        ? `?redirectTo=${redirectTo}`
                                        : ""
                                }`}
                            >
                                Register
                            </Link>{" "}
                        </p>

                        <input
                            type="text"
                            className="login__textBox black-text"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            placeholder="E-mail Address"
                        />
                        <input
                            type="password"
                            className="login__textBox black-text"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            placeholder="Password"
                        />
                        <div className="forgot-password">
                            <Link to="/reset">Forgot Password?</Link>
                        </div>
                        <button
                            className="login__btn splash-bg"
                            onClick={() =>
                                logInWithEmailAndPassword(email, password)
                            }
                        >
                            Login
                        </button>
                    </div>
                    <div className="login__spacer">
                        <div className="or">
                            <hr />
                            <span className="or-text">or login with</span>
                            <hr />
                        </div>
                        <button
                            className="login__btn login__google"
                            onClick={signInWithGoogle}
                        >
                            <FcGoogle /> <span>Google</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
