import Footer from "./Footer"
import Header from "./Header"
import useLoadingStore from "../../data/loadingStore"
import { Loading } from "../Loading"
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"

export default function Layout({ children, dark, setDark }) {
    const loading = useLoadingStore.getState().loading
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024)

    const location = useLocation()

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1024)
        }

        window.addEventListener("resize", handleResize)
        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [])

    const convertPathNameToCSSClass = pathname => {
        const path = pathname.split("/")[1]
        if (pathname === "/") {
            return "home"
        }
        return path
    }

    return (
        <>
            <Header dark={dark} setDark={setDark} isMobile={isMobile} />
            {loading && <Loading />}
            <main
                className={`main-content ${dark && "dark"} ${
                    isMobile && "mobile"
                } main-${convertPathNameToCSSClass(location.pathname)}`}
            >
                {children}
            </main>
            <Footer dark={dark} setDark={setDark} isMobile={isMobile} />
        </>
    )
}
