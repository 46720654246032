import {
    collection,
    or,
    doc,
    addDoc,
    getDocs,
    updateDoc,
    query,
    where,
    deleteDoc,
    documentId,
    getDoc,
} from "firebase/firestore"
import { db } from "../firebase"
import moment from "moment"
import { Timestamp } from "firebase/firestore"

const dataService = {
    getData: async (collectionName, setLoading) => {
        setLoading(true)
        let newData
        await getDocs(collection(db, collectionName)).then(QuerySnapshot => {
            newData = QuerySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }))
            setLoading(false)
        })
        return newData
    },
    updateField: async (id, key, value, setter, data) => {
        setter(
            data.map(item => {
                if (item.id === id) {
                    return {
                        ...item,
                        [key]: value,
                    }
                }
                return item
            })
        )
    },
    saveField: async (id, key, value, collection, updating) => {
        updating(true)
        const dataRef = doc(db, collection, id)
        await updateDoc(dataRef, {
            [key]: value,
        })
        updating(false)
    },

    deleteDoc: async (id, collection, setter, data) => {
        setter(data.filter(item => item.id !== id))
        await deleteDoc(doc(db, collection, id))
    },
    getScoresForTeam: async teamID => {
        const q = query(collection(db, "scores"), where("team", "==", teamID))
        const querySnapshot = await getDocs(q)
        return querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }))
    },
    getScoresForUser: async userID => {
        const howFarBack = moment().subtract(100, "days").format("YYYY-MM-DD")
        const q = query(
            collection(db, "games"),
            or(
                where("date", ">=", howFarBack) // string comparison
            )
        )

        const querySnapshot = await getDocs(q)
        const games = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }))
        const userGames = games.filter(game =>
            game.teams?.find(team => team.members?.includes(userID))
        )
        const userScores = []
        for (const game of userGames) {
            const gameScores = await dataService.getScoresForGame(game)
            userScores.push(
                ...gameScores.filter(
                    score =>
                        score.team ===
                        game.teams.find(team => team.members.includes(userID))
                            .team
                )
            )
        }
        return userScores
    },
    getUser: async userID => {
        const q = query(collection(db, "users"), where("uid", "==", userID))
        const querySnapshot = await getDocs(q)
        return querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }))[0]
    },
    getUserByEmail: async email => {
        const q = query(collection(db, "users"), where("email", "==", email))
        const querySnapshot = await getDocs(q)
        return querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }))[0]
    },
    getTeam: async teamID => {
        const q = query(
            collection(db, "teams"),
            where("__name__", "==", teamID)
        )
        const querySnapshot = await getDocs(q)
        return querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }))
    },
    getTeamGames: async teamID => {
        const q = query(
            collection(db, "gameteams"),
            where("team", "==", teamID)
        )
        const querySnapshot = await getDocs(q)
        const teamGames = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }))
        const uniqueGameIDs = [...new Set(teamGames.map(game => game.game))]
        if (uniqueGameIDs.length === 0) return []
        const q2 = query(
            collection(db, "games"),
            where(documentId(), "in", uniqueGameIDs)
        )
        /*
         the line above gave me an error f

            

         */
        const querySnapshot2 = await getDocs(q2)
        const games = querySnapshot2.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }))
        // return only the teamGames whose games are finalized
        return teamGames.filter(teamGame =>
            games.find(
                game => game.id === teamGame.game && game.status === "finalized"
            )
        )
    },
    getChampionship: async seasonID => {
        const q = query(
            collection(db, "championships"),
            where("season", "==", seasonID)
        )
        const querySnapshot = await getDocs(q)
        return querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }))[0]
    },
    getTeamRank: async (teamID, seasonID) => {
        const q = query(
            collection(db, "scores"),
            where("season", "==", seasonID)
        )
        const querySnapshot = await getDocs(q)
        const scores = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }))
        const uniqueTeamIDs = [...new Set(scores.map(score => score.team))]
        const teamScores = uniqueTeamIDs.map(teamID => ({
            teamID,
            total: scores
                .filter(score => score.team === teamID)
                .reduce((acc, score) => acc + score.score, 0),
        }))
        const sortedTeamScores = teamScores.sort((a, b) => b.total - a.total)
        const teamRank = sortedTeamScores.findIndex(
            teamScore => teamScore.teamID === teamID
        )
        return {
            currentRank: teamRank + 1,
            totalTeams: sortedTeamScores.length,
        }
    },
    getGameTeams: async gameID => {
        if (gameID === undefined) return []
        const q = query(
            collection(db, "gameteams"),
            where("game", "==", gameID)
        )
        const querySnapshot = await getDocs(q)
        return querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }))
    },
    getGameRounds: async gameID => {
        if (gameID === undefined) return []
        const q = query(collection(db, "rounds"), where("game", "==", gameID))
        const querySnapshot = await getDocs(q)
        return querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }))
    },
    getGameScores: async gameID => {
        if (gameID === undefined) return []
        const q = query(
            collection(db, "gamescores"),
            where("game", "==", gameID)
        )
        const querySnapshot = await getDocs(q)
        return querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }))
    },
    deleteGameTeam: async (gameID, teamID) => {
        const q = query(
            collection(db, "gameteams"),
            where("game", "==", gameID),
            where("team", "==", teamID)
        )
        const querySnapshot = await getDocs(q)
        querySnapshot.docs.map(doc => deleteDoc(doc.ref))
    },
    getGame: async gameID => {
        if (gameID === undefined) return {}
        const docRef = doc(db, "games", gameID)
        const docSnap = await getDoc(docRef)
        if (!docSnap.exists()) return {}
        return {
            id: docSnap.id,
            ...docSnap.data(),
        }
    },
    getGameDetails: async gameID => {
        if (gameID === undefined) return {}
        const game = await dataService.getGame(gameID)
        const gameTeams = await dataService.getGameTeams(gameID)
        const gameRounds = await dataService.getGameRounds(gameID)
        const gameScores = await dataService.getGameScores(gameID)
        return {
            game,
            gameTeams,
            gameRounds,
            gameScores,
        }
    },
    getGameUsersAndVenues: async games => {
        const uniqueUserIDs = [...new Set(games.map(game => game.user))]
        if (uniqueUserIDs.length === 0) return { users: [], venues: [] }
        const q = query(
            collection(db, "users"),
            where("uid", "in", uniqueUserIDs)
        )
        const querySnapshot2 = await getDocs(q)
        const users = querySnapshot2.docs.map(doc => ({
            id: doc.data().uid,
            ...doc.data(),
        }))
        const uniqueVenueIDs = [...new Set(games.map(game => game.venue))]
        if (uniqueVenueIDs.length === 0) return { users, venues: [] }
        const q3 = query(
            collection(db, "venues"),
            where(documentId(), "in", uniqueVenueIDs)
        )
        const querySnapshot3 = await getDocs(q3)
        const venues = querySnapshot3.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }))
        return { users, venues }
    },
    deleteGame: async gameID => {
        // const gameTeams = await dataService.getGameTeams(gameID)
        // gameTeams.map(gameTeam =>
        //     dataService.deleteGameTeam(gameID, gameTeam.team)
        // )
        // const gameRounds = await dataService.getGameRounds(gameID)
        // gameRounds.map(round => deleteDoc(doc(db, "rounds", round.id)))
        // const gameScores = await dataService.getGameScores(gameID)
        // gameScores.map(score => deleteDoc(doc(db, "gamescores", score.id)))
        deleteDoc(doc(db, "games", gameID))
        return true
    },
    findScore: async (teamID, date, venue) => {
        const q = query(
            collection(db, "scores"),
            where("team", "==", teamID),
            where("date", "==", date),
            where("venue", "==", venue)
        )
        const querySnapshot = await getDocs(q)
        return querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }))[0]
    },
    getNextTeam: async teamID => {
        // we need to get all the teams and sort them by score and then return the next team
        // we need to filter the scores to the current season

        // get the current season
        const q = query(collection(db, "seasons"), where("active", "==", true))
        const querySnapshot = await getDocs(q)
        const currentSeason = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }))[0]

        const q2 = query(
            collection(db, "scores"),
            where("season", "==", currentSeason.id)
        )
        const querySnapshot2 = await getDocs(q2)
        const scores = querySnapshot2.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }))

        const uniqueTeamIDs = [...new Set(scores.map(score => score.team))]
        const teamScores = uniqueTeamIDs.map(teamID => ({
            teamID,
            total: scores
                .filter(score => score.team === teamID)
                .reduce((acc, score) => acc + score.score, 0),
        }))
        const sortedTeamScores = teamScores.sort((a, b) => b.total - a.total)
        const teamRank = sortedTeamScores.findIndex(
            teamScore => teamScore.teamID === teamID
        )
        return sortedTeamScores[teamRank + 1]
    },
    getPrevTeam: async teamID => {
        // we need to get all the teams and sort them by score and then return the next team
        // we need to filter the scores to the current season

        // get the current season
        const q = query(collection(db, "seasons"), where("active", "==", true))
        const querySnapshot = await getDocs(q)
        const currentSeason = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }))[0]

        const q2 = query(
            collection(db, "scores"),
            where("season", "==", currentSeason.id)
        )
        const querySnapshot2 = await getDocs(q2)
        const scores = querySnapshot2.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }))

        const uniqueTeamIDs = [...new Set(scores.map(score => score.team))]
        const teamScores = uniqueTeamIDs.map(teamID => ({
            teamID,
            total: scores
                .filter(score => score.team === teamID)
                .reduce((acc, score) => acc + score.score, 0),
        }))
        const sortedTeamScores = teamScores.sort((a, b) => b.total - a.total)
        const teamRank = sortedTeamScores.findIndex(
            teamScore => teamScore.teamID === teamID
        )
        return sortedTeamScores[teamRank - 1]
    },
    getTeamCategoryCorrectCount: async teamID => {
        // first we have to get all games for the team by looking at scores and find the unique game ids
        const q = query(collection(db, "scores"), where("team", "==", teamID))
        const querySnapshot = await getDocs(q)
        const scores = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }))

        const uniqueGameIDs = [
            ...new Set(scores.map(score => score.game)),
        ].filter(Boolean)

        // now we can just get those games, because the new game object has a .rounds property which is an array of objects that are just category: id, round: number and a .teams property which is an arry of objects that are {team: id, scores: [scoreObjects] }, scoreObjects are {round: number, touched: boolean, questions: [questionObjects] }, questionObjects are {correct: boolean, question: number}
        if (uniqueGameIDs.length === 0) return []
        const chunkSize = 20
        const chunks = []
        for (let i = 0; i < uniqueGameIDs.length; i += chunkSize) {
            chunks.push(uniqueGameIDs.slice(i, i + chunkSize))
        }

        // Perform multiple queries and combine the results
        const games = []
        for (const chunk of chunks) {
            const q2 = query(
                collection(db, "games"),
                where(documentId(), "in", chunk)
            )
            const querySnapshot2 = await getDocs(q2)
            games.push(
                ...querySnapshot2.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }))
            )
        }

        const categoryCorrectCounts = {}

        for (const game of games) {
            const team = game?.teams?.find(team => team.team === teamID)

            if (team) {
                team.scores.forEach(score => {
                    score.questions.forEach(question => {
                        const category = game.rounds.find(
                            round => round.round === score.round
                        ).category
                        if (categoryCorrectCounts[category]) {
                            categoryCorrectCounts[category].correctCount +=
                                question.correct ? 1 : 0
                            categoryCorrectCounts[category].incorrectCount +=
                                question.correct ? 0 : 1
                        } else {
                            categoryCorrectCounts[category] = {
                                correctCount: question.correct ? 1 : 0,
                                incorrectCount: question.correct ? 0 : 1,
                            }
                        }
                    })
                })
            }
        }

        return Object.keys(categoryCorrectCounts).map(category => ({
            category,
            correctCount: categoryCorrectCounts[category].correctCount,
            incorrectCount: categoryCorrectCounts[category].incorrectCount,
        }))
    },
    getTeamCategoryCorrectCountOld: async teamID => {
        // first we have to get all games for the team by looking at gameteams
        const q = query(
            collection(db, "gameteams"),
            where("team", "==", teamID)
        )
        const querySnapshot = await getDocs(q)
        const gameTeams = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }))
        const uniqueGameIDs = [
            ...new Set(gameTeams.map(gameTeam => gameTeam.game)),
        ]
        // for each of the games, we need to get all of the rounds, we don't really care what games the rounds are associated with yet, that will be used later
        if (uniqueGameIDs.length === 0) return []
        const q2 = query(
            collection(db, "rounds"),
            where("game", "in", uniqueGameIDs)
        )
        const querySnapshot2 = await getDocs(q2)
        const rounds = querySnapshot2.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }))
        const uniqueCategoryIDs = [
            ...new Set(rounds.map(round => round.category)),
        ]
        // now we have all the categories for the team
        // we need to get all the gamescores for the team, which have a game property, a team property, and a round property. there is also an array of questions, each with 2 properties, correct: boolean, and queestion: number - we don't care about the question number
        const q3 = query(
            collection(db, "gamescores"),
            where("team", "==", teamID)
        )
        const querySnapshot3 = await getDocs(q3)
        const gameScores = querySnapshot3.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }))
        // we need to get the correct count for each category
        const categoryCorrectCounts = uniqueCategoryIDs.map(categoryID => {
            const categoryRounds = rounds.filter(
                round => round.category === categoryID
            )
            const categoryGameScores = gameScores.filter(gameScore =>
                categoryRounds.find(round => round.id === gameScore.round)
            )
            const correctCount = categoryGameScores.reduce((acc, gameScore) => {
                return (
                    acc +
                    gameScore.questions.filter(question => question.correct)
                        .length
                )
            }, 0)
            const incorrectCount = categoryGameScores.reduce(
                (acc, gameScore) => {
                    return (
                        acc +
                        gameScore.questions.filter(
                            question => !question.correct
                        ).length
                    )
                },
                0
            )
            return {
                category: categoryID,
                correctCount,
                incorrectCount,
            }
        })

        return categoryCorrectCounts
    },
    getFromWhere: async (collectionName, field, value) => {
        if (value === undefined) return []
        const q = query(
            collection(db, collectionName),
            where(field, "==", value)
        )
        const querySnapshot = await getDocs(q)
        return querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }))
    },
    /*
        given an array of score objects, find the other score objects for that date, venue and season
        and then return for each score object, where that team placed compared to the other score objects for that date, venue & season
    */
    getTeamRanks: async teamScores => {
        if (teamScores.length === 0) return []
        const q = query(
            collection(db, "scores"),
            where("season", "==", teamScores[0].season)
        )

        const querySnapshot = await getDocs(q)

        const scores = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }))

        teamScores
            .sort((a, b) => b.date - a.date)
            // only the first 10 scores
            .slice(0, 10)
            .map(async teamScore => {
                // now get all of the other scores that aren't this team, but are for the same date, venue and season

                const teamRank =
                    scores.filter(
                        score =>
                            score.date.seconds === teamScore.date.seconds &&
                            score.venue === teamScore.venue &&
                            score.team !== teamScore.team &&
                            score.score > teamScore.score
                    ).length + 1

                teamScore.rank = teamRank
                teamScore.totalTeams = scores.filter(
                    score =>
                        score.date.seconds === teamScore.date.seconds &&
                        score.venue === teamScore.venue
                ).length
            })

        return teamScores
    },
    getScoresForGame: async game => {
        // if game.date is a string, convert it to a Timestamp otherwise leave it alone
        if (game === undefined) return []
        if (typeof game.date === "string") {
            game.date = Timestamp.fromDate(moment(game.date).toDate())
        }
        const date = game.date
        const season = game.season
        const venue = game.venue
        const q = query(collection(db, "scores"), where("game", "==", game.id))
        const querySnapshot = await getDocs(q)
        if (!querySnapshot.empty) {
            return querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }))
        } else {
            // we need to calculate the scores using gameScores and gameTeams
            const gameTeams = await dataService.getGameTeams(game.id)
            const gameScores = await dataService.getGameScores(game.id)
            const scores = []
            for (const gameTeam of gameTeams) {
                const teamScores = gameScores.filter(
                    gameScore => gameScore.team === gameTeam.team
                )
                const teamScore = {
                    team: gameTeam.team,
                    venue,
                    season,
                    date,
                    score: teamScores.reduce((acc, gameScore) => {
                        return (
                            acc +
                            gameScore.questions.filter(
                                question => question.correct
                            ).length
                        )
                    }, 0),
                }
                scores.push(teamScore)
            }
            return scores
        }
    },
    getScoresByID: async scoreID => {
        const q = query(
            collection(db, "scores"),
            where("__name__", "==", scoreID)
        )
        const querySnapshot = await getDocs(q)
        const score = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }))[0]
        return dataService.getScoresForGame(score)
    },
    getGameFromVenueSeasonDateHost: async (venue, season, date) => {
        const q = query(
            collection(db, "games"),
            where("venue", "==", venue),
            where("season", "==", season),
            where("date", "==", date)
        )
        const querySnapshot = await getDocs(q)
        return querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }))[0]
    },
    getScoresWithoutGameId: async () => {
        const q = query(collection(db, "scores"), where("game", "==", ""))
        const querySnapshot = await getDocs(q)
        return querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }))
    },
    updateScoresWithGameId: async () => {
        const scores = await dataService.getScoresWithoutGameId()

        for (const score of scores) {
            const game = await dataService.getGameFromVenueSeasonDateHost(
                score.venue,
                score.season,
                score.date
            )

            if (game) {
                // Update the score record with the game ID
                const scoreRef = doc(db, "scores", score.id)
                await updateDoc(scoreRef, { game: game.id })
            } else {
                // If no matching game found, create a new game record
                const newGame = {
                    date: score.date,
                    season: score.season,
                    venue: score.venue,
                    status: "finalized",
                    user: score.user, // Assuming score has user field, adjust as necessary
                }
                const newGameRef = await addDoc(
                    collection(db, "games"),
                    newGame
                )
                // Update the score record with the new game ID
                const scoreRef = doc(db, "scores", score.id)
                await updateDoc(scoreRef, { game: newGameRef.id })
            }
        }
    },
    addGamePropertyToAllScores: async () => {
        const scoresCollection = collection(db, "scores")
        const querySnapshot = await getDocs(scoresCollection)

        for (const doc of querySnapshot.docs) {
            await updateDoc(doc.ref, { game: "" })
        }
    },
    insertIntoCollection: async (collectionName, data) => {
        const docRef = await addDoc(collection(db, collectionName), data)
        return {
            id: docRef.id,
            ...data,
        }
    },
    findAndResolveDuplicates: async () => {
        const gamesCollection = collection(db, "games")
        const gamesSnapshot = await getDocs(gamesCollection)
        const games = gamesSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }))

        const groups = games.reduce((acc, game) => {
            const date =
                game.date instanceof Timestamp
                    ? game.date.toDate().toISOString().split("T")[0]
                    : new Date(game.date).toISOString().split("T")[0]
            const key = `${game.venue}_${game.season}_${date}`
            if (!acc[key]) {
                acc[key] = []
            }
            acc[key].push(game)
            return acc
        }, {})

        for (const key in groups) {
            const group = groups[key]
            if (group.length > 1) {
                // Check for rounds associated with each game
                const gamesWithRounds = []
                for (const game of group) {
                    const roundsCollection = collection(db, "rounds")
                    const roundsSnapshot = await getDocs(
                        query(roundsCollection, where("game", "==", game.id))
                    )
                    if (!roundsSnapshot.empty) {
                        gamesWithRounds.push(game)
                    }
                }

                const gameToKeep =
                    gamesWithRounds.length > 0 ? gamesWithRounds[0] : group[0]
                const duplicateGames = group.filter(
                    game => game.id !== gameToKeep.id
                )

                for (const duplicateGame of duplicateGames) {
                    // Update scores to link to the game to keep
                    const scoresCollection = collection(db, "scores")
                    const scoresSnapshot = await getDocs(
                        query(
                            scoresCollection,
                            where("game", "==", duplicateGame.id)
                        )
                    )
                    const scores = scoresSnapshot.docs.map(doc => ({
                        id: doc.id,
                        ...doc.data(),
                    }))

                    for (const score of scores) {
                        const scoreRef = doc(db, "scores", score.id)
                        await updateDoc(scoreRef, { game: gameToKeep.id })
                    }

                    // Delete the duplicate game
                    const duplicateGameRef = doc(db, "games", duplicateGame.id)
                    await deleteDoc(duplicateGameRef)
                }
            }
        }
    },
    updateScoresWithRank: async () => {
        const q = query(collection(db, "scores"), where("game", "!=", ""))
        const querySnapshot = await getDocs(q)
        const scores = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }))

        const games = [...new Set(scores.map(score => score.game))]

        for (const gameId of games) {
            const gameScores = scores.filter(score => score.game === gameId)
            const sortedScores = gameScores.sort((a, b) => b.score - a.score)

            for (let i = 0; i < sortedScores.length; i++) {
                const score = sortedScores[i]
                const rank = i + 1 // Rank starts at 1

                await updateDoc(doc(db, "scores", score.id), {
                    rank: rank,
                })
            }
        }
    },
    getScoresWithNonExistingGame: async () => {
        // const scoresSnapshot = await getDocs(collection(db, "scores"))
        // const gamesSnapshot = await getDocs(collection(db, "games"))
        // const existingGameIds = new Set(gamesSnapshot.docs.map(doc => doc.id))
        // const scoresWithNonExistingGame = scoresSnapshot.docs
        //     .filter(doc => !existingGameIds.has(doc.data().game))
        //     .map(doc => ({
        //         id: doc.id,
        //         ...doc.data(),
        //     }))
        // return scoresWithNonExistingGame
    },
    getGameQuestions: async templateID => {
        const q = query(
            collection(db, "gameQuestions"),
            where("template", "==", templateID)
        )
        const querySnapshot = await getDocs(q)
        const gameQuestions = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }))

        const uniqueQuestionIDs = [
            ...new Set(
                gameQuestions.map(gameQuestion => gameQuestion.question)
            ),
        ]

        const questions = []

        if (uniqueQuestionIDs.length === 0) return { gameQuestions, questions }

        const q2 = query(collection(db, "questions"))
        const querySnapshot2 = await getDocs(q2)
        querySnapshot2.docs.forEach(doc => {
            questions.push({
                id: doc.id,
                ...doc.data(),
            })
        })

        return {
            gameQuestions,
            questions: questions.filter(q => uniqueQuestionIDs.includes(q.id)),
        }
    },
    createGameQuestion: async gameQuestion => {
        const gameQuestionRef = await addDoc(
            collection(db, "gameQuestions"),
            gameQuestion
        )
        return {
            id: gameQuestionRef.id,
            ...gameQuestion,
        }
    },
    createQuestion: async question => {
        const questionRef = await addDoc(collection(db, "questions"), question)
        return {
            id: questionRef.id,
            ...question,
        }
    },
    updateQuestion: async question => {
        const questionRef = doc(db, "questions", question.id)
        await updateDoc(questionRef, question)
        return question
    },
    updateGameQuestion: async gameQuestion => {
        const gameQuestionRef = doc(db, "gameQuestions", gameQuestion.id)
        await updateDoc(gameQuestionRef, gameQuestion)
        return gameQuestion
    },
    getGamesForTheWeek: async (startOfWeek, endOfWeek) => {
        const startString = startOfWeek.format("YYYY-MM-DD")
        const endString = endOfWeek.format("YYYY-MM-DD")

        const q = query(
            collection(db, "games"),
            where("date", ">=", startString),
            where("date", "<=", endString)
        )

        const querySnapshot = await getDocs(q)
        const games = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }))
        return games
    },
    updateCollection: async (collectionName, id, data) => {
        const docRef = doc(db, collectionName, id)
        await updateDoc(docRef, data)
        return data
    },
}

export default dataService
