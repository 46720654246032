import React, { useState } from "react"

import useCategoryStore from "../../../data/categoryStore"
import useGameStore from "../../../data/gameStore"
import useTemplateStore from "../../../data/templateStore"
import useOneGameStore from "../../../data/oneGameStore"

export default function Answers() {
    const template = useTemplateStore(state => state.template)
    const rounds = useOneGameStore(state => state.rounds)
    const localGame = JSON.parse(localStorage.getItem("smt-game"))
    const currentSlide = localGame?.currentSlide
    const currentQuestion = localGame?.currentQuestion || 0
    const showAnswer = localGame?.showAnswer || false
    const [fontSize, setFontSize] = useState("7vw") // Default font size

    const currentRound = rounds.find(
        (r, index, arr) =>
            r.slide <= currentSlide + 1 &&
            (arr[index + 1]?.slide > currentSlide + 1 ||
                index === arr.length - 1)
    )?.round
    const categories = useCategoryStore(state => state.categories)
    const category =
        (localGame.rounds && localGame.rounds[currentRound - 1]?.category) || 0
    //  using the rounds array to determine the current question within the round (1-8)
    //  I ddon't need the current question, I want all of the questions for the current round, and their answers, i'm going to cycle through them based on the currentQuestion and showAnswer state

    const questions =
        template && template.questions && template.questions[category]

    const calculateFontSize = question => {
        const textLength = question.length
        if (textLength > 150) {
            return "4vw" // Smaller font for long text
        } else if (textLength > 100) {
            return "5vw"
        } else if (textLength > 50) {
            return "6vw"
        } else {
            return "6vw" // Default size
        }
    }

    return questions?.length > 0 ? (
        <div className="slide question-slide">
            <span className="question-slide-number">
                {currentQuestion + 1}:
            </span>
            <h1 className="question-slide-title">
                {categories.find(c => c.id === category)?.name}
            </h1>
            <div
                className={`question-slide-question ${categories
                    .find(c => c.id === category)
                    ?.name.toLowerCase()
                    .replace(" ", "-")}-background-transparent`}
            >
                <span
                    className="question-slide-question-text"
                    style={{
                        fontSize: calculateFontSize(
                            questions[currentQuestion].question
                        ),
                        lineHeight: calculateFontSize(
                            questions[currentQuestion].question
                        ),
                    }}
                >
                    {questions[currentQuestion].question}
                </span>
                <span
                    className={`question-slide-answer show-${showAnswer}`}
                    style={{
                        fontSize: calculateFontSize(
                            questions[currentQuestion].question
                        ),
                        lineHeight: calculateFontSize(
                            questions[currentQuestion].question
                        ),
                        marginTop: calculateFontSize(
                            questions[currentQuestion].question
                        ),
                    }}
                >
                    {questions[currentQuestion].answer}
                </span>
            </div>
        </div>
    ) : (
        ""
    )
}
