import create from "zustand"
import moment from "moment"
import { collection, getDocs } from "firebase/firestore"
import { db } from "../firebase"

const useSeasonStore = create((set, get) => ({
    seasons: [],
    activeSeason: null,
    setSeasons: seasons => set({ seasons }),
    getSeasons: async () => {
        const seasonCollection = collection(db, "seasons")
        const seasonSnapshot = await getDocs(seasonCollection)
        const seasons = seasonSnapshot.docs
            .map(doc => ({ id: doc.id, ...doc.data() }))
            .sort((a, b) => moment(b.endDate).diff(moment(a.endDate)))
        set({ seasons, activeSeason: seasons.find(season => season.active) })
    },
}))

export default useSeasonStore
