import { addDoc, collection } from "firebase/firestore"
import React, { useEffect } from "react"
import { Filter } from "bad-words"
import { db } from "../../firebase"

import "./TeamsList.css"

import useOneGameStore from "../../data/oneGameStore"
import useTeamStore from "../../data/teamStore"

import { PiUserFill } from "react-icons/pi"
import { IoCloseCircle } from "react-icons/io5"

export default function TeamsList({
    setTeams,
    teamSearch,
    setTeamSearch,
    gameScoreboard,
    setShowTeams,
    setShowControls,
    showControls,
}) {
    const game = useOneGameStore(state => state.game)
    const gameTeams = useOneGameStore(state => state.gameTeams)
    const scoreboard = useOneGameStore(state => state.scoreboard)
    const teams = useTeamStore(state => state.teams)
    const filter = new Filter()

    const addNewTeam = async () => {
        if (!teamSearch) return

        const teamExists = teams.some(
            team => team.name.toLowerCase() === teamSearch.toLowerCase()
        )
        if (teamExists) return

        const cleanedName = filter.clean(teamSearch)

        if (cleanedName !== teamSearch) {
            alert("The team name contains inappropriate language.")
            return
        }

        // confirmation dialog
        if (!window.confirm(`Create new team: ${teamSearch}?`)) return

        const teamName = teamSearch
        const newTeam = await addDoc(collection(db, "teams"), {
            name: teamName,
        })
        // addTeamToGame(newTeam.id, gameId)
        useOneGameStore.getState().addTeam(newTeam.id)
        useTeamStore.getState().addTeam({ id: newTeam.id, name: teamName })
        setTeamSearch("")
        // setTeams([...teams, { id: newTeam.id, name: teamName }])
    }

    const [editing, setEditing] = React.useState(false)

    const updateTeamName = (teamId, newName) => {
        useOneGameStore.getState().changeTeamDisplayName(teamId, newName)
        setEditing(false)
    }

    useEffect(() => {
        setInterval(() => {
            useTeamStore.getState().getTeams()
        }, 10000)
    }, [])

    return (
        <ul className="live-scoring-list">
            {/* TODO: add scores and sort by scores */}
            <div className="team-header black-text flex">
                <span>Find a Team:</span>
                <input
                    type="text"
                    placeholder="Search Teams (3 characters minimum)"
                    className="team-search"
                    value={teamSearch}
                    onChange={e => setTeamSearch(e.target.value)}
                    disabled={game.status === "finalized"}
                />
                <button
                    className="clear-search"
                    onClick={() => setTeamSearch("")}
                >
                    <IoCloseCircle />
                </button>
                <button
                    className="done-button"
                    onClick={() => {
                        setShowTeams(false)
                    }}
                >
                    Done
                </button>
                <button
                    className="done-button mobile"
                    onClick={() => {
                        setShowTeams(false)
                        setShowControls(true)
                    }}
                >
                    Done
                </button>
            </div>

            {teamSearch.length >= 3 &&
                teams
                    .filter(
                        team =>
                            team.name
                                .toLowerCase()
                                .includes(teamSearch.toLowerCase()) &&
                            !gameTeams.find(
                                gameTeam => gameTeam.team === team.id
                            )
                    )
                    .map(team => (
                        <li
                            onClick={() => {
                                useOneGameStore.getState().addTeam(team.id)
                                setTeamSearch("")
                            }}
                            key={team.id}
                        >
                            {team.name}
                        </li>
                    ))}

            {teamSearch.length >= 3 && (
                <li
                    onClick={() =>
                        !teams.some(
                            team =>
                                team.name.toLowerCase() ===
                                teamSearch.toLowerCase()
                        ) && addNewTeam(game.id)
                    }
                    className={`game-team-new ${
                        teams.some(
                            team =>
                                team.name.toLowerCase() ===
                                teamSearch.toLowerCase()
                        )
                            ? "crossed-out"
                            : ""
                    }`}
                >
                    ➕ Create Team: {filter.clean(teamSearch)}
                </li>
            )}

            {scoreboard.map((gameTeam, i) => (
                <li key={i} className="game-team-selected">
                    <span className="team-rank">{i + 1}</span>
                    <span className="team-info">
                        <span
                            className="display-name"
                            onClick={() => setEditing(gameTeam.team)}
                        >
                            {editing === gameTeam.team ? (
                                <input
                                    type="text"
                                    className="team-name-edit"
                                    defaultValue={gameTeam.displayName}
                                    autoFocus
                                    onBlur={e => {
                                        updateTeamName(
                                            gameTeam.team,
                                            e.target.value
                                        )
                                    }}
                                />
                            ) : gameTeam.displayName !== "" ? (
                                gameTeam.displayName
                            ) : (
                                gameTeam.name
                            )}
                        </span>
                        <span className="team-players">
                            {gameTeam.members} <PiUserFill />
                        </span>

                        <span
                            className="remove-team"
                            onClick={() => {
                                if (
                                    window.confirm(
                                        `Are you sure you want to remove ${
                                            gameTeam.displayName ||
                                            gameTeam.name
                                        } from the game?`
                                    )
                                ) {
                                    useOneGameStore
                                        .getState()
                                        .removeTeam(gameTeam.team)
                                }
                            }}
                        >
                            Delete Team
                        </span>
                    </span>
                    <span className="team-score">{gameTeam.score}</span>
                </li>
            ))}
        </ul>
    )
}
