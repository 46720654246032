import React, { useState, useEffect } from "react"
import moment from "moment"
import { Link, useNavigate } from "react-router-dom"

import { useParams } from "react-router-dom"

import useVenueStore from "../data/venueStore"
import useTeamStore from "../data/teamStore"
import homepageService from "../data/homepageService"
import "./VenuePage.css"
import SeasonCountdown from "../components/SeasonCountdown/SeasonCountdown"

export default function VenuePage({ id, game = {}, presentation = "" }) {
    const { venueid } = useParams()
    const navigate = useNavigate()
    const [venue, setVenue] = useState(null)
    const [top10Teams, setTop10Teams] = useState([])
    const [days, setDays] = useState(0)
    const [hours, setHours] = useState(0)
    const [minutes, setMinutes] = useState(0)
    const [seconds, setSeconds] = useState(0)

    useEffect(() => {
        const idToUse = id !== undefined ? id : venueid
        const venue = useVenueStore
            .getState()
            .venues.find(venue => venue.id === idToUse)
        console.log(venue)
        setVenue(venue)
        homepageService.getTop10TeamsOfSeasonByVenue(venue?.id).then(teams => {
            setTop10Teams(
                teams.map(t => {
                    return {
                        ...useTeamStore
                            .getState()
                            .teams.find(team => team.id === t.id),
                        total: t.total,
                    }
                })
            )
        })
    }, [id, venueid])

    useEffect(() => {
        const interval = setInterval(() => {
            if (game.date) {
                const now = moment()
                const gameDate = moment(
                    `${game.date} - ${game.time}`,
                    "YYYY-MM-DD - HH:mm"
                )
                const diff = gameDate.diff(now)
                const duration = moment.duration(diff)
                setDays(String(duration.days()).padStart(2, "0"))
                setHours(String(duration.hours()).padStart(2, "0"))
                setMinutes(String(duration.minutes()).padStart(2, "0"))
                setSeconds(String(duration.seconds()).padStart(2, "0"))
            }
        }, 1000)
        return () => clearInterval(interval)
    }, [game])

    const isCurrentlyLive = game => {
        return (
            game?.date === moment().format("YYYY-MM-DD") &&
            game?.time &&
            moment().isBetween(
                moment(game?.time, "HH:mm").subtract(15, "minutes"),
                moment(game?.time, "HH:mm").add(3, "hours")
            )
        )
    }

    return venue ? (
        <div className={`venue-page ${presentation}`}>
            <div className="venue-page__header">
                <div className="venue-page__header-left">
                    <h1>{venue?.name}</h1>
                    <ul className="venue-page__header__info">
                        <li className="venue-page__header__info__item">
                            <a
                                href={`http://maps.apple.com/?daddr=${venue.address}+${venue.city}+${venue.state}+${venue.zip}`}
                            >
                                <img
                                    height="50"
                                    src="/images/applemaps.png"
                                    alt="Apple Maps"
                                />
                            </a>
                            <a
                                href={`http://maps.google.com/?daddr=${venue.address}+${venue.city}+${venue.state}+${venue.zip}`}
                            >
                                <img
                                    height="50"
                                    src="/images/googlemaps.png"
                                    alt="Google Maps"
                                />
                            </a>
                        </li>
                        <li className="venue-page__header__info__item">
                            {venue?.address}
                        </li>
                        <li className="venue-page__header__info__item">
                            {venue?.city}, {venue?.state} {venue?.zip}
                        </li>
                    </ul>
                </div>
            </div>
            <div className="venue-page__hero">
                <div
                    className="venue-page__next-game"
                    style={{
                        backgroundImage: `url(${
                            venue?.photo
                                ? venue?.photo
                                : `/images/venues/${venue?.id}.jpg`
                        })`,
                    }}
                >
                    {isCurrentlyLive(game) ? (
                        <>
                            <h2>Game Currently Live!</h2>
                            <Link
                                to={`/game/${game.id}`}
                                className="venue-page__live-game-link button-85"
                            >
                                Go to Game
                            </Link>
                        </>
                    ) : (
                        <h2>
                            Next Game{" "}
                            {game.rounds &&
                                `- ${moment(game.date).format("ll")} - ${moment(
                                    game.time,
                                    "HH:mm"
                                ).format("h:mm a")}`}
                        </h2>
                    )}
                    {game.rounds && !isCurrentlyLive(game) && (
                        <ul className="venue-page__countdown-timer">
                            <li>
                                <span className="timer-number">{days}</span>
                                <span className="timer-label">Days</span>
                            </li>
                            <li className="blinking-separator">:</li>
                            <li>
                                <span className="timer-number">{hours}</span>
                                <span className="timer-label">Hours</span>
                            </li>
                            <li className="blinking-separator">:</li>
                            <li>
                                <span className="timer-number">{minutes}</span>
                                <span className="timer-label">Minutes</span>
                            </li>
                            {/* <li className="venue-page__countdown-timer__seconds">
                                <span className="timer-number">{seconds}</span>
                                <span className="timer-label">seconds</span>
                            </li> */}
                        </ul>
                    )}
                    {!game.rounds && (
                        <div className="venue-page__no-game">
                            <p>
                                Games at {venue.name} are typically held on{" "}
                                {venue.day}s at{" "}
                                {moment(venue.time, "HH:mm").format("h:mm a")}
                            </p>
                            <p>
                                Check back soon or reach out to the venue for
                                more information.
                            </p>
                        </div>
                    )}
                </div>
            </div>
            <div className="venue-page__top-ten-scoreboard">
                <h2>{venue.name} Top 10 Teams</h2>
                <SeasonCountdown />
                <div className="home__standings-list">
                    <div className="home__standings-team home__standings-team-header">
                        <span className="home__standings-team-rank">Rank</span>
                        <span className="home__standings-team-name">Team</span>
                        <span className="home__standings-team-total">
                            Total
                        </span>
                    </div>
                    {top10Teams.map((team, index) => (
                        <div
                            key={team.id}
                            className={`home__standings-team home__standings-team-${index}`}
                            onClick={() => {
                                navigate(`/team/${team.id}`)
                            }}
                        >
                            <span className="home__standings-team-rank">
                                {index + 1}
                            </span>
                            <span className="home__standings-team-name">
                                {team.name}
                            </span>
                            <span className="home__standings-team-total">
                                {team.total}
                            </span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    ) : null
}
