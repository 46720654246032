import React, { useEffect, useState } from "react"
import { useAuthState } from "react-firebase-hooks/auth"
import { useNavigate } from "react-router-dom"
import { Link } from "react-router-dom"
import { auth, sendPasswordReset } from "../../firebase"
import "./Reset.css"
import dataService from "../../data/dataService"
function Reset() {
    const [email, setEmail] = useState("")
    const [user, loading] = useAuthState(auth)
    const navigate = useNavigate()
    useEffect(() => {
        if (loading) return
        if (user) navigate("/")
    }, [user, loading, navigate])

    const findUserByEmail = async email => {
        const user = await dataService.getUserByEmail(email)
        if (user && user.authProvider === "local") {
            sendPasswordReset(email)
        } else if (user && user.authProvider === "google") {
            alert("You are using Google to sign in. Please login with google")
        } else {
            alert("User not found")
        }
        return user
    }

    return (
        <div className="reset">
            <div className="reset__container">
                <input
                    type="text"
                    className="reset__textBox"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    placeholder="E-mail Address"
                />
                <button
                    className="reset__btn"
                    onClick={() => findUserByEmail(email)}
                >
                    Send password reset email
                </button>
                <div>
                    Don't have an account? <Link to="/register">Register</Link>{" "}
                    now.
                </div>
                <div>
                    Already have an account? <Link to="/login">Login</Link> now.
                </div>
            </div>
        </div>
    )
}
export default Reset
