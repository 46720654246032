import { useEffect, useState } from "react"
import { collection, doc, addDoc, updateDoc } from "firebase/firestore"
import { db } from "../../firebase"
import Modal from "../../components/Modal/Modal"
import dataService from "../../data/dataService"
import { Link } from "react-router-dom"

export default function SeasonsAdmin() {
    const [seasons, setSeasons] = useState([])
    const [currentSeason, setCurrentSeason] = useState(null)
    const [loading, setLoading] = useState(false)
    const [updating, setUpdating] = useState(false)
    const [query, setQuery] = useState("")
    const [searchResults, setSearchResults] = useState([])
    const [showAddModal, setShowAddModal] = useState(false)

    useEffect(() => {
        dataService.getData("seasons", setLoading).then(setSeasons)
    }, [])

    const addSeason = async e => {
        e.preventDefault()
        setUpdating(true)
        const name = e.target.name.value

        const seasonRef = await addDoc(collection(db, "seasons"), { name, active: false })

        setSeasons([...seasons, { id: seasonRef.id, name, active: false }])
        setUpdating(false)
        e.target.reset()
        setShowAddModal(false)
    }

    const searchSeasons = async () => {
        const results = seasons.filter(season =>
            season.name.toLowerCase().includes(query.toLowerCase())
        )
        setSearchResults(results)
    }

    const updateSeasonField = async (id, field, value) => {
        setUpdating(true)
        const seasonRef = doc(db, "seasons", id)
        await updateDoc(seasonRef, { [field]: value })
        setUpdating(false)
        dataService.getData("seasons", setLoading).then(setSeasons)
    }

    const setActive = async id => {
        setUpdating(true)
        const updatedSeasons = seasons.map(season => ({
            ...season,
            active: season.id === id,
        }))
        await Promise.all(
            updatedSeasons.map(season =>
                updateDoc(doc(db, "seasons", season.id), { active: season.active })
            )
        )
        setSeasons(updatedSeasons)
        setUpdating(false)
    }

    return (
        <div className="seasons-admin-page">
            <h1>Seasons</h1>
            <div className="search-area flex">
                <input
                    type="text"
                    value={query}
                    onChange={e => setQuery(e.target.value)}
                    placeholder="Search Seasons..."
                    className="black-text"
                    onKeyDown={e => {
                        if (e.key === "Enter") {
                            e.preventDefault()
                            searchSeasons()
                        }
                    }}
                />
                <button onClick={searchSeasons}>Search</button>
                <button onClick={() => setShowAddModal(true)}>Add Season</button>
            </div>
            {updating && <div className="save-banner">Updating...</div>}
            <div className="seasons-area flex">
                <h2>Search Results</h2>
                <ul className="seasons-list">
                    {searchResults.map(season => (
                        <li
                            key={season.id}
                            className="season-item black-text"
                            onClick={() => setCurrentSeason(season)}
                        >
                            <span className="season-name">{season.name}</span>
                            <span className="season-active">{season.active ? "Active" : "Inactive"}</span>
                            <span className="season-dates">
                                {season.startDate} - {season.endDate}
                            </span>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="all-seasons-area flex">
                <h2>All Seasons</h2>
                <ul className="seasons-list">
                    {seasons.map(season => (
                        <li
                            key={season.id}
                            className="season-item black-text"
                            onClick={() => setCurrentSeason(season)}
                        >
                            <span className="season-name">{season.name}</span>
                            <span className="season-active">{season.active ? "Active" : "Inactive"}</span>
                            <span className="season-dates">
                                {season.startDate} - {season.endDate}
                            </span>
                        </li>
                    ))}
                </ul>
            </div>
            {currentSeason && (
                <Modal
                    title="Edit Season"
                    onClose={() => setCurrentSeason(null)}
                    className="season-modal black-text"
                >
                    <form>
                        <label htmlFor="name">Name</label>
                        <input
                            type="text"
                            id="name"
                            value={currentSeason.name}
                            onChange={e =>
                                setCurrentSeason({
                                    ...currentSeason,
                                    name: e.target.value,
                                })
                            }
                            onBlur={e =>
                                updateSeasonField(
                                    currentSeason.id,
                                    "name",
                                    e.target.value
                                )
                            }
                        />
                        <label htmlFor="active">Active</label>
                        <input
                            type="checkbox"
                            id="active"
                            checked={currentSeason.active}
                            onChange={() => setActive(currentSeason.id)}
                        />
                        <label htmlFor="slug">Slug</label>
                        <input
                            type="text"
                            id="slug"
                            value={currentSeason.slug}
                            onChange={e =>
                                setCurrentSeason({
                                    ...currentSeason,
                                    slug: e.target.value,
                                })
                            }
                            onBlur={e =>
                                updateSeasonField(
                                    currentSeason.id,
                                    "slug",
                                    e.target.value
                                )
                            }
                        />
                        <label htmlFor="startDate">Start Date</label>
                        <input
                            type="date"
                            id="startDate"
                            value={currentSeason.startDate}
                            onChange={e =>
                                setCurrentSeason({
                                    ...currentSeason,
                                    startDate: e.target.value,
                                })
                            }
                            onBlur={e =>
                                updateSeasonField(
                                    currentSeason.id,
                                    "startDate",
                                    e.target.value
                                )
                            }
                        />
                        <label htmlFor="endDate">End Date</label>
                        <input
                            type="date"
                            id="endDate"
                            value={currentSeason.endDate}
                            onChange={e =>
                                setCurrentSeason({
                                    ...currentSeason,
                                    endDate: e.target.value,
                                })
                            }
                            onBlur={e =>
                                updateSeasonField(
                                    currentSeason.id,
                                    "endDate",
                                    e.target.value
                                )
                            }
                        />
                    </form>
                </Modal>
            )}
            {showAddModal && (
                <Modal
                    title="Add Season"
                    onClose={() => setShowAddModal(false)}
                    className="season-modal black-text"
                >
                    <form onSubmit={addSeason}>
                        <label htmlFor="name">Name</label>
                        <input type="text" id="name" name="name" required />
                        <button type="submit">Add Season</button>
                    </form>
                </Modal>
            )}
        </div>
    )
}
