import { useEffect, useState } from "react"
import { doc, updateDoc } from "firebase/firestore"
import { db } from "../../firebase"
import Modal from "../../components/Modal/Modal"
import useUserStore from "../../data/userStore"
import roles from "../../data/roles"

import { Link } from "react-router-dom"

import useTeamStore from "../../data/teamStore"
import useTransactionStore from "../../data/transactionStore"
import dataService from "../../data/dataService"
import moment from "moment"

export default function UserAdmin() {
    const hosts = useUserStore(state => state.hosts)
    const setHosts = useUserStore(state => state.setHosts)
    const users = useUserStore(state => state.users)
    const setUsers = useUserStore(state => state.setUsers)
    const [currentUser, setCurrentUser] = useState(null)
    const [updating, setUpdating] = useState(false)
    const [query, setQuery] = useState("")
    const [searchResults, setSearchResults] = useState([])

    const updateUserField = async (id, field, value) => {
        setUpdating(true)
        const userRef = doc(db, "users", id)
        await updateDoc(userRef, {
            [field]: value,
        })
        setUpdating(false)
        setHosts()
        setUsers()
        useUserStore.getState().getHosts() // Reload hosts
    }

    const updateCredits = credits => {
        if (credits < 0 && currentUser.gameCredits + credits < 0) {
            alert("Cannot have negative credits")
            return
        }
        const newCredits =
            (currentUser.gameCredits ? currentUser.gameCredits : 0) +
            Number(credits)

        dataService.insertIntoCollection("transactions", {
            receiver: currentUser.uid,
            giver: "ADMIN",
            amount: 0,
            credits,
            date: moment().format("YYYY-MM-DD"),
            time: moment().format("HH:mm:ss"),
            type: "admin",
            status: "completed",
            note: "adminID",
        })
        setCurrentUser({ ...currentUser, gameCredits: newCredits })
        useTransactionStore
            .getState()
            .addCreditsToUser(currentUser.uid, credits)
    }

    const setCredits = credits => {
        setCurrentUser({ ...currentUser, gameCredits: credits })
        updateUserField(currentUser.id, "gameCredits", credits)
    }

    const searchUsers = async () => {
        const results = await useUserStore.getState().searchUsers(query)
        setSearchResults(results)
    }

    return (
        <div className="users-admin-page">
            <h1>Users</h1>
            <div className="search-area flex">
                <input
                    type="text"
                    value={query}
                    onChange={e => setQuery(e.target.value)}
                    placeholder="Search Users..."
                    className="black-text"
                    onKeyDown={e => {
                        if (e.key === "Enter") {
                            e.preventDefault()
                            searchUsers()
                        }
                    }}
                />
                <button onClick={searchUsers}>Search</button>
            </div>
            {updating && <div className="save-banner">Updating...</div>}
            <div className="users-area flex">
                <h2>Search Results</h2>
                <ul className="users-list">
                    {searchResults.map(user => (
                        <li
                            key={user.id}
                            className="user-item black-text"
                            onClick={() => setCurrentUser(user)}
                        >
                            <span className="user-name">{user.name}</span>
                            <span className="user-email">{user.email}</span>
                            <span className="user-role">{user.role}</span>
                            <span className="user-credits">
                                Credits: {user.gameCredits || 0}
                            </span>
                            <ul className="user-teams">
                                {user.teams?.map(team => (
                                    <li key={team}>
                                        {
                                            useTeamStore
                                                .getState()
                                                .teams.find(t => t.id === team)
                                                ?.name
                                        }
                                    </li>
                                ))}
                            </ul>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="hosts-area flex">
                <h2>Hosts</h2>
                <ul className="users-list">
                    {hosts?.map(user => (
                        <li
                            key={user.id}
                            className="user-item black-text"
                            onClick={() => setCurrentUser(user)}
                        >
                            <span className="user-name">{user.name}</span>
                            <span className="user-email">{user.email}</span>
                            <span className="user-role">{user.role}</span>
                            <span className="user-credits">
                                Credits: {user.gameCredits || 0}
                            </span>
                        </li>
                    ))}
                </ul>
            </div>
            {currentUser && (
                <Modal
                    title="Edit User"
                    onClose={() => setCurrentUser(null)}
                    className="user-modal black-text"
                >
                    <form>
                        <p className="user-profile-link">
                            <Link to={`/user/${currentUser.uid}`}>
                                Profile Page
                            </Link>
                        </p>
                        <label htmlFor="name">Name</label>
                        <input
                            type="text"
                            id="name"
                            value={currentUser.name}
                            onChange={e =>
                                setCurrentUser({
                                    ...currentUser,
                                    name: e.target.value,
                                })
                            }
                            onBlur={e =>
                                updateUserField(
                                    currentUser.id,
                                    "name",
                                    e.target.value
                                )
                            }
                            disabled={currentUser.authProvider === "google"}
                        />
                        <label htmlFor="email">Email</label>
                        <input
                            type="text"
                            id="email"
                            value={currentUser.email}
                            onChange={e =>
                                setCurrentUser({
                                    ...currentUser,
                                    email: e.target.value,
                                })
                            }
                            onBlur={e =>
                                updateUserField(
                                    currentUser.id,
                                    "email",
                                    e.target.value
                                )
                            }
                            disabled={currentUser.authProvider === "google"}
                        />
                        <label htmlFor="role">Role</label>
                        <select
                            id="role"
                            value={currentUser.role}
                            onChange={e =>
                                setCurrentUser({
                                    ...currentUser,
                                    role: e.target.value,
                                })
                            }
                            onBlur={e =>
                                updateUserField(
                                    currentUser.id,
                                    "role",
                                    e.target.value
                                )
                            }
                        >
                            <option value="">Select Role</option>
                            {roles.map(role => (
                                <option key={role} value={role}>
                                    {role}
                                </option>
                            ))}
                        </select>
                        <label htmlFor="credits">
                            Game Credits - {currentUser.gameCredits}
                        </label>
                        <div className="credits-controls">
                            <p className="credits-controls-label">
                                Add Credits:
                            </p>
                            <ul className="credits-controls-list add">
                                <li>
                                    <button
                                        type="button"
                                        onClick={() => updateCredits(1)}
                                    >
                                        +1
                                    </button>
                                </li>
                                <li>
                                    <button
                                        type="button"
                                        onClick={() => updateCredits(4)}
                                    >
                                        +4
                                    </button>
                                </li>
                                <li>
                                    <button
                                        type="button"
                                        onClick={() => updateCredits(12)}
                                    >
                                        12
                                    </button>
                                </li>
                                <li>
                                    <button
                                        type="button"
                                        onClick={() => updateCredits(24)}
                                    >
                                        24
                                    </button>
                                </li>
                                <li>
                                    <button
                                        type="button"
                                        onClick={() => updateCredits(52)}
                                    >
                                        52
                                    </button>
                                </li>
                            </ul>
                            <p className="credits-controls-label">
                                Remove Credits:
                            </p>
                            <ul className="credits-controls-list remove">
                                <li>
                                    <button
                                        type="button"
                                        onClick={() => updateCredits(-1)}
                                    >
                                        -1
                                    </button>
                                </li>
                                <li>
                                    <button
                                        type="button"
                                        onClick={() => updateCredits(-4)}
                                    >
                                        -4
                                    </button>
                                </li>
                                <li>
                                    <button
                                        type="button"
                                        onClick={() => updateCredits(-12)}
                                    >
                                        -12
                                    </button>
                                </li>
                                <li>
                                    <button
                                        type="button"
                                        onClick={() => updateCredits(-24)}
                                    >
                                        -24
                                    </button>
                                </li>
                                <li>
                                    <button
                                        type="button"
                                        onClick={() => updateCredits(-52)}
                                    >
                                        -52
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </form>
                </Modal>
            )}
        </div>
    )
}
