import moment from "moment"
import MobileFooter from "../MobileFooter/MobileFooter"

export default function Footer({ dark, setDark, isMobile }) {
    return isMobile ? (
        <MobileFooter />
    ) : (
        <footer
            className={`footer ${dark && "dark"} ${isMobile && "mobile"} gray`}
        >
            <p className="footer-link-socials">
                <a href="https://www.facebook.com/SouthernMarylandTrivia">
                    <img
                        src="/images/facebook.png"
                        alt="Trivia Takeover Live Facebook"
                    />
                </a>
                <a href="https://www.instagram.com/southernmarylandtrivia/">
                    <img
                        src="/images/instagram.png"
                        alt="Trivia Takeover Live Instagram"
                    />
                </a>
            </p>
            <p className="footer-link-email">
                Email:{" "}
                <a href="mailto:admin@triviatakeover.live">
                    admin@triviatakeover.live
                </a>
            </p>
            <p className="footer-link-copy">
                © {moment().format("YYYY")} by SM TRIVIA LLC
            </p>
        </footer>
    )
}
