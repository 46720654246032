import create from "zustand"
import { collection, getDocs, query, where } from "firebase/firestore"
import { db } from "../firebase"

const useBadgeStore = create(set => ({
    badges: [],
    teamID: false,
    teamBadges: [],
    setBadges: badges => set({ badges }),
    getBadges: async () => {
        const badgeCollection = collection(db, "badges")
        const badgeSnapshot = await getDocs(badgeCollection)
        const badges = badgeSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }))
        set({ badges })
    },
    setTeamBadges: teamBadges => set({ teamBadges }),
    getTeamBadges: async teamID => {
        const q = query(
            collection(db, "teambadges"),
            where("team", "==", teamID)
        )
        const querySnapshot = await getDocs(q)
        const teamBadges = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }))
        set({ teamBadges, teamID })
    },
}))

export default useBadgeStore
