import create from "zustand"
import {
    collection,
    getDocs,
    query,
    where,
    deleteDoc,
    doc,
} from "firebase/firestore"
import { db } from "../firebase"

const categoryOrder = [
    "History",
    "Pop Culture",
    "Science",
    "Sports",
    "Places",
    "Movies",
    "Mystery",
    "Music",
]

const useCategoryStore = create(set => ({
    categories: [],
    setCategories: categories => set({ categories }),
    getCategories: async () => {
        const categoryCollection = collection(db, "categories")
        const categorySnapshot = await getDocs(categoryCollection)
        const categories = categorySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }))
        categories
            .filter(c => c.active)
            .sort((a, b) => {
                return (
                    categoryOrder.indexOf(a.name) -
                    categoryOrder.indexOf(b.name)
                )
            })
        set({ categories })
    },
    countQuestionsPerCategory: async () => {
        const categoryCollection = collection(db, "categories")
        const categorySnapshot = await getDocs(categoryCollection)
        const categories = categorySnapshot.docs
            .map(doc => ({
                id: doc.id,
                ...doc.data(),
            }))
            .filter(c => c.active)
        const questionsPerCategory = {}
        await Promise.all(
            categories.map(async category => {
                const questionCollection = collection(db, "questions")
                const questionQuery = query(
                    questionCollection,
                    where("category", "==", category.id)
                )
                const querySnapshot = await getDocs(questionQuery)
                questionsPerCategory[category.id] = {
                    used: querySnapshot.docs.filter(doc => {
                        const data = doc.data()
                        return data.lastUsed && data.lastUsed.length
                    }).length,
                    unused: querySnapshot.docs.filter(doc => {
                        const data = doc.data()
                        return !data.lastUsed || !data.lastUsed.length
                    }).length,
                }
            })
        )
        return questionsPerCategory
    },
    getQuestionsForCategory: async categoryId => {
        const questionCollection = collection(db, "questions")
        const questionQuery = query(
            questionCollection,
            where("category", "==", categoryId)
        )
        const querySnapshot = await getDocs(questionQuery)
        return querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }))
    },
    getUnusedQuestionsForCategory: async categoryId => {
        const questionCollection = collection(db, "questions")
        const questionQuery = query(
            questionCollection,
            where("category", "==", categoryId),
            where("lastUsed", "==", "")
        )
        const querySnapshot = await getDocs(questionQuery)
        return querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }))
    },
    deleteQuestion: async questionId => {
        const questionRef = doc(db, "questions", questionId)
        await deleteDoc(questionRef)
    },
}))

export default useCategoryStore
