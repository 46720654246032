import React, { useEffect } from "react"
import Loading from "../Loading"

import useOneGameStore from "../../data/oneGameStore"
import useTeamStore from "../../data/teamStore"
import useTemplateStore from "../../data/templateStore"

import { BsFillBackspaceFill } from "react-icons/bs"

import { gameOptions } from "../../utils/dateUtils"
import { FaCheckCircle } from "react-icons/fa"
import { FaMinusCircle } from "react-icons/fa"

export default function TeamScoringView({
    currentRound,
    currentTeam,
    setScoring,
    setScoreTouched,
    setCurrentTeam,
}) {
    const numberOfQuestions = gameOptions.questionsPerRound[currentRound - 1]

    const gameTeams = useOneGameStore(state => state.gameTeams)
    const gameScores = useOneGameStore(state => state.gameScores)
    const gameRounds = useOneGameStore(state => state.gameRounds)
    const teams = useTeamStore(state => state.teams)
    const game = useOneGameStore(state => state.game)
    const localGame = JSON.parse(localStorage.getItem("smt-game"))
    const template = useTemplateStore(state => state.template)
    const questions = useTemplateStore(state => state.template.questions)

    const [saving, setSaving] = React.useState(false)

    const team = gameTeams.find(gt => gt.team === currentTeam)
    const teamScore = team?.scores.find(ts => ts.round === currentRound)
    const localTeam = localGame?.teams.find(t => t.team === currentTeam)
    const matchingLocalTeam = teams.find(t => t.id === localTeam?.team)

    const updateScoring = async (question, correct) => {
        // we should check if the game is already finalized, and if so, we should not allow any more scoring
        if (game.status === "finalized" || game.status === "completed") {
            alert("This game is already finalized.")
            return
        }

        useOneGameStore
            .getState()
            .scoreRound(currentTeam, currentRound, question, correct)
    }

    const setAllCorrect = () => {
        useOneGameStore.getState().setAllCorrect(currentTeam, currentRound)
    }

    const setAllIncorrect = () => {
        useOneGameStore.getState().setAllIncorrect(currentTeam, currentRound)
    }

    useEffect(() => {
        if (teamScore?.touched === false) {
            useOneGameStore
                .getState()
                .setScoreTouched(currentTeam, currentRound)
        }
    }, [teamScore, currentTeam, currentRound])

    return (
        <div className="team-scoring-view">
            <li
                className="live-scoring-go-back"
                onClick={() => setCurrentTeam(null)}
            >
                <BsFillBackspaceFill /> Go Back
            </li>
            <h2 className="team-scoring-team-name">
                <span className="display-name">
                    {localTeam?.displayName || matchingLocalTeam?.name}
                </span>
            </h2>

            <div className="mark-all-buttons">
                <button className="mark-all-correct" onClick={setAllCorrect}>
                    All <FaCheckCircle />
                </button>
                <button
                    className="mark-all-incorrect"
                    onClick={setAllIncorrect}
                >
                    All <FaMinusCircle />
                </button>
            </div>

            <ul className="team-scoring-list">
                {Array.from({ length: numberOfQuestions }, (_, i) => i + 1).map(
                    question => (
                        <li key={question}>
                            {teamScore?.questions?.find(
                                q => q.question === question
                            )?.correct === true ? (
                                <button
                                    className="correct"
                                    onClick={() =>
                                        updateScoring(question, false)
                                    }
                                >
                                    {question} -{" "}
                                    {(template &&
                                        questions &&
                                        questions[
                                            gameRounds.find(
                                                gr => gr.round === currentRound
                                            )?.category
                                        ][question - 1]?.answer) ||
                                        ""}
                                </button>
                            ) : teamScore?.questions?.find(
                                  q => q.question === question
                              )?.correct === false ? (
                                <button
                                    className="incorrect"
                                    onClick={() =>
                                        updateScoring(question, true)
                                    }
                                >
                                    {question} -{" "}
                                    {(template &&
                                        questions &&
                                        questions[
                                            gameRounds.find(
                                                gr => gr.round === currentRound
                                            )?.category
                                        ] &&
                                        questions[
                                            gameRounds.find(
                                                gr => gr.round === currentRound
                                            )?.category
                                        ][question - 1]?.answer) ||
                                        ""}
                                </button>
                            ) : (
                                <button
                                    onClick={() =>
                                        updateScoring(question, true)
                                    }
                                >
                                    {question} -{" "}
                                    {(template &&
                                        questions[
                                            gameRounds.find(
                                                gr => gr.round === currentRound
                                            )?.category
                                        ][question - 1]?.answer) ||
                                        ""}
                                </button>
                            )}
                        </li>
                    )
                )}
            </ul>
        </div>
    )
}
