import React from "react"
import { LoadScript } from "@react-google-maps/api"

const gplacesAPIKey = process.env.REACT_APP_PLACES_API
const libraries = ["places"]

const GoogleMapsLoader = ({ children }) => {
    return (
        <LoadScript googleMapsApiKey={gplacesAPIKey} libraries={libraries}>
            {children}
        </LoadScript>
    )
}

export default GoogleMapsLoader
