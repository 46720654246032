import { useEffect, useState } from "react"
import { FaMinusCircle } from "react-icons/fa"

import "./QuestionAdmin.css"

import useCategoryStore from "../../data/categoryStore"

export default function QuestionAdmin() {
    const categoryNameToClass = name => {
        return name.toLowerCase().replace(" ", "-") + "-background"
    }

    const [questionsPerCategory, setQuestionsPerCategory] = useState({})
    const [selectedCategory, setSelectedCategory] = useState(null)
    const [questions, setQuestions] = useState([])

    useEffect(() => {
        const countQs = async () => {
            const qsPerC = await useCategoryStore
                .getState()
                .countQuestionsPerCategory()
            setQuestionsPerCategory(qsPerC)
        }

        countQs()
    }, [])

    useEffect(() => {
        if (selectedCategory) {
            const getQs = async () => {
                const qs = await useCategoryStore
                    .getState()
                    .getQuestionsForCategory(selectedCategory.id)
                setQuestions(qs)
            }

            getQs()
        }
    }, [selectedCategory])

    const handleDelete = async id => {
        if (window.confirm("Are you sure you want to delete this question?")) {
            await useCategoryStore.getState().deleteQuestion(id)
            const qs = await useCategoryStore
                .getState()
                .getQuestionsForCategory(selectedCategory.id)
            setQuestions(qs)
        }
    }

    return (
        <div className="question-admin">
            <h2>Question Admin</h2>
            {selectedCategory && (
                <>
                    <h3 className="question-admin-selected-category">
                        {selectedCategory.name}
                    </h3>
                    <button
                        className="question-admin-back"
                        onClick={() => setSelectedCategory(null)}
                    >
                        Back
                    </button>
                </>
            )}

            {!selectedCategory && (
                <div className="question-admin-categories">
                    {useCategoryStore.getState().categories.map(category => (
                        <div
                            key={category.id}
                            className={`question-admin-category ${categoryNameToClass(
                                category.name
                            )}`}
                            onClick={() => setSelectedCategory(category)}
                        >
                            <h3>{category.name}</h3>
                            <p className="question-admin-category-count unused">
                                <span className="question-admin-category-count-number">
                                    {questionsPerCategory[category.id]
                                        ?.unused || 0}
                                </span>
                                <span className="question-admin-category-count-text">
                                    Unused Questions
                                </span>
                            </p>
                            <p className="question-admin-category-count used">
                                <span className="question-admin-category-count-number">
                                    {questionsPerCategory[category.id]?.used ||
                                        0}
                                </span>
                                <span className="question-admin-category-count-text">
                                    Used Questions
                                </span>
                            </p>
                        </div>
                    ))}
                </div>
            )}

            {selectedCategory && (
                <div className="question-admin-questions">
                    <h3>Unused Questions</h3>
                    <ul className="question-admin-questions-list">
                        <li className="question-admin-question">
                            <p className="question-admin-question-text">
                                Question
                            </p>
                            <p className="question-admin-answer-text">Answer</p>
                            <p className="question-admin-status-text">Status</p>
                            <p className="question-admin-actions">Actions</p>
                        </li>
                        {questions
                            .filter(
                                question =>
                                    !question.lastUsed ||
                                    question?.lastUsed?.length === 0
                            )
                            .map(question => (
                                <li
                                    key={question.id}
                                    className="question-admin-question"
                                >
                                    <p className="question-admin-question-text">
                                        {question.question}
                                    </p>
                                    <p className="question-admin-answer-text">
                                        {question.answer}
                                    </p>
                                    <p className="question-admin-status-text">
                                        Not used
                                    </p>
                                    <p className="question-admin-status-actions">
                                        <FaMinusCircle
                                            className="delete-question"
                                            onClick={() =>
                                                handleDelete(question.id)
                                            }
                                        />
                                    </p>
                                </li>
                            ))}
                    </ul>
                </div>
            )}
            {selectedCategory && (
                <div className="question-admin-questions">
                    <h3>Used Questions</h3>
                    <ul className="question-admin-questions-list">
                        <li className="question-admin-question">
                            <p className="question-admin-question-text">
                                Question
                            </p>
                            <p className="question-admin-answer-text">Answer</p>
                            <p className="question-admin-status-text">Status</p>
                            <p className="question-admin-actions">Actions</p>
                        </li>
                        {questions
                            .filter(
                                question =>
                                    question.lastUsed &&
                                    question?.lastUsed?.length > 0
                            )
                            .sort((a, b) => {
                                return (
                                    new Date(b.lastUsed) - new Date(a.lastUsed)
                                )
                            })
                            .map(question => (
                                <li
                                    key={question.id}
                                    className="question-admin-question"
                                >
                                    <p className="question-admin-question-text">
                                        {question.question}
                                    </p>
                                    <p className="question-admin-answer-text">
                                        {question.answer}
                                    </p>
                                    <p className="question-admin-status-text">
                                        {question.lastUsed}
                                    </p>
                                    <p className="question-admin-status-actions">
                                        <FaMinusCircle
                                            className="delete-question"
                                            onClick={() =>
                                                handleDelete(question.id)
                                            }
                                        />
                                    </p>
                                </li>
                            ))}
                    </ul>
                </div>
            )}
        </div>
    )
}
