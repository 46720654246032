import React, { useEffect, useState } from "react"
import {
    collection,
    doc,
    addDoc,
    getDocs,
    deleteDoc,
    updateDoc,
    Timestamp,
    writeBatch,
} from "firebase/firestore"
import moment from "moment"
import { db } from "../../firebase"
import useScoreStore from "../../data/scoresStore"
import useSeasonStore from "../../data/seasonStore"
import useGameStore from "../../data/gameStore"
import useUserStore from "../../data/userStore"
import useVenueStore from "../../data/venueStore"
import dataService from "../../data/dataService"

export default function ToolsAdmin() {
    const [loading, setLoading] = useState(false)
    const [operationMessage, setOperationMessage] = useState("")
    const [scoresWithoutGame, setScoresWithoutGame] = useState([])
    const [mostRecentScore, setMostRecentScore] = useState(null)
    const [game, setGame] = useState(null)
    const [similarScores, setSimilarScores] = useState([])
    const [season, setSeason] = useState(null)
    const [scoresWithNonExistingGame, setScoresWithNonExistingGame] = useState(
        []
    )

    useEffect(() => {
        const activeSeason = useSeasonStore
            .getState()
            .seasons.find(season => season.active)
        setSeason(activeSeason)
    }, [])

    const fetchScoresWithoutGame = async () => {
        setLoading(true)
        const scores = await dataService.getScoresWithoutGameId()
        setScoresWithoutGame(scores)
        setLoading(false)
    }

    const addGamePropertyToAllScores = async () => {
        setLoading(true)
        setOperationMessage("Adding 'game' property to all scores...")
        await dataService.addGamePropertyToAllScores()
        setOperationMessage("Added 'game' property to all scores.")
        setLoading(false)
        fetchScoresWithoutGame()
    }

    const updateScoresWithGameId = async () => {
        setLoading(true)
        setOperationMessage("Updating scores with game IDs...")
        await dataService.updateScoresWithGameId()
        setOperationMessage("Updated scores with game IDs.")
        setLoading(false)
        fetchScoresWithoutGame()
    }

    const processGame = async () => {
        setLoading(true)
        if (game) {
            for (const score of similarScores) {
                const scoreRef = doc(db, "scores", score.id)
                await updateDoc(scoreRef, { game: game.id })
            }
            setOperationMessage("Updated scores with existing game ID.")
        } else {
            const newGame = {
                date: mostRecentScore.date,
                season: mostRecentScore.season,
                venue: mostRecentScore.venue,
                status: "finalized",
                user: mostRecentScore.user,
            }
            const newGameRef = await addDoc(collection(db, "games"), newGame)
            for (const score of similarScores) {
                const scoreRef = doc(db, "scores", score.id)
                await updateDoc(scoreRef, { game: newGameRef.id })
            }
            setOperationMessage(
                "Created new game and updated scores with new game ID."
            )
        }
        await fetchScoresWithoutGame()
        if (season) {
            await getMostRecentScoreWithoutGame(season.id)
        }
        setLoading(false)
    }

    const getMostRecentScoreWithoutGame = async seasonId => {
        setLoading(true)
        const scores = await dataService.getScoresWithoutGameId()
        const mostRecent = scores
            .sort((a, b) => b.date.seconds - a.date.seconds)
            .filter(score => {
                return score.season === seasonId
            })[0]
        setMostRecentScore(mostRecent)
        if (mostRecent) {
            // Ensure date is a Timestamp
            const date =
                mostRecent.date instanceof Timestamp
                    ? mostRecent.date
                    : Timestamp.fromDate(new Date(mostRecent.date))
            const matchedGame =
                await dataService.getGameFromVenueSeasonDateHost(
                    mostRecent.venue,
                    mostRecent.season,
                    date
                )
            setGame(matchedGame)
            const similarScores = scores.filter(
                score =>
                    score.venue === mostRecent.venue &&
                    score.season === mostRecent.season &&
                    (score.date instanceof Timestamp
                        ? score.date.seconds
                        : Timestamp.fromDate(new Date(score.date)).seconds) ===
                        date.seconds
            )
            setSimilarScores(similarScores)
        }
        setLoading(false)
    }

    const resolveDuplicates = async () => {
        setLoading(true)
        setOperationMessage("Resolving duplicates...")
        await dataService.findAndResolveDuplicates()
        setOperationMessage("Duplicates resolved.")
        await fetchScoresWithoutGame()
        if (season) {
            await getMostRecentScoreWithoutGame(season.id)
        }
        setLoading(false)
    }

    const updateRanks = async () => {
        setLoading(true)
        setOperationMessage("Updating ranks...")
        await dataService.updateScoresWithRank()
        setOperationMessage("Ranks updated.")
        setLoading(false)
    }

    const fetchScoresWithNonExistingGame = async () => {
        setLoading(true)
        const scores = await dataService.getScoresWithNonExistingGame()
        setScoresWithNonExistingGame(scores)
        setLoading(false)
    }

    const deleteScoresWithNonExistingGame = async () => {
        setLoading(true)
        setOperationMessage("Deleting scores with non-existing games...")
        for (const score of scoresWithNonExistingGame) {
            const scoreRef = doc(db, "scores", score.id)
            await deleteDoc(scoreRef)
        }
        setOperationMessage("Deleted scores with non-existing games.")
        await fetchScoresWithNonExistingGame()
        setLoading(false)
    }

    const updateGameDates = async () => {
        const gamesRef = collection(db, "games")
        const querySnapshot = await getDocs(gamesRef)
        const batch = writeBatch(db)

        querySnapshot.forEach(doc => {
            const data = doc.data()
            if (data.date instanceof Timestamp) {
                const date = data.date.toDate().toISOString().split("T")[0]
                batch.update(doc.ref, { date })
            } else if (typeof data.date === "string") {
                const date = new Date(data.date).toISOString().split("T")[0]
                batch.update(doc.ref, { date })
            }
        })

        await batch.commit()
    }

    useEffect(() => {
        fetchScoresWithoutGame()
        fetchScoresWithNonExistingGame()
    }, [])

    useEffect(() => {
        if (season) {
            getMostRecentScoreWithoutGame(season.id)
        }
    }, [season])

    return (
        <div>
            <h1>Tools Admin</h1>
            <hr />
            <h2>Score Report</h2>
            <p>
                Number of scores without a game ID for current season:{" "}
                {season &&
                    scoresWithoutGame.filter(
                        score => score.season === season.id
                    ).length}
            </p>
            <p>
                Number of scores without a game ID: {scoresWithoutGame.length}
            </p>
            <button onClick={addGamePropertyToAllScores} disabled={true}>
                Add 'game' Property to All Scores
            </button>
            <button onClick={updateScoresWithGameId} disabled={loading}>
                Update Scores with Game IDs
            </button>
            <button onClick={resolveDuplicates} disabled={loading}>
                Resolve Duplicates
            </button>
            <button onClick={updateRanks} disabled={loading}>
                Update Ranks for All Scores
            </button>
            <button onClick={updateGameDates} disabled={loading}>
                Update Games to use Date String over Timestamp
            </button>
            <hr />
            <button onClick={fetchScoresWithNonExistingGame} disabled={loading}>
                Fetch Scores with Non-Existing Games
            </button>
            <button
                onClick={deleteScoresWithNonExistingGame}
                disabled={loading}
            >
                Delete Scores with Non-Existing Games
            </button>
            <hr />
            {loading && <p>Loading...</p>}
            {operationMessage && <p>{operationMessage}</p>}
            <hr />
            <h2>Most Recent Score Without a Game For the Current Season</h2>
            {mostRecentScore ? (
                <>
                    <ul>
                        <li>
                            <strong>Venue: </strong>
                            {
                                useVenueStore
                                    .getState()
                                    .venues.find(
                                        venue =>
                                            venue.id === mostRecentScore.venue
                                    )?.name
                            }
                        </li>
                        <li>
                            <strong>Season: </strong>
                            {
                                useSeasonStore
                                    .getState()
                                    .seasons.find(
                                        season =>
                                            season.id === mostRecentScore.season
                                    )?.name
                            }
                        </li>
                        <li>
                            <strong>Date: </strong>
                            {new Date(
                                mostRecentScore.date.seconds * 1000
                            ).toLocaleDateString()}
                        </li>
                        <li>
                            <strong>Host: </strong>
                            {
                                useUserStore
                                    .getState()
                                    .hosts.find(
                                        host => host.id === mostRecentScore.user
                                    )?.name
                            }
                        </li>
                    </ul>
                    <h2>
                        {similarScores.length} more Scores with Same Venue,
                        Season, Date, and Host
                    </h2>
                    <h3>Game Exists: {game ? "Yes" : "No"}</h3>
                    <p>
                        <button onClick={processGame} disabled={loading}>
                            {game
                                ? "Update Scores with Game ID"
                                : "Create Game and Update Scores with Game ID"}
                        </button>
                    </p>
                </>
            ) : (
                <>
                    <p>No scores without a game for the current season.</p>
                    <p>Would you like to work on an older season?</p>
                    <select
                        onChange={e => {
                            const season = useSeasonStore
                                .getState()
                                .seasons.find(
                                    season => season.id === e.target.value
                                )
                            setSeason(season)
                        }}
                        defaultValue={season ? season.id : ""}
                    >
                        <option value="">Select a Season</option>
                        {useSeasonStore
                            .getState()
                            .seasons.sort((a, b) => {
                                //   use .endDate but transform it using moment
                                return moment(b.endDate).diff(moment(a.endDate))
                            })
                            .map(season => (
                                <option key={season.id} value={season.id}>
                                    {season.name}
                                </option>
                            ))}
                    </select>
                </>
            )}
        </div>
    )
}
