import React from "react"
import moment from "moment"

import { IoIosArrowBack } from "react-icons/io"
import { IoIosArrowForward } from "react-icons/io"

export default function CalendarHeader({ date, setDate, view }) {
    const goBack = () => {
        setDate(
            date
                .clone()
                .subtract(
                    1,
                    view === "weekly" || view === "host-dashboard"
                        ? "week"
                        : "month"
                )
        )
    }

    const goForward = () => {
        setDate(
            date
                .clone()
                .add(
                    1,
                    view === "weekly" || view === "host-dashboard"
                        ? "week"
                        : "month"
                )
        )
    }

    const goToToday = () => {
        setDate(
            moment().startOf(
                view === "weekly" || view === "host-dashboard"
                    ? "week"
                    : "month"
            )
        )
    }

    return (
        <header className="calendar__header black-text">
            <h2 className="calendar__title">
                {view === "weekly" &&
                    `${date.startOf("week").format("MMMM Do")} - ${date
                        .endOf("week")
                        .format("MMMM Do, YYYY")}`}
                {view === "monthly" && date.format("MMMM YYYY")}
                {view === "host-dashboard" &&
                    `${date.startOf("week").format("MMMM Do")} - ${date
                        .endOf("week")
                        .format("MMMM Do, YYYY")}`}
            </h2>
            <ul className="calendar__nav">
                <li className="calendar__nav-item">
                    <button className="calendar__nav-btn" onClick={goBack}>
                        <IoIosArrowBack />
                    </button>
                </li>
                <li className="calendar__nav-item">
                    <button className="calendar__nav-btn" onClick={goToToday}>
                        Today
                    </button>
                </li>
                <li className="calendar__nav-item">
                    <button className="calendar__nav-btn" onClick={goForward}>
                        <IoIosArrowForward />
                    </button>
                </li>
            </ul>
        </header>
    )
}
