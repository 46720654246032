import React from "react"
import styled from "styled-components"
import { rankings } from "../utils/dateUtils"

import "./Medal.css"
import "./MedalMobile.css"

export function Medal({ name, value }) {
    const calculateMedalClass = (medalName, metric) => {
        const medal = rankings.find(r => r.name === medalName)
        const rating = metric
        let className = `${medalName.replace(" ", "").toLowerCase()} `

        if (rating >= medal.ratings[5]) {
            className += "master"
        } else if (rating >= medal.ratings[4]) {
            className += "diamond"
        } else if (rating >= medal.ratings[3]) {
            className += "platinum"
        } else if (rating >= medal.ratings[2]) {
            className += "gold"
        } else if (rating >= medal.ratings[1]) {
            className += "silver"
        } else if (rating >= medal.ratings[0]) {
            className += "bronze"
        } else {
            className += "none"
        }
        return className
    }

    const calculateMedalProgress = (medalName, metric) => {
        const medal = rankings.find(r => r.name === medalName)
        const rating = metric

        if (rating >= medal.ratings[5]) {
            return 100
        } else if (rating >= medal.ratings[4]) {
            return (rating / medal.ratings[5]) * 100
        } else if (rating >= medal.ratings[3]) {
            return (rating / medal.ratings[4]) * 100
        } else if (rating >= medal.ratings[2]) {
            return (rating / medal.ratings[3]) * 100
        } else if (rating >= medal.ratings[1]) {
            return (rating / medal.ratings[2]) * 100
        } else if (rating >= medal.ratings[0]) {
            return (rating / medal.ratings[1]) * 100
        } else {
            return (rating / medal.ratings[0]) * 100
        }
    }

    const calculateNextValue = (medalName, metric) => {
        const medal = rankings.find(r => r.name === medalName)
        const rating = metric

        if (rating >= medal.ratings[5]) {
            return "MAX"
        } else if (rating >= medal.ratings[4]) {
            return `${rating} / ${medal.ratings[5]}`
        } else if (rating >= medal.ratings[3]) {
            return `${rating} / ${medal.ratings[4]}`
        } else if (rating >= medal.ratings[2]) {
            return `${rating} / ${medal.ratings[3]}`
        } else if (rating >= medal.ratings[1]) {
            return `${rating} / ${medal.ratings[2]}`
        } else if (rating >= medal.ratings[0]) {
            return `${rating} / ${medal.ratings[1]}`
        } else {
            return `${rating} / ${medal.ratings[0]}`
        }
    }

    return (
        <li key={name} className={`medal ${calculateMedalClass(name, value)}`}>
            <span className="medal-row">
                {/* <span className="medal-top-row">
                    <span className="medal-label">
                        {rankings.find(r => r.name === name)?.label}
                    </span>
                    <span className="medal-value">{value}</span>
                </span> */}
                {/* <span className="medal-bottom-row">
                    <span className="medal-progress">
                        <ProgressBar
                            className={`medal-progress-bar ${name.toLowerCase()}`}
                            progress={calculateMedalProgress(name, value)}
                        />
                    </span>
                </span> */}
                <div className="progress-container">
                    <CircularProgress
                        className="circular-progress"
                        progress={calculateMedalProgress(name, value)}
                    >
                        <div className="inner-icon">
                            {rankings.find(r => r.name === name)?.icon}
                        </div>
                    </CircularProgress>
                    <CircularProgressRemaining
                        progress={calculateMedalProgress(name, value)}
                        className="circular-progress-remaining"
                    />
                </div>
                {/* <span className="medal-icon">
                    {rankings.find(r => r.name === name)?.icon}
                </span> */}
                <span className="medal-footer-row">
                    <span className="medal-remaining">
                        {calculateNextValue(name, value)}
                    </span>
                    <span className="medal-rating">
                        {calculateMedalClass(name, value) !== "none"
                            ? name
                            : ""}
                    </span>
                </span>
            </span>
        </li>
    )
}

const ProgressBar = styled.div`
    height: 10px;
    width: ${props => props.progress}%;
    background-color: lightgreen;
    transition: width 0.5s;
`

const CircularProgress = styled.div`
    background: conic-gradient(
        lightgreen 0%,
        lightgreen calc(${props => props.progress} * 1%),
        transparent calc(${props => props.progress} * 1%),
        transparent 100%
    );
`
const CircularProgressRemaining = styled.div`
    background: conic-gradient(
        transparent 0%,
        transparent calc(${props => props.progress} * 1%),
        lightgray calc(${props => props.progress} * 1%),
        lightgray 100%
    );
`
