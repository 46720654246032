import React from "react"
import QRCode from "qrcode.react"

import "./Slideshow.css"
import "./SlideshowMobile.css"

import useTemplateStore from "../../data/templateStore"
import useHeaderStore from "../../data/headerStore"
import useOneGameStore from "../../data/oneGameStore"
import useTeamStore from "../../data/teamStore"

export default function Slideshow() {
    // const game = useGameStore(state => state.game)
    const template = useTemplateStore(state => state.template)
    const [game, setGame] = React.useState({})
    const slides = useOneGameStore(state => state.slides)
    const rounds = useOneGameStore(state => state.rounds)

    React.useEffect(() => {
        if (!template?.id) {
            useTemplateStore.getState().getTemplate(game.template, () => {})
        }
    }, [game.template, template?.id])

    // I need to have an interval that polls the smt-game local-storage every 5 seconds, and converts that to a game object

    React.useEffect(() => {
        const localGame = JSON.parse(localStorage.getItem("smt-game"))
        useHeaderStore.getState().setShowHeader(false)
        if (localGame) {
            setGame(localGame)
        }
        const interval = setInterval(() => {
            const localGame = JSON.parse(localStorage.getItem("smt-game"))
            const oldTeams = game?.teams
            if (localGame) {
                if (oldTeams?.length !== localGame?.game?.teams?.length) {
                    useTeamStore.getState().getTeams()
                }
                setGame(localGame)
            }
        }, 1000)

        return () => clearInterval(interval)
    }, [])

    // // I want this to poll every 5 seconds it will call the useGameStore.getState().loadGame() function
    // const interval = setInterval(() => {
    //     useGameStore.getState().getGame(game.id, () => {})
    // }, 5000)

    // clean up the interval

    // React.useEffect(() => {
    //     return () => clearInterval(interval)
    // }, [])

    const nextSlide = slides[game.currentSlide + 1]

    const currentRound = rounds.find(
        (r, index, arr) =>
            r.slide <= game.currentSlide + 1 &&
            (arr[index + 1]?.slide > game.currentSlide + 1 ||
                index === arr.length - 1)
    )?.round

    const disabled =
        nextSlide === "CategorySelection" && game.teams.length === 0
            ? true
            : nextSlide === "Category" &&
              game.rounds[currentRound - 1]?.category === ""
            ? true
            : slides[game.currentSlide] === "Scoreboard" &&
              game.teams.some(
                  team => team.scores[currentRound - 1].touched === false
              )
            ? true
            : false
    const disabledText = disabled ? "Waiting..." : "Next"

    return (
        <div
            className={`slideshow slide-num-${
                game.currentSlide
            } slide-type-${slides[game.currentSlide]?.toLowerCase()}`}
        >
            {slides[game.currentSlide] &&
                // use the slides array to dynamically load the correct component
                React.createElement(
                    require(`./slides/${slides[game.currentSlide]}`).default
                )}
            {/* we need to make a QR code that sends people to domain name /game/game.id */}

            {/* <footer className="slideshow-footer">
                <button
                    className="prev-slide"
                    onClick={() => useGameStore.getState().prevSlide()}
                >
                    Previous
                </button>
                <p className="slide-number">{game.currentSlide + 1} / 85</p>
                <button
                    disabled={disabled}
                    className="next-slide"
                    onClick={() => useGameStore.getState().nextSlide()}
                >
                    {disabled ? disabledText : "Next"}
                </button>
            </footer>
            <footer className="slideshow-footer-2">
                <button
                    className="reload-game"
                    onClick={() => {
                        useGameStore.getState().getGame(game.id, () => {})
                    }}
                >
                    Reload Game
                </button>
            </footer> */}
        </div>
    )
}
