import React from "react"

export default function Offer() {
    return (
        <div className="slide offer-slide">
            <h1>Want a free appetizer?</h1>
            <p>
                Before the end of Round 1: On your FACEBOOK or INSTAGRAM, tag
                SOUTHERN MARYLAND TRIVIA and check in at THIS VENUE to be
                entered into a raffle to win a FREE PRIZE drawn at the end of
                Round 2! (one entry per team)
            </p>
        </div>
    )
}
