import { Link } from "react-router-dom"
import { useEffect, useState } from "react"

import {
    collection,
    doc,
    getDoc,
    addDoc,
    getDocs,
    updateDoc,
    deleteDoc,
    writeBatch,
    query,
    where,
} from "firebase/firestore"
import { db } from "../../firebase"

export default function TeamsAdmin() {
    const [teams, setTeams] = useState([])
    const [loading, setLoading] = useState(false)
    const [updating, setUpdating] = useState(false)
    const [merging, setMerging] = useState(false)
    const [mergingStatus, setMergingStatus] = useState("")
    const [users, setUsers] = useState([])
    const [q, setQ] = useState("")
    const [team1, setTeam1] = useState({})
    const [team2, setTeam2] = useState({})
    const fetchTeams = async () => {
        await getDocs(collection(db, "teams")).then(querySnapshot => {
            const newData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }))
            setTeams(newData)
        })
    }

    const fetchUsers = async () => {
        await getDocs(collection(db, "users")).then(querySnapshot => {
            const newData = querySnapshot.docs.map(doc => ({
                ...doc.data(),
                id: doc.id,
            }))
            setUsers(newData)
        })
    }

    useEffect(() => {
        setLoading(true)
        fetchTeams()
        fetchUsers()
        setLoading(false)
    }, [])

    const updateTeamName = async (id, name) => {
        setTeams(
            teams.map(team => {
                if (team.id === id) {
                    return {
                        ...team,
                        name,
                    }
                }
                return team
            })
        )
    }

    const saveTeamName = async (id, name) => {
        setUpdating(true)
        const teamRef = doc(db, "teams", id)
        await updateDoc(teamRef, {
            name,
        })
        setUpdating(false)
    }

    const updateTeamCaptain = async (id, captain) => {
        setUpdating(true)
        const teamRef = doc(db, "teams", id)
        await updateDoc(teamRef, {
            captain,
        })

        setTeams(
            teams.map(team => {
                if (team.id === id) {
                    return {
                        ...team,
                        captain,
                    }
                }
                return team
            })
        )
        setUpdating(false)
    }

    const addTeam = async e => {
        e.preventDefault()
        setUpdating(true)
        const name = e.target.name.value
        const captain = e.target.captain.value

        const teamRef = await addDoc(collection(db, "teams"), {
            name,
            captain,
        })

        setTeams([
            ...teams,
            {
                id: teamRef.id,
                name,
                captain,
            },
        ])
        setUpdating(false)
        e.target.reset()
    }

    const search = e => {
        const keyword = e.target.value
        setQ(keyword)
    }

    const handleTeamSelection = team => {
        if (team1.id && team2.id) {
            setTeam2({})
            setTeam1({})
        } else if (team1.id) {
            setTeam2(team)
        } else {
            setTeam1(team)
        }
    }

    const isTeamSelected = team => {
        if (team1.id === team.id || team2.id === team.id) {
            return "selected"
        }
        return ""
    }

    const mergeTeams = async () => {
        setUpdating(true)
        setMerging(true)
        setMergingStatus("Merging teams...")
        const batch = writeBatch(db)

        setMergingStatus("Updating games...")
        // Fetch all games
        const gamesSnapshot = await getDocs(collection(db, "games"))
        gamesSnapshot.forEach(doc => {
            const gameData = doc.data()
            if (
                gameData.teams &&
                gameData.teams.length > 0 &&
                gameData.teams.some(team => team.team === team2.id)
            ) {
                const gameRef = doc.ref
                const newTeams = gameData.teams.map(team => {
                    if (team.team === team2.id) {
                        return { ...team, team: team1.id }
                    }
                    return team
                })
                batch.update(gameRef, { teams: newTeams })
            }
        })

        setMergingStatus("Updating Game Teams...")
        // now go through all gameteams and find the ones where .team is team2 and change them to team1
        const gameTeamsQuery = query(
            collection(db, "gameteams"),
            where("team", "==", team2.id)
        )
        const gameTeamsSnapshot = await getDocs(gameTeamsQuery)
        gameTeamsSnapshot.forEach(doc => {
            const gameTeamRef = doc.ref
            batch.update(gameTeamRef, { team: team1.id })
        })

        setMergingStatus("Updating Game Scores...")
        // now go through all of gamescores and find the ones where .team is .team2 and change them to team1
        const gameScoresQuery = query(
            collection(db, "gamescores"),
            where("team", "==", team2.id)
        )
        const gameScoresSnapshot = await getDocs(gameScoresQuery)
        gameScoresSnapshot.forEach(doc => {
            const gameScoreRef = doc.ref
            batch.update(gameScoreRef, { team: team1.id })
        })

        setMergingStatus("Updating Badges...")
        // now the same for badges
        const badgesQuery = query(
            collection(db, "badges"),
            where("team", "==", team2.id)
        )
        const badgesSnapshot = await getDocs(badgesQuery)
        badgesSnapshot.forEach(doc => {
            const badgeRef = doc.ref
            batch.update(badgeRef, { team: team1.id })
        })

        setMergingStatus("Updating Scores...")
        // now the same for scores
        const scoresQuery = query(
            collection(db, "scores"),
            where("team", "==", team2.id)
        )
        const scoresSnapshot = await getDocs(scoresQuery)
        scoresSnapshot.forEach(doc => {
            const scoreRef = doc.ref
            batch.update(scoreRef, { team: team1.id })
        })

        setMergingStatus("Updating Team Badges...")
        // now the same for teambadges
        const teamBadgesQuery = query(
            collection(db, "teambadges"),
            where("team", "==", team2.id)
        )
        const teamBadgesSnapshot = await getDocs(teamBadgesQuery)
        teamBadgesSnapshot.forEach(doc => {
            const teamBadgeRef = doc.ref
            batch.update(teamBadgeRef, { team: team1.id })
        })

        setMergingStatus(`Committing Updates and Removing ${team2.name}...`)

        // Commit the batch
        await batch.commit()

        // Remove team2 from teams collection
        await deleteDoc(doc(db, "teams", team2.id))

        // Update local state
        setTeams(teams.filter(team => team.id !== team2.id))
        setTeam1({})
        setTeam2({})
        setUpdating(false)
        setMerging(false)
        setMergingStatus("")
    }

    return (
        <section className="teams-admin-page">
            <h1>Teams</h1>
            <p>
                <Link to="/admin">Admin</Link>
            </p>
            <hr />
            <p>{loading && <span>Teams: Loading...</span>}</p>
            <p>{updating && <span>Teams: Updating...</span>}</p>
            <section>
                <input
                    className="black-text"
                    type="text"
                    placeholder="Search Teams...."
                    onChange={search}
                />
            </section>
            <details>
                <summary>Add Team</summary>
                <form onSubmit={addTeam}>
                    <label htmlFor="name">Name</label>
                    <input type="text" id="name" />
                    {/* <label htmlFor="captain">Captain</label>
                    <select id="captain">
                        <option value="">None</option>
                        {users.map(user => (
                            <option key={user.id} value={user.id}>
                                {`${user.email} - ${user.name}`}
                            </option>
                        ))}
                    </select> */}
                    <button type="submit">Add Team</button>
                </form>
            </details>
            {team1.id && team2.id && (
                <section className="merge-teams">
                    <h2>Merge Teams</h2>
                    {mergingStatus && <p>{mergingStatus}</p>}
                    {!merging && (
                        <>
                            <p>
                                Merging{" "}
                                <span className="merge-team-name">
                                    {team2.name}
                                </span>{" "}
                                into{" "}
                                <span className="merge-team-name">
                                    {team1.name}
                                </span>
                            </p>
                            <button
                                className="btn btn-secondary"
                                onClick={() => {
                                    setTeam1({})
                                    setTeam2({})
                                }}
                            >
                                Cancel
                            </button>
                            <button
                                className="btn btn-primary"
                                onClick={mergeTeams}
                            >
                                Merge Teams
                            </button>
                        </>
                    )}
                </section>
            )}
            {teams && (
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Team Page</th>
                            <th>Options</th>
                        </tr>
                    </thead>
                    <tbody>
                        {teams
                            .filter(team =>
                                q !== ""
                                    ? team.name
                                          .toLowerCase()
                                          .includes(q.toLowerCase())
                                    : true
                            )
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map(team => (
                                <tr key={team.id}>
                                    <td>
                                        <input
                                            className="black-text"
                                            type="text"
                                            value={team.name}
                                            onChange={e =>
                                                updateTeamName(
                                                    team.id,
                                                    e.target.value
                                                )
                                            }
                                            onBlur={e =>
                                                saveTeamName(
                                                    team.id,
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </td>
                                    <td>
                                        <Link to={`/team/${team.id}`}>
                                            Team Page
                                        </Link>
                                    </td>
                                    <td>
                                        {q.length > 0 && (
                                            <button
                                                className={isTeamSelected(team)}
                                                onClick={() =>
                                                    handleTeamSelection(team)
                                                }
                                            >
                                                {team1.id === team.id ? "Main Team Selected" : team2.id === team.id ? "Secondary Team Selected for deletion" : "Select Team for Merge"}
                                            </button>
                                        )}
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            )}
        </section>
    )
}
