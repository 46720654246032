import React from "react"
import Scoreboard from "./Scoreboard"

export default function Rotating() {
    return (
        <div className="slide rotating-slide">
            <Scoreboard rotating={true} />
        </div>
    )
}
