import React, { useState, useEffect, useContext } from "react"
import { useNavigate } from "react-router-dom"
import { MdScoreboard } from "react-icons/md"
import { IoCalendarSharp } from "react-icons/io5"
import { FaHistory, FaUser } from "react-icons/fa"
import { useAuthState } from "react-firebase-hooks/auth"
import { auth, db } from "../../firebase"
import { collection, getDocs, query, where } from "firebase/firestore"
import { MdLiveTv } from "react-icons/md"
import dataService from "../../data/dataService"
import moment from "moment"

import FooterContext from "../../data/footerContext"

import useHeaderStore from "../../data/headerStore"

import "./MobileFooter.css"

export default function MobileFooter() {
    const [user] = useAuthState(auth)
    const navigate = useNavigate()
    const [userData, setUserData] = useState({})
    const [isAdmin, setIsAdmin] = useState(false)
    const [isHost, setIsHost] = useState(false)
    const [liveGameURL, setLiveGameURL] = useState("")
    const showHeader = useHeaderStore(state => state.showHeader)
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024)

    const { currentMobileTab, setCurrentMobileTab } = useContext(FooterContext)

    useEffect(() => {
        getUserData()
    }, [user])

    useEffect(() => {
        async function getLiveGameURL() {
            const ttlurl = localStorage.getItem("ttl-game-url")
            const gameID = ttlurl?.split("/")[2]
            dataService.getGame(gameID).then(game => {
                if (
                    game?.status !== "finalized" &&
                    moment(game?.date).isSameOrAfter(moment(), "day")
                ) {
                    setLiveGameURL(ttlurl)
                } else {
                    setLiveGameURL("")
                    localStorage.removeItem("ttl-game-url")
                }
            })
        }
        getLiveGameURL()
    }, [])

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 1024)
        window.addEventListener("resize", handleResize)
        return () => window.removeEventListener("resize", handleResize)
    }, [])

    const getUserData = async () => {
        if (user) {
            const q = query(
                collection(db, "users"),
                where("uid", "==", user.uid)
            )
            const querySnapshot = await getDocs(q)
            querySnapshot.forEach(doc => {
                setIsAdmin(doc.data().role === "admin")
                setIsHost(doc.data().role === "host")
                setUserData(doc.data())
            })
        }
    }

    const handleDashboardClick = () => {
        setCurrentMobileTab("profile")
        if (!user) {
            navigate("/login")
        } else if (isHost || isAdmin) {
            navigate("/host-dashboard")
        } else {
            navigate(`/user/${user.uid}`)
        }
    }

    if (!isMobile) return null

    return (
        <div
            className={`home__mobile-footer gray show-${showHeader}`}
            style={{ pointerEvents: "none" }}
        >
            <ul
                className="home__mobile-footer-list"
                style={{ pointerEvents: "auto" }}
            >
                <li
                    className={`home__mobile-footer-item ${
                        liveGameURL ? "live" : "disabled"
                    }
                    ${currentMobileTab === "live" && "current"}
                    `}
                    onClick={() => {
                        if (liveGameURL) {
                            navigate(liveGameURL)
                        }
                        setCurrentMobileTab("live")
                    }}
                >
                    <MdLiveTv />
                    <span className="home__mobile-footer-item-label">Live Game</span>
                </li>
                <li
                    className={`home__mobile-footer-item ${
                        currentMobileTab === "standings" && "current"
                    }`}
                    onClick={() => {
                        setCurrentMobileTab("standings")
                        navigate("/home/standings")
                    }}
                >
                    <MdScoreboard />
                    <span className="home__mobile-footer-item-label">
                        Standings
                    </span>
                </li>
                <li
                    className={`home__mobile-footer-item ${
                        currentMobileTab === "schedule" && "current"
                    }`}
                    onClick={() => {
                        setCurrentMobileTab("schedule")
                        navigate("/home/schedule")
                    }}
                >
                    <IoCalendarSharp />
                    <span className="home__mobile-footer-item-label">
                        Schedule
                    </span>
                </li>
                <li
                    className={`home__mobile-footer-item ${
                        currentMobileTab === "recent" && "current"
                    }`}
                    onClick={() => {
                        setCurrentMobileTab("recent")
                        navigate("/home/recent")
                    }}
                >
                    <FaHistory />
                    <span className="home__mobile-footer-item-label">
                        Recent
                    </span>
                </li>
                <li
                    className={`home__mobile-footer-item ${
                        currentMobileTab === "profile" && "current"
                    }`}
                    onClick={handleDashboardClick}
                >
                    <FaUser />
                    <span className="home__mobile-footer-item-label">
                        {!user
                            ? "Login"
                            : isHost || isAdmin
                            ? "Dashboard"
                            : "Profile"}
                    </span>
                </li>
            </ul>
        </div>
    )
}
