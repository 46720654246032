import React, { useEffect, useState } from "react"
import { useParams, useNavigate } from "react-router-dom"

import LiveScoring from "../../components/LiveScoring/LiveScoring"
// import "./LiveScoringAdmin.css"
import "./HostDashboard.css"
import { gameOptions } from "../../utils/dateUtils"
import useGameStatus from "../Game/useGameStatus"
import useOneGameStore from "../../data/oneGameStore"
import useVenueStore from "../../data/venueStore"

export default function GameDetailsAdmin() {
    let { gameid } = useParams()
    const navigate = useNavigate()
    const game = useOneGameStore(state => state.game)
    const { legacy, live, upcoming } = useGameStatus(game, false)
    const [gameVenue, setGameVenue] = useState("")
    const [hasRounds, setHasRounds] = React.useState(false)
    const venues = useVenueStore(state => state.venues)

    const [selectedDate, setSelectedDate] = React.useState("")

    const closeLiveScoring = () => {
        navigate("/admin/games")
    }

    useEffect(() => {
        useOneGameStore.getState().loadGame(gameid)
        setTimeout(() => {
            if (game.venue) {
                setGameVenue(venues.find(venue => venue.id === game.venue))
            }
        }, 500)
    }, [gameid])

    useEffect(() => {
        const handleEscapeKey = event => {
            if (event.key === "Escape") {
                closeLiveScoring()
            }
        }

        document.addEventListener("keydown", handleEscapeKey)

        return () => {
            document.removeEventListener("keydown", handleEscapeKey)
        }
    }, [])

    return (
        <LiveScoring
            gameVenue={gameVenue}
            setGameVenue={setGameVenue}
            gameID={gameid}
            numberOfRoundsPerGame={
                legacy
                    ? gameOptions.legacyNumberOfRoundsPerGame
                    : gameOptions.numberOfRoundsPerGame
            }
            questionsPerRound={
                legacy
                    ? gameOptions.legacyQuestionsPerRound
                    : gameOptions.questionsPerRound
            }
            close={closeLiveScoring}
            selectedDate={selectedDate}
            refreshGames={() => {}}
            admin={true}
        />
    )
}
