import React, { useState, useEffect } from "react"
import { logout } from "../firebase"
import { useNavigate } from "react-router-dom"
import { Link, useParams } from "react-router-dom"
import moment from "moment"
import dataService from "../data/dataService"

import useVenueStore from "../data/venueStore"
import useTeamStore from "../data/teamStore"

import "./User.css"

function ordinal_suffix_of(i) {
    if (i === undefined) return ""
    var j = i % 10,
        k = i % 100
    if (j === 1 && k !== 11) {
        return i + "st"
    }
    if (j === 2 && k !== 12) {
        return i + "nd"
    }
    if (j === 3 && k !== 13) {
        return i + "rd"
    }
    return i + "th"
}

export default function User({ id = null }) {
    const navigate = useNavigate()
    const [scores, setScores] = useState([])
    const [currentRecentGamesPage, setCurrentRecentGamesPage] = useState(1)
    const venues = useVenueStore(state => state.venues)
    const teams = useTeamStore(state => state.teams)
    const params = useParams()
    const [userData, setUserData] = useState({})

    useEffect(() => {
        const userID = id || params.id
        dataService.getUser(userID).then(data => {
            dataService.getScoresForUser(userID).then(scores => {
                setScores(scores)
            })
            setUserData(data)
        })
    }, [params])

    return (
        <div className="user-page">
            <h1>User Profile</h1>
            <h2>{userData.name !== "" ? userData.name : userData.email}</h2>
            <button
                className="user-logout"
                onClick={() => {
                    logout()
                    navigate("/")
                }}
            >
                Logout
            </button>
            <div className="user-content">
                <div
                    className={`team-page-season-header team-page-content-section`}
                >
                    <h2 className="team-page-clickable-header black-text">
                        Recent Games
                    </h2>

                    <ul className="teams-page-scores-list">
                        {scores
                            .sort((a, b) =>
                                // use moment to sort by date
                                moment(b.date.toDate()).diff(
                                    moment(a.date.toDate())
                                ) > 0
                                    ? 1
                                    : -1
                            )
                            .map(
                                (score, i) =>
                                    i < 10 * currentRecentGamesPage &&
                                    i >= 10 * (currentRecentGamesPage - 1) && (
                                        <li
                                            key={score.id}
                                            onClick={() =>
                                                navigate(
                                                    `/game-team/${score.game.replace(
                                                        "/",
                                                        ""
                                                    )}/${score.team}`
                                                )
                                            }
                                            className={`team-score-page-score rank-${score.rank}`}
                                        >
                                            <span className="score-score">
                                                {score.score}
                                            </span>
                                            <span className="score-date-venue-wrapper">
                                                <span className="score-venue">
                                                    {
                                                        venues.find(
                                                            venue =>
                                                                venue.id ===
                                                                score.venue
                                                        )?.name
                                                    }
                                                </span>
                                                <span className="score-team black-text">
                                                    {
                                                        teams.find(
                                                            team =>
                                                                team.id ===
                                                                score.team
                                                        )?.name
                                                    }
                                                </span>
                                                <span className="score-date">
                                                    {moment(
                                                        score.date.toDate()
                                                    ).format("MMM Do, YYYY")}
                                                </span>
                                                <span className="score-rank">
                                                    {ordinal_suffix_of(
                                                        score.rank
                                                    )}
                                                </span>
                                            </span>
                                        </li>
                                    )
                            )}
                    </ul>

                    <div className="home__recent-game-pagination">
                        <button
                            className={`home__recent-game-pagination-btn home__recent-game-pagination-prev splash-bg splash-border ${
                                currentRecentGamesPage === 1 && "disabled"
                            }`}
                            disabled={currentRecentGamesPage === 1}
                            onClick={() =>
                                setCurrentRecentGamesPage(
                                    currentRecentGamesPage - 1
                                )
                            }
                        >
                            Prev
                        </button>
                        <button
                            className="home__recent-game-pagination-btn home__recent-game-pagination-next splash-bg splash-border"
                            onClick={() =>
                                setCurrentRecentGamesPage(
                                    currentRecentGamesPage + 1
                                )
                            }
                            disabled={currentRecentGamesPage >= 100}
                        >
                            Next
                        </button>
                    </div>
                    <div className="home__recent-game-pagination-details">
                        <span className="home__recent-game-pagination-page">
                            Page {currentRecentGamesPage}
                        </span>
                    </div>
                </div>

                <div className="user-page-teams black-text">
                    <h2 className="team-page-clickable-header black-text">
                        Your Teams
                    </h2>
                    <ul className="user-page-teams--list">
                        {userData?.teams?.map(team => (
                            <li
                                key={team}
                                className="user-page-teams--list-item"
                            >
                                <Link to={`/team/${team}`}>
                                    {teams.find(t => t.id === team)?.name}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    )
}
