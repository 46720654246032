import React from "react"

import { IoCaretDown, IoCaretForward } from "react-icons/io5"
import moment from "moment"
import styled from "styled-components"

import useBadgeStore from "../../data/badgeStore"
import useTeamStore from "../../data/teamStore"

export default function Badges({
    showBadges,
    setShowBadges,
    team,
    scores,
    teamStats,
}) {
    return (
        (useBadgeStore.getState().teamBadges.length > 0 ||
            useBadgeStore.getState().badges.some(badge => badge.earnable)) && (
            <div className="team-page-stats-badges">
                {showBadges && (
                    <ul className="badge-list">
                        {useBadgeStore
                            .getState()
                            .teamBadges.sort(
                                (a, b) =>
                                    useBadgeStore
                                        .getState()
                                        .badges.find(
                                            badge => badge.id === a.badge
                                        ).earnable -
                                    useBadgeStore
                                        .getState()
                                        .badges.find(
                                            badge => badge.id === b.badge
                                        ).earnable
                            )
                            .map(badge => (
                                <li
                                    className={`badge earned ${
                                        useBadgeStore
                                            .getState()
                                            .badges.find(
                                                b => b.id === badge.badge
                                            ).earnable
                                            ? "earnable"
                                            : "not-earnable"
                                    }`}
                                    key={badge.id}
                                >
                                    <BadgeFront
                                        className="badge-front"
                                        image={`/images/badges/${
                                            useBadgeStore
                                                .getState()
                                                .badges.find(
                                                    b => b.id === badge.badge
                                                ).image
                                        }`}
                                    >
                                        <span className="badge-name">
                                            {
                                                useBadgeStore
                                                    .getState()
                                                    .badges.find(
                                                        b =>
                                                            b.id === badge.badge
                                                    ).name
                                            }
                                        </span>
                                    </BadgeFront>
                                    <span className="badge-back">
                                        <span className="badge-description">
                                            {
                                                useBadgeStore
                                                    .getState()
                                                    .badges.find(
                                                        b =>
                                                            b.id === badge.badge
                                                    ).description
                                            }
                                        </span>
                                        <span className="badge-name">
                                            {
                                                useBadgeStore
                                                    .getState()
                                                    .badges.find(
                                                        b =>
                                                            b.id === badge.badge
                                                    ).name
                                            }
                                        </span>
                                        <span className="badge-date">
                                            {moment(badge.earned).format(
                                                "MMM Do, YYYY"
                                            )}
                                        </span>
                                        {useBadgeStore
                                            .getState()
                                            .badges.find(
                                                b => b.id === badge.badge
                                            ).earnable && (
                                            <span className="badge-earned">
                                                {
                                                    useTeamStore
                                                        .getState()
                                                        .teams.find(
                                                            t =>
                                                                t.id ===
                                                                useBadgeStore
                                                                    .getState()
                                                                    .badges.find(
                                                                        b =>
                                                                            b.id ===
                                                                            badge.badge
                                                                    ).team
                                                        ).name
                                                }
                                            </span>
                                        )}
                                    </span>
                                </li>
                            ))}
                        {useBadgeStore
                            .getState()
                            .badges.filter(
                                badge =>
                                    badge.earnable &&
                                    badge.team !== team.id &&
                                    useBadgeStore
                                        .getState()
                                        .teamBadges.find(
                                            b => b.badge === badge.id
                                        ) === undefined
                            )
                            .map(badge => (
                                <li
                                    className={`badge not-earned ${
                                        badge.earnable
                                            ? "earnable"
                                            : "not-earnable"
                                    }`}
                                    key={badge.id}
                                >
                                    <BadgeFront
                                        className="badge-front"
                                        image={`/images/badges/${badge.image}`}
                                    >
                                        <span className="badge-name">
                                            {badge.name}
                                        </span>
                                    </BadgeFront>
                                    <span className="badge-back">
                                        <span className="badge-description">
                                            {badge.description}
                                        </span>
                                        <span className="badge-name">
                                            {badge.name}
                                        </span>
                                        <span>
                                            {scores.reduce(
                                                (acc, score) =>
                                                    acc + score.score,
                                                0
                                            ) +
                                                teamStats.reduce(
                                                    (acc, stat) =>
                                                        acc + stat.correctCount,
                                                    0
                                                )}{" "}
                                            / 2,693
                                        </span>
                                        <span className="badge-earned">
                                            {
                                                useTeamStore
                                                    .getState()
                                                    .teams.find(
                                                        t => t.id === badge.team
                                                    ).name
                                            }
                                        </span>
                                    </span>
                                </li>
                            ))}
                    </ul>
                )}
            </div>
        )
    )
} // End of Badges

const BadgeFront = styled.span`
    background-image: url(${props => props.image});
`
