import React, { useState } from "react"
import moment from "moment"

import useVenueStore from "../../data/venueStore"
import dataService from "../../data/dataService"

import { RiSlideshow2Fill } from "react-icons/ri"
import { IoIosClose } from "react-icons/io"

export default function LiveScoringHeader({
    handleGoBack,
    game,
    gameVenue,
    setGameVenue,
    selectedDate,
    gameTeams,
    showTeams,
    setShowTeams,
    setCurrentRound,
    setShowScoreboard,
    calculateThirdLine,
    setCurrentTeam,
    admin,
    handledGameOpenedForFirstTime,
}) {
    const [editVenue, setEditVenue] = useState(false)

    const updateVenue = e => {
        // update the venue of the game in the database
        dataService.saveField(
            game.id,
            "venue",
            e.target.value,
            "games",
            () => {}
        )
        game.venue = e.target.value
        setGameVenue(
            useVenueStore
                .getState()
                .venues.find(venue => venue.id === e.target.value)
        )
        // also update the gameVenue
        setEditVenue(false)
    }

    return (
        <header>
            {admin && (
                <span className="admin-banner">
                    Currently in Admin Mode (template: {game.template}, id:{" "}
                    {game.id})
                </span>
            )}

            <h2>
                {editVenue ? (
                    <p className="live-scoring-venue-edit">
                        <select
                            className="live-scoring-venue-select"
                            defaultValue={game.venue}
                            onChange={updateVenue}
                        >
                            <option value="">Choose Venue</option>
                            {useVenueStore.getState().venues.map(venue => (
                                <option key={venue.id} value={venue.id}>
                                    {venue.name}
                                </option>
                            ))}
                        </select>
                        <button
                            className="live-scoring-venue-select-button"
                            onClick={() => setEditVenue(false)}
                        >
                            Cancel
                        </button>
                    </p>
                ) : (
                    <span
                        className="live-scoring-venue-name"
                        onClick={() => setEditVenue(true)}
                    >
                        {game && gameVenue ? `Live Scoring - ${gameVenue.name}` : "Choose Venue"}
                    </span>
                )}

                <span className="live-scoring-header-date-time">
                    {moment(
                        game && game.date
                            ? game.date.toDate
                                ? game.date.toDate()
                                : game.date
                            : selectedDate
                    ).format("MMMM Do, YYYY")}{" "}
                    -{" "}
                    {moment(
                        game && game.time ? game.time : "20:00"
                    , "HH:mm A").format("h:mm A")}
                </span>
                <span className="live-scoring-header-third-line">
                    {calculateThirdLine()}
                </span>
            </h2>
            <div className="live-scoring-header-buttons">
                <button
                    className={`scoreboard-button selected ${
                        game?.opened ? "opened" : "never-opened"
                    }`}
                >
                    <a
                        className="slideshow-button"
                        target="_blank"
                        href={`/slideshow/${game.id}`}
                        onClick={() => handledGameOpenedForFirstTime()}
                        rel="noreferrer"
                    >
                        <RiSlideshow2Fill />
                    </a>
                </button>
                <span className="close-game" onClick={handleGoBack}>
                    <IoIosClose />
                </span>
            </div>
        </header>
    )
}
