import "./Home.css"
import UpcomingGames from "../components/Home/UpcomingGames"
import Standings from "../components/Home/Standings"
import RecentGames from "../components/Home/RecentGames"
import { Loading, SmallLoading } from "../components/Loading"

import { React, useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import FooterContext from "../data/footerContext"

export default function Home() {
    const { currentMobileTab, setCurrentMobileTab } = useContext(FooterContext)
    const [currentUpcomingGame, setCurrentUpcomingGame] = useState(0)
    const [currentUpcomingGameType, setCurrentUpcomingGameType] = useState()
    const [currentRecentGame, setCurrentRecentGame] = useState(0)
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024)
    const { tab } = useParams()

    useEffect(() => {
        setCurrentMobileTab(tab || "schedule")
        const handleResize = () => setIsMobile(window.innerWidth <= 1024)
        window.addEventListener("resize", handleResize)
        return () => window.removeEventListener("resize", handleResize)
    }, [tab])

    return (
        <div className={`home home-tab-${tab}`}>
            {(!tab || tab === "schedule" || tab === "upcoming") && (
                <UpcomingGames
                    currentUpcomingGame={currentUpcomingGame}
                    setCurrentUpcomingGame={setCurrentUpcomingGame}
                    currentUpcomingGameType={currentUpcomingGameType}
                    setCurrentUpcomingGameType={setCurrentUpcomingGameType}
                />
            )}

            {(!tab || tab === "standings") && <Standings />}

            {(!tab || tab === "recent") && (
                <RecentGames
                    currentRecentGame={currentRecentGame}
                    setCurrentRecentGame={setCurrentRecentGame}
                />
            )}
        </div>
    )
}
