import React from "react"

export default function FinalScores() {
    return (
        <div className="slide final-scores-slide">
            <section className="final-scores-wrapper">
                <p className="trivia-title">Final Scores</p>
                <p className="final-scores-crab">
                    <img
                        src="/images/Crab-Logo-11-Random-BG.gif"
                        alt="loading"
                        className="loading"
                        width="200"
                        className="image-center"
                    />
                </p>
            </section>
        </div>
    )
}
