import React, { useEffect, useRef, useState } from "react"
import useGameStore from "../../../data/gameStore"
import useTemplateStore from "../../../data/templateStore"
import useTeamStore from "../../../data/teamStore"
import useCategoryStore from "../../../data/categoryStore"
import useOneGameStore from "../../../data/oneGameStore"
import useUserStore from "../../../data/userStore"
import QRCode from "qrcode.react"

export default function Scoreboard({ rotating = false }) {
    const timeBetweenQuestions = 30000

    const game = useGameStore(state => state.game)
    const template = useTemplateStore(state => state.template)
    const localGame = JSON.parse(localStorage.getItem("smt-game"))
    const currentRound =
        useOneGameStore.getState().getCurrentRoundFromGame(localGame) -
        (rotating ? 2 : 0)
    const [fontSize, setFontSize] = useState("7vw") // Default font size
    const hosts = useUserStore(state => state.hosts)
    const host = hosts.find(h => h.id === localGame.user)
    const [currentQuestion, setCurrentQuestion] = useState(1)
    const teams = useTeamStore(state => state.teams)

    const categories = useCategoryStore(state => state.categories)
    const category =
        (localGame.rounds && localGame.rounds[currentRound + 1]?.category) || 0

    const isEndSlide = useOneGameStore(state => state.isEndSlide)
    const isFinalScoreboardSlide = useOneGameStore(
        state => state.isFinalScoreboardSlide
    )

    useEffect(() => {
        const interval = setInterval(() => {
            if (!game.started) {
                useTeamStore.getState().getTeams()
            }
        }, 1000)

        return () => clearInterval(interval)
    }, [])

    const finalRound = isFinalScoreboardSlide()

    const question = !finalRound
        ? template &&
          template.questions &&
          template.questions[category] &&
          template.questions[category][currentQuestion - 1]
        : 0

    useEffect(() => {
        // set a timeout to advance the question every 10 seconds
        const questionTimer = setTimeout(() => {
            if (currentQuestion < 8) {
                setCurrentQuestion(currentQuestion + 1)
            } else {
                setCurrentQuestion(1)
            }
        }, timeBetweenQuestions)

        if (
            template &&
            template.questions &&
            template.questions[category] &&
            template.questions[category][currentQuestion - 1]
        ) {
            const qObj = template.questions[category][currentQuestion - 1]

            // Adjust font size based on the question length
            const textLength = qObj.question.length
            if (textLength > 150) {
                setFontSize("5vw") // Smaller font for long text
            } else if (textLength > 100) {
                setFontSize("5vw")
            } else if (textLength > 50) {
                setFontSize("7vw")
            } else {
                setFontSize("7vw") // Default size
            }
        }

        return () => clearTimeout(questionTimer)

        // eslint-disable-next-line
    }, [currentQuestion])

    const tableRef = useRef(null)
    const [isScrolling, setIsScrolling] = useState(false)
    const [top3, setTop3] = useState([])
    const [firstPlace, setFirstPlace] = useState(null)
    const [secondPlace, setSecondPlace] = useState(null)
    const [thirdPlace, setThirdPlace] = useState(null)

    const calculateTop3 = () => {
        if (game.teams.filter(team => team.position > 0).length === 3) {
            setTop3(
                game.teams
                    .filter(team => team.position > 0)
                    .sort((a, b) => a.position - b.position)
                    .map((team, index) => {
                        if (index === 0) {
                            setFirstPlace(team.team)
                        } else if (index === 1) {
                            setSecondPlace(team.team)
                        } else if (index === 2) {
                            setThirdPlace(team.team)
                        }
                        return {
                            team: team.team,
                            total: team.scores.reduce(
                                (acc, score) =>
                                    acc +
                                    score.questions.filter(q => q.correct)
                                        .length,
                                0
                            ),
                        }
                    })
            )
        } else {
            setTop3(
                game.teams
                    .map(team => {
                        return {
                            team: team.team,
                            total: team.scores.reduce(
                                (acc, score) =>
                                    acc +
                                    score.questions.filter(q => q.correct)
                                        .length,
                                0
                            ),
                        }
                    })
                    .sort((a, b) => b.total - a.total)
                    .slice(0, 3)
            )
        }
    }

    const checkTopThreeForTie = () => {
        if (
            top3?.length < 3 &&
            game?.teams?.length > 0 &&
            top3?.length < game?.teams?.length
        ) {
            calculateTop3()
        }
        if (
            top3[0]?.total === top3[1]?.total ||
            top3[1]?.total === top3[2]?.total
        ) {
            return true
        }
        return false
    }

    const buildTeamsForScoreboard = () => {
        const initialTeams = localGame.teams
            .sort((a, b) => {
                return a.position !== b.position &&
                    a.position !== 0 &&
                    b.position !== 0
                    ? a.position - b.position
                    : b.scores.reduce(
                          (acc, round) =>
                              acc +
                              round.questions.reduce(
                                  (roundAcc, question) =>
                                      roundAcc + (question.correct ? 1 : 0),
                                  0
                              ),
                          0
                      ) -
                          a.scores.reduce(
                              (acc, round) =>
                                  acc +
                                  round.questions.reduce(
                                      (roundAcc, question) =>
                                          roundAcc + (question.correct ? 1 : 0),
                                      0
                                  ),
                              0
                          )
            })
            .map((team, index) => {
                return {
                    ...team,
                    total: team.scores.reduce(
                        (acc, round) =>
                            acc +
                            round.questions.reduce(
                                (roundAcc, question) =>
                                    roundAcc + (question.correct ? 1 : 0),
                                0
                            ),
                        0
                    ),
                    name: team?.displayName
                        ? team?.displayName
                        : teams.find(t => t.id === team.team)?.name,
                }
            })

        // now we need to go through these teams and assign a rank to them based on their total. If their total is shared with another team, they share the same rank. Which means the next rank is the number of teams that have a higher total + 1 - for instance if team1 has 3 and team 2 has 3 they would both have the rank of 1st and team3 with a total of 2 would be 3rd

        return initialTeams
            .map(team => {
                const rank = initialTeams.filter(
                    t => t.total > team.total
                ).length
                return {
                    ...team,
                    rank: rank + 1,
                }
            })
            .slice(0, 10)
    }

    return (
        <div className="slide scoreboard-slide">
            <section className="scoreboard-slide-wrapper">
                {!rotating && (
                    <p className="trivia-title scoreboard-title">
                        {finalRound ? "Final Scores" : "Scoreboard"}
                    </p>
                )}
                {checkTopThreeForTie() && finalRound && (
                    <p className="tiebreaker-tagline">Tiebreaker Required</p>
                )}
                {!rotating && (
                    <div className="scoregrid-wrapper">
                        <table className={`scoregrid`}>
                            <thead>
                                <tr>
                                    <th></th>
                                    {game.rounds?.map((round, index) => {
                                        return round.category === "" ? (
                                            <th key={index}>
                                                <span className="scoregrid-round-label">
                                                    Round {index + 1}
                                                </span>
                                            </th>
                                        ) : (
                                            <th
                                                key={index}
                                                className={`scoregrid-round-header ${
                                                    index === currentRound - 1
                                                        ? "current"
                                                        : ""
                                                }`}
                                            >
                                                <span className="scoregrid-round-label">
                                                    {
                                                        useCategoryStore
                                                            .getState()
                                                            .categories.find(
                                                                c =>
                                                                    c.id ===
                                                                    round.category
                                                            ).name
                                                    }
                                                </span>
                                            </th>
                                        )
                                    })}
                                    <th className="scoregrid-total">
                                        <span className="scoregrid-round-label">
                                            Total
                                        </span>
                                    </th>
                                </tr>
                            </thead>
                        </table>
                        <table
                            ref={tableRef}
                            className={`scoregrid scroll-grid ${
                                checkTopThreeForTie() && "tiebreaker"
                            }`}
                        >
                            <tbody>
                                {buildTeamsForScoreboard().map(
                                    (team, index, teamsArray) => {
                                        return (
                                            <tr
                                                className={`team-score-row team-score-${index} team-score-position-${team.position} team-score-final-round-${finalRound}`}
                                                key={index}
                                            >
                                                <td>
                                                    {teamsArray[index - 1]
                                                        ?.rank === team.rank ? (
                                                        <span className="tie"></span>
                                                    ) : (
                                                        `${team.rank}. `
                                                    )}
                                                    {team.name}
                                                    {team.members.length ===
                                                        0 && (
                                                        <span className="asterisk">
                                                            *
                                                        </span>
                                                    )}
                                                </td>
                                                {team.scores.map(
                                                    (score, index) => {
                                                        return (
                                                            <td
                                                                key={index}
                                                                className={`scoregrid-team-round ${
                                                                    index ===
                                                                    currentRound -
                                                                        1
                                                                        ? "current"
                                                                        : ""
                                                                } ${
                                                                    score.touched
                                                                        ? "touched"
                                                                        : ""
                                                                }`}
                                                            >
                                                                {score.questions.reduce(
                                                                    (
                                                                        acc,
                                                                        question
                                                                    ) =>
                                                                        acc +
                                                                        (question.correct
                                                                            ? 1
                                                                            : 0),
                                                                    0
                                                                )}
                                                            </td>
                                                        )
                                                    }
                                                )}
                                                <td>{team.total}</td>
                                            </tr>
                                        )
                                    }
                                )}
                            </tbody>
                        </table>

                        <p className="final-score-tagline">
                            * Please see host after game
                        </p>
                        <p className="final-score-tagline">
                            Scoreboard beyond Top 10 available at
                            TriviaTakeover.Live
                        </p>
                    </div>
                )}

                {/* if it's not the finalRound, I want to rotate each question from the currentRound while the host scores */}

                {rotating && currentQuestion < 9 && (
                    <div className="slide question-slide">
                        <span className="question-slide-number">
                            {currentQuestion}:
                        </span>
                        <h1 className="question-slide-title">
                            {categories.find(c => c.id === category)?.name}
                        </h1>
                        <h2 className="question-slide-subtitle">
                            Currently Scoring
                        </h2>
                        <div
                            className={`question-slide-question ${categories
                                .find(c => c.id === category)
                                ?.name.toLowerCase()
                                .replace(" ", "-")}-background-transparent`}
                        >
                            <span
                                className="question-slide-question-text"
                                style={{
                                    fontSize: fontSize,
                                    lineHeight: fontSize,
                                }}
                            >
                                {question?.question}
                            </span>
                        </div>
                    </div>
                )}

                {rotating && currentQuestion === 9 && host.venmoHandle && (
                    <section className="tip-your-host">
                        <QRCode
                            value={`https://venmo.com/${host.venmoHandle}`}
                            size={200}
                        />
                        <p className="venmo-handle">
                            Venmo: @{host.venmoHandle}
                        </p>
                        <p className="qr-code-tagline">Tip Your Host</p>
                    </section>
                )}
            </section>
        </div>
    )
}
