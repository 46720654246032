import React, { useState, useEffect } from "react"

import useTeamStore from "../../data/teamStore"

const Search = ({
    currentTeam,
    setCurrentTeam,
    teamsToExclude = [],
    user,
    gameTeams = [],
    view = "modal",
}) => {
    const [search, setSearch] = useState("")
    const teams = useTeamStore(state => state.teams)
    const [userTeams, setUserTeams] = useState([])

    useEffect(() => {
        const userTeams = teams.filter(team => team.users?.includes(user?.uid))
        setUserTeams(userTeams)
    }, [teams, user])

    const handleAddingTeam = teamId => {
        if (user && teamId !== "") {
            setCurrentTeam({ id: teamId })
        } else {
            setCurrentTeam(teamId)
        }
    }

    return (
        <div className={`team-search search-view-${view}`}>
            <input
                id="team-search"
                name="team-search"
                type="text"
                placeholder={
                    view === "modal"
                        ? "Find or Create a Team"
                        : "Search for a Team"
                }
                className="header__search"
                value={search}
                onChange={e => setSearch(e.target.value)}
                // onBlur={() => setTimeout(() => setSearch(""), 200)}
                autoFocus={view === "modal"}
            />
            <div className="header__search__results">
                {search.length > 2 ? (
                    <>
                        <h3 className="search-results-title">Search Results</h3>
                        <ul>
                            {useTeamStore
                                .getState()
                                .teams.filter(
                                    team =>
                                        team.name.toLowerCase() ===
                                        search.toLowerCase()
                                ).length === 0 &&
                                view === "modal" && (
                                    <li className="search-new-team">
                                        <span
                                            className="team-name"
                                            onClick={async () => {
                                                if (
                                                    window.confirm(
                                                        `Create team "${search}" and add to the game?`
                                                    )
                                                ) {
                                                    const newTeamID =
                                                        await useTeamStore
                                                            .getState()
                                                            .createNewTeam(
                                                                search
                                                            )
                                                    if (!newTeamID) {
                                                        alert(
                                                            "Team name already exists"
                                                        )
                                                    } else {
                                                        setSearch("")
                                                        setCurrentTeam({
                                                            id: newTeamID,
                                                        })
                                                    }
                                                }
                                            }}
                                        >
                                            <strong>Create Team:</strong>{" "}
                                            <small>{search}</small>
                                        </span>
                                    </li>
                                )}
                            {useTeamStore
                                .getState()
                                .teams.filter(team =>
                                    team.name
                                        .toLowerCase()
                                        .includes(search.toLowerCase())
                                )
                                .filter(team => {
                                    if (teamsToExclude) {
                                        return !teamsToExclude.includes(
                                            team.id.toString()
                                        )
                                    }
                                    return true
                                })
                                .map(team => (
                                    <li
                                        key={team.id}
                                        onClick={() => setSearch("")}
                                    >
                                        <span
                                            className="team-name"
                                            onClick={() =>
                                                handleAddingTeam(team.id)
                                            }
                                        >
                                            {team.name}
                                        </span>
                                    </li>
                                ))}
                            <li className="no-results-found">
                                No Results Found
                            </li>
                        </ul>
                    </>
                ) : search.length > 0 ? (
                    <p>Enter at least 3 characters to search for a team</p>
                ) : null}
            </div>

            {userTeams.length > 0 && view === "modal" && (
                <>
                    <h3 className="your-previous-teams">Your Previous Teams</h3>
                    <ul className="user-previous-teams">
                        {userTeams
                            .sort((a, b) => (a.name > b.name ? 1 : -1))
                            .map(team =>
                                teamsToExclude.includes(team.id) ? (
                                    <li
                                        key={team.id}
                                        disabled={true}
                                        className="user-previous-team disabled"
                                    >
                                        <span>{team.name}</span>{" "}
                                        <small>(Already Joined)</small>
                                    </li>
                                ) : (
                                    <li
                                        key={team.id}
                                        className="user-previous-team"
                                        onClick={() => {
                                            handleAddingTeam(team.id)
                                        }}
                                    >
                                        {team.name}
                                    </li>
                                )
                            )}
                    </ul>
                </>
            )}

            {search === "nudeegg" && (
                <div className="nudeegg">
                    <iframe src="https://egggame.org/" title="Egg Game" />
                </div>
            )}
        </div>
    )
}
export default Search
