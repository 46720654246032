import React, { useEffect } from "react"
import { GrScorecard } from "react-icons/gr"
import useCategoryStore from "../../data/categoryStore"
import useGameStore from "../../data/gameStore"
import useTeamStore from "../../data/teamStore"
import useOneGameStore from "../../data/oneGameStore"
import useUserStore from "../../data/userStore"
import { gameOptions } from "../../utils/dateUtils"
import { useNavigate } from "react-router-dom"
import Search from "../../components/Layout/Search"
import Modal from "../../components/Modal/Modal"
import { PiUserFill } from "react-icons/pi"

const Scoreboard = ({
    scores,
    legacy,
    activeTeam,
    setScoreCard,
    hasRounds,
}) => {
    const teams = useTeamStore(state => state.teams)
    const game = useGameStore(state => state.game)
    const gameRounds = useGameStore(state => state.gameRounds)
    const gameScores = useGameStore(state => state.gameScores)
    const categories = useCategoryStore(state => state.categories)
    const navigate = useNavigate()
    const [currentRound, setCurrentRound] = React.useState("all")
    const [scoreboardScores, setScoreboardScores] = React.useState([])
    const currentUser = useUserStore(state => state.currentUser)

    const gameTeams = useOneGameStore(state => state.gameTeams)

    const showScoreCard = (e, team) => {
        e.stopPropagation()
        setScoreCard(team)
    }

    useEffect(() => {
        if (currentRound === "all") {
            setScoreboardScores(scores)
        } else {
            const selectedRoundId = gameRounds[parseInt(currentRound)]?.id

            const roundScores = gameScores
                .filter(gs => gs.round === selectedRoundId)
                .reduce((acc, gs) => {
                    if (!acc[gs.team]) {
                        acc[gs.team] = 0
                    }
                    acc[gs.team] += gs.questions.filter(q => q.correct).length
                    return acc
                }, {})

            const scoresFromPreviousRoundsTotaled = scores.reduce(
                (acc, score) => {
                    const currentRoundNumber = parseInt(currentRound)
                    const previousRoundIDs = gameRounds
                        .sort((a, b) => a.round - b.round)
                        .slice(0, currentRoundNumber)
                        .map(r => r.id)

                    const previousRoundScores = gameScores
                        .filter(gs => previousRoundIDs.includes(gs.round))
                        .reduce((acc2, gs) => {
                            if (!acc2[gs.team]) {
                                acc2[gs.team] = 0
                            }
                            acc2[gs.team] += gs.questions.filter(
                                q => q.correct
                            ).length
                            return acc2
                        }, {})
                    return previousRoundScores
                },
                {}
            )

            const updatedScores = scores.map(score => {
                return {
                    ...score,
                    score: roundScores[score.team] || 0,
                    previousScore:
                        scoresFromPreviousRoundsTotaled[score.team] || 0,
                }
            })

            setScoreboardScores(updatedScores)
        }
    }, [scores, gameScores, gameRounds, currentRound])

    return (
        <div className="game-body-center">
            <ul className="scoreboard-list">
                <li className="scoreboard-list-item scoreboard-list-header">
                    <span className="team-rank">Rank</span>
                    <span className="team-name">Team</span>
                    <span className="team-score">Score</span>
                    {currentRound !== "all" && (
                        <span className="team-previous-score">Total</span>
                    )}
                </li>
                {scoreboardScores &&
                    scoreboardScores.length > 0 &&
                    scoreboardScores
                        .sort((a, b) => {
                            if (currentRound !== "all") {
                                return (
                                    b.score +
                                    b.previousScore -
                                    a.score -
                                    a.previousScore
                                )
                            } else {
                                return b.score - a.score
                            }
                        })
                        .map((score, i) => (
                            <li
                                key={i}
                                className={`scoreboard-list-item ${
                                    score.team === activeTeam ? "active" : ""
                                } ${
                                    gameTeams
                                        .find(gt => gt.team === score.team)
                                        ?.members?.includes(currentUser?.uid)
                                        ? "active"
                                        : ""
                                }  ${currentRound !== "all" ? "round" : ""}`}
                                onClick={e => showScoreCard(e, score.team)}
                            >
                                <span className="team-rank">{i + 1}</span>
                                <span className="team-name">
                                    <span className="team-displayname">
                                        {gameTeams.find(
                                            gt => gt.team === score.team
                                        )?.displayName ||
                                            teams.find(t => t.id === score.team)
                                                ?.name}{" "}
                                        {gameTeams.find(
                                            gt => gt.team === score.team
                                        )?.members?.length > 0 && (
                                            <span className="team-members">
                                                {
                                                    gameTeams.find(
                                                        gt =>
                                                            gt.team ===
                                                            score.team
                                                    )?.members?.length
                                                }{" "}
                                                <PiUserFill />
                                            </span>
                                        )}
                                    </span>
                                    <span className="team-name-name">
                                        {
                                            teams.find(t => t.id === score.team)
                                                ?.name
                                        }
                                    </span>
                                </span>
                                <span
                                    className={`team-score ${
                                        score.score > 0 ? "more" : "none"
                                    }`}
                                >
                                    {score.score}
                                </span>
                                {score.previousScore !== undefined && (
                                    <span className="team-previous-score">
                                        <span
                                            className={`score-total ${
                                                score.score > 0
                                                    ? "more"
                                                    : "none"
                                            }`}
                                        >
                                            {score.score + score.previousScore}
                                        </span>
                                        <span className="previous-score">
                                            {score.previousScore}
                                        </span>
                                    </span>
                                )}
                            </li>
                        ))}
            </ul>
        </div>
    )
}

export default Scoreboard
