import create from "zustand"
import dataService from "./dataService"
import moment from "moment"

const useTemplateStore = create((set, get) => ({
    templateID: false,
    template: {},
    questions: [],
    scores: [],
    subCat: "",
    seasonalTemplates: [],
    getTemplate: async templateID => {
        await set({
            templateID: templateID,
            template: {},
            questions: [],
            scores: [],
        })
        dataService
            .getFromWhere("templates", "__name__", templateID)
            .then(data => {
                set({
                    templateID: templateID,
                    template: data[0],
                    subCat: data[0]?.subCat,
                })
            })
    },
    getTemplatesForSeason: async seasonID => {
        await dataService
            .getData("templates", () => {})
            .then(templates => {
                const seasonalTemplates = templates.filter(
                    template => template.season === seasonID
                )
                set({ seasonalTemplates })
                return seasonalTemplates
            })
    },
    findTemplateForDate: date => {
        return dataService
            .getData("templates", () => {})
            .then(templates =>
                templates.find(
                    t =>
                        moment(t.date).format("YYYY-MM-DD") ===
                        moment(date).format("YYYY-MM-DD")
                )
            )
    },
    createQuestion: question => {
        dataService
            .createQuestion({
                question: question.question,
                answer: question.answer,
                category: question.category,
            })
            .then(data => {
                set(state => ({
                    questions: [...state.questions, data],
                }))
            })
    },
    updateQuestion: question => {
        dataService
            .updateQuestion({
                id: question.questionId,
                question: question.question,
                answer: question.answer,
                category: question.category,
            })
            .then(data => {
                set(state => ({
                    questions: state.questions.map(q =>
                        q.id === data.id ? data : q
                    ),
                }))
            })
    },
}))

export default useTemplateStore
