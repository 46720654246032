import React, { useState, useEffect } from "react"

import { LuTimerReset } from "react-icons/lu"

import dataService from "../../data/dataService"
import moment from "moment"

import "./SeasonCountdown.css"

export default function SeasonCountdown() {
    const [showTooltip, setShowTooltip] = useState(false)
    const [seasons, setSeasons] = useState([])
    const [activeSeason, setActiveSeason] = useState(null)

    useEffect(() => {
        dataService.getData("seasons", () => {}).then(setSeasons)
    }, [])

    useEffect(() => {
        if (seasons.length > 0) {
            setActiveSeason(seasons.find(season => season.active))
        }
    }, [seasons])

    return (
        <>
            <div
                className="season-countdown"
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
                //  onClick={() => setShowTooltip(!showTooltip)}
            >
                <LuTimerReset className="season-countdown-icon" />
                <span className="season-countdown-text">
                    {activeSeason &&
                        `${moment(activeSeason.endDate).diff(
                            moment(),
                            "weeks"
                        )}w ${
                            moment(activeSeason.endDate).diff(
                                moment(),
                                "days"
                            ) % 7
                        }d`}
                </span>
            </div>
            {showTooltip && (
                <div className="season-countdown-tooltip black-text">
                    <span className="season-name">
                        {activeSeason?.name} Season
                    </span>
                    <span className="season-dates">
                        {moment(activeSeason?.startDate).format("ll")} -{" "}
                        {moment(activeSeason?.endDate).format("ll")}
                    </span>
                    <span className="season-countdown-text">
                        The season ends in
                        {activeSeason &&
                            ` ${moment(activeSeason.endDate).diff(
                                moment(),
                                "weeks"
                            )} weeks and ${
                                moment(activeSeason.endDate).diff(
                                    moment(),
                                    "days"
                                ) % 7
                            } days`}
                    </span>
                    <span className="season-countdown-description">
                        At which point all scores and stats will reset.
                    </span>
                </div>
            )}
        </>
    )
}
