import { Link } from "react-router-dom"
import { useState, useEffect, useCallback } from "react"
import { GoogleMap, MarkerF, InfoBoxF } from "@react-google-maps/api"

import { collection, getDocs } from "firebase/firestore"
import { db } from "../firebase"

import moment from "moment"

import dataService from "../data/dataService"
import homepageService from "../data/homepageService"
import useUserStore from "../data/userStore"
import useVenueStore from "../data/venueStore"

import "./Schedule.css"
import { Loading } from "../components/Loading"
import Calendar from "../components/Calendar/Calendar"
import { getNextOccurrence } from "../utils/dateUtils"

const containerStyle = {
    width: "20vw",
    height: "80vh",
}

const center = {
    lat: 38.553921,
    lng: -76.805295,
}

const calculateAverageCenter = venues => {
    const total = venues.reduce(
        (acc, venue) => {
            acc.lat += Number(venue.lat)
            acc.lng += Number(venue.lng)
            return acc
        },
        { lat: 0, lng: 0 }
    )
    return {
        lat: total.lat / venues.length,
        lng: total.lng / venues.length,
    }
}

export default function Schedule() {
    const venues = useVenueStore(state => state.venues)
    const setVenues = useVenueStore(state => state.setVenues)
    const [loading, setLoading] = useState(false)
    const [map, setMap] = useState(null)
    const [activeMarker, setActiveMarker] = useState(null)
    const [page, setPage] = useState(null)
    const userLocation = useUserStore(state => state.userLocation)
    const [upcomingGames, setUpcomingGames] = useState([])
    const [upcomingGamesByVenue, setUpcomingGamesByVenue] = useState([])
    const [mapCenter, setMapCenter] = useState(center)

    useEffect(() => {
        dataService
            .getData("pages", () => {})
            .then(data => {
                setPage(data.find(page => page.slug === "schedule"))
            })
    }, [])

    const onLoad = useCallback(function callback(map) {
        map.setZoom(9)
        setMap(map)
    }, [])

    const onUnmount = useCallback(function callback(map) {
        setMap(null)
    }, [])

    useEffect(() => {
        const fetchUpcomingGames = async () => {
            const games = await homepageService.getUpcomingGames()
            setUpcomingGames(games)
        }
        fetchUpcomingGames()
    }, [])

    useEffect(() => {
        const sortedVenues = venues
            .filter(venue => venue.day && venue.time && venue.city)
            .map(venue => {
                return {
                    ...venue,
                    nextDate: getNextOccurrence(venue.day),
                    date: getNextOccurrence(venue.day),
                }
            })
            .sort((a, b) => a.nextDate - b.nextDate)

        setUpcomingGamesByVenue(sortedVenues)
    }, [venues])

    useEffect(() => {
        if (userLocation) {
            const nearbyVenues = venues.filter(
                venue =>
                    calculateDistance(userLocation, {
                        lat: venue.lat,
                        lng: venue.lng,
                    }) < 50
            )

            if (nearbyVenues.length > 0) {
                setMapCenter(userLocation)
            } else if (venues.length > 0) {
                const averageCenter = calculateAverageCenter(venues)
                setMapCenter(averageCenter)
            }
        }
    }, [userLocation, venues])

    const filterVenues = () => {
        const numberOfVenuesToShow = 10
        let sortedVenues = venues
            .map(venue => {
                const distance = calculateDistance(userLocation, {
                    lat: venue.lat,
                    lng: venue.lng,
                })
                return { ...venue, distance }
            })
            .filter(venue => venue.distance < 50)
            .sort((a, b) => a.distance - b.distance)

        if (sortedVenues.length < numberOfVenuesToShow) {
            sortedVenues = venues
                .map(venue => {
                    const distance = calculateDistance(userLocation, {
                        lat: venue.lat,
                        lng: venue.lng,
                    })
                    return { ...venue, distance }
                })
                .sort((a, b) => a.distance - b.distance)
        }
        return sortedVenues.slice(0, numberOfVenuesToShow)
    }

    const calculateDistance = (loc1, loc2) => {
        if (!loc1 || !loc2) {
            return 0
        }
        const R = 6371 // Radius of the Earth in km
        const dLat = (loc2.lat - loc1.lat) * (Math.PI / 180)
        const dLng = (loc2.lng - loc1.lng) * (Math.PI / 180)
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(loc1.lat * (Math.PI / 180)) *
                Math.cos(loc2.lat * (Math.PI / 180)) *
                Math.sin(dLng / 2) *
                Math.sin(dLng / 2)
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
        const distance = R * c // Distance in km
        return distance * 0.621371 // Convert to miles
    }

    if (!page) {
        return <Loading />
    }

    return (
        <div className="schedule-page-wrapper">
            <h1 className="page-title">{page.title}</h1>
            <div className="schedule-page-map">
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={mapCenter}
                    zoom={10}
                    onLoad={onLoad}
                    onUnmount={onUnmount}
                >
                    {upcomingGamesByVenue
                        .filter(venue => venue.lat !== "" && venue.lng !== "")
                        .map(venue => (
                            <MarkerF
                                key={venue.id}
                                position={{
                                    lat: Number(venue.lat),
                                    lng: Number(venue.lng),
                                }}
                                onClick={() => setActiveMarker(venue.id)}
                            >
                                {activeMarker === venue.id && (
                                    <InfoBoxF>
                                        <div className="venue-infobox black-text">
                                            <button
                                                className="close-button"
                                                onClick={() =>
                                                    setActiveMarker(null)
                                                }
                                            >
                                                &times;
                                            </button>
                                            <h2>{venue.name}</h2>
                                            <p>{venue.day}</p>
                                            <p>{venue.time}</p>
                                        </div>
                                    </InfoBoxF>
                                )}
                            </MarkerF>
                        ))}
                </GoogleMap>
            </div>
            <div className="schedule-page-calendar">
                <Calendar
                    buttonData={filterVenues()}
                    buttonItem={venue => (
                        <Link to={`/game/${venue.id}`}>{venue.name}</Link>
                    )}
                    data={upcomingGamesByVenue}
                    setData={setVenues}
                    buttonClickAdd={null}
                    buttonClickEdit={null}
                    dateChanged={() => {}}
                    view="monthly"
                />
            </div>
        </div>
    )
}
