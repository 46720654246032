import { Link } from "react-router-dom"
import { useState, useEffect } from "react"
import dataService from "../data/dataService"
import { Loading } from "../components/Loading"

export default function About() {
    const [page, setPage] = useState(null)

    useEffect(() => {
        dataService
            .getData("pages", () => {})
            .then(data => {
                setPage(data.find(page => page.slug === "about"))
            })
    }, [])
    return page ? (
        <section className={`bg-${page.color}`}>
            <h1>{page.title}</h1>
            <h2>{page.subheadline}</h2>
            <p>
                {page.content}
            </p>
        </section>
    ) : (
        <Loading />
    )
}
