import { Link } from "react-router-dom"
import { useNavigate } from "react-router-dom"
import { useEffect, useState, useContext } from "react"
import moment from "moment"
import Calendar from "../../components/Calendar/Calendar"
import { FaCoins, FaUser, FaDiscord } from "react-icons/fa6"
import { BiLogoVenmo } from "react-icons/bi"
import { RiLogoutBoxRFill } from "react-icons/ri"
import { GiHamburgerMenu } from "react-icons/gi"

import PurchaseCredits from "../../components/PurchaseCredits/PurchaseCredits"

import Modal from "../../components/Modal/Modal"

import { TbHelpHexagonFilled } from "react-icons/tb"

import dataService from "../../data/dataService"

import {
    collection,
    getDocs,
    limit,
    query,
    orderBy,
    where,
    addDoc,
} from "firebase/firestore"
import { auth, db, logout } from "../../firebase"
import { useAuthState } from "react-firebase-hooks/auth"
import ScoresWizardStep1 from "./Scores/Step1"
import ScoresWizardStep2 from "./Scores/Step2"
import ScoresWizardStep3 from "./Scores/Step3"

import "./ScoresWizard.css"
import "./HostDashboard.css"
import ScoresWizardStep4 from "./Scores/Step4"
import ScoresWizardStep5 from "./Scores/Step5"

import LiveScoring from "../../components/LiveScoring/LiveScoring"
import { GiPlayButton } from "react-icons/gi"
import { FaRegCalendarPlus } from "react-icons/fa6"
import { LuFileSpreadsheet } from "react-icons/lu"

import { gameOptions } from "../../utils/dateUtils"
import CreateGameForm from "../../components/Forms/CreateGameForm"
import useGameStore from "../../data/gameStore"
import useVenueStore from "../../data/venueStore"
import useTemplateStore from "../../data/templateStore"
import useTransactionStore from "../../data/transactionStore"
import useUserStore from "../../data/userStore"
import FooterContext from "../../data/footerContext"

// this component is not just used for the Scores Admin
// but also for the Host Dashboard

export default function HostDashboard({ admin }) {
    const searchParams = new URLSearchParams(window.location.search)
    const showHelpInitially = searchParams.get("help") || false

    const [updatedVenmo, setUpdatedVenmo] = useState(false)
    const [savingGame, setSavingGame] = useState(false)
    const [seasons, setSeasons] = useState([])
    const [teams, setTeams] = useState([])
    const [loading, setLoading] = useState(false)
    const [updating, setUpdating] = useState(false)
    const [user] = useAuthState(auth)
    const [userData, setUserData] = useState({})
    const [wizardStep, setWizardStep] = useState(0)
    const [selectedSeason, setSelectedSeason] = useState("")
    const [selectedDate, setSelectedDate] = useState("")
    const [selectedVenue, setSelectedVenue] = useState("")
    const [games, setGames] = useState([])
    const [showGame, setShowGame] = useState(false)
    const [gameVenue, setGameVenue] = useState("")
    const [currentRound, setCurrentRound] = useState(0)
    const [showHelp, setShowHelp] = useState(showHelpInitially)
    const [legacy, setLegacy] = useState(false)
    const [templates, setTemplates] = useState([])
    const [creatingGame, setCreatingGame] = useState(false)
    const { currentMobileTab, setCurrentMobileTab } = useContext(FooterContext)
    const [showVenmo, setShowVenmo] = useState(false)

    const [showMobileMenu, setShowMobileMenu] = useState(false)
    const [editingGame, setEditingGame] = useState(false)
    const [rounds, setRounds] = useState([
        {
            round: 1,
            category: "",
        },
        {
            round: 2,
            category: "",
        },
        {
            round: 3,
            category: "",
        },
        {
            round: 4,
            category: "",
        },
        {
            round: 5,
            category: "",
        },
        {
            round: 6,
            category: "",
        },
        {
            round: 7,
            category: "",
        },
        {
            round: 8,
            category: "",
        },
    ])
    const [showPurchaseModal, setShowPurchaseModal] = useState(false)
    const [transferCredits, setTransferCredits] = useState(false)
    const [transferEmail, setTransferEmail] = useState("")
    const [transferAmount, setTransferAmount] = useState(0)
    const [gameCredits, setGameCredits] = useState(0)
    const [transferEmailVerified, setTransferEmailVerified] = useState(false)
    const hosts = useUserStore(state => state.hosts)

    const transactions = useTransactionStore(state => state.transactions)

    const navigate = useNavigate()
    const venues = useVenueStore(state => state.venues)

    const updateGame = game => {
        dataService.updateCollection("games", game.id, game)
        setGames(games.map(g => (g.id === game.id ? game : g)))
        setShowGame(game)
    }

    const showLiveScoring = date => {
        const formattedDate = moment(date).format("YYYY-MM-DD")
        const game = games.find(
            game => moment(game.date).format("YYYY-MM-DD") === formattedDate
        )

        // if (!game && moment(date) < moment().startOf("day")) {
        //     return
        // }

        if (game) {
            setShowGame(game)
            useGameStore.getState().setLiveScoring(true)
            useTemplateStore.getState().getTemplate(game.template, () => {})
            if (
                moment(
                    game.date.toDate
                        ? game.date.toDate()
                        : game.date.seconds
                        ? game.date.seconds
                        : game.date
                ).isBefore(gameOptions.legacyDate)
            ) {
                setLegacy(true)
            } else {
                setLegacy(false)
            }
            setGameVenue(venues.find(venue => venue.id === game.venue))
        } else {
            showVenueWizard(formattedDate)
        }
    }

    const updateVenmoHandle = () => {
        const venmoHandle = updatedVenmo

        dataService.saveField(
            userData.id,
            "venmoHandle",
            venmoHandle,
            "users",
            () => {}
        )
        setUserData({
            ...userData,
            venmoHandle,
        })
        setGameCredits(userData.gameCredits ? userData.gameCredits : 0)
        setUpdatedVenmo(false)
    }

    const showVenueWizard = date => {
        // show the venue wizard
        setSelectedSeason(seasons.find(season => season.active).id)
        // setSelectedDate(date)
        setCreatingGame(date ? date : "no-date")
    }

    const closeLiveScoring = () => {
        setShowGame(false)
        setSelectedDate("")
        setCurrentRound(0)
    }

    useEffect(() => {
        const handleEscapeKey = event => {
            if (event.key === "Escape") {
                closeLiveScoring()
                setShowHelp(false)
            }
        }

        document.addEventListener("keydown", handleEscapeKey)
        setCurrentMobileTab("profile")
        return () => {
            document.removeEventListener("keydown", handleEscapeKey)
        }
    }, [])

    const fetchGameCredits = async () => {
        const q = query(collection(db, "users"), where("uid", "==", user.uid))
        await getDocs(q).then(querySnapshot => {
            querySnapshot.forEach(doc => {
                setGameCredits(doc.data().gameCredits)
            })
        })
    }

    const consumeGameCredit = newGame => {
        const newCredits = gameCredits - 1
        setGameCredits(newCredits)
        dataService.saveField(
            userData.id,
            "gameCredits",
            newCredits,
            "users",
            () => {}
        )
        dataService.insertIntoCollection("transactions", {
            receiver: "~~POOL~~",
            giver: user.uid,
            amount: 0,
            credits: 1,
            date: moment().format("YYYY-MM-DD"),
            time: moment().format("HH:mm:ss"),
            type: "game",
            status: "completed",
            note: newGame.id,
        })
        useTransactionStore.getState().getAllTransactionsByUser(user.uid)
    }

    const initiateTransfer = async e => {
        e.preventDefault()
        if (
            window.confirm(
                `Are you sure you want to transfer ${transferAmount} credits to ${transferEmail}? This will leave you with ${
                    gameCredits - transferAmount
                } credits.`
            ) &&
            transferEmailVerified
        ) {
            dataService.insertIntoCollection("transactions", {
                receiver:
                    hosts.find(host => host.email === transferEmail)?.uid ||
                    "ERROR",
                giver: user.uid,
                amount: 0,
                credits: transferAmount,
                date: moment().format("YYYY-MM-DD"),
                time: moment().format("HH:mm:ss"),
                type: "transfer",
                status: "pending",
                note: "",
            })
            setTransferEmail("")
            setTransferAmount(0)
            setTransferCredits(false)
            setTransferEmailVerified(false)
            useTransactionStore.getState().getAllTransactionsByUser(user.uid)
            const newCredits = gameCredits - transferAmount
            setGameCredits(newCredits)
            dataService.saveField(
                userData.id,
                "gameCredits",
                newCredits,
                "users",
                () => {}
            )
        }
    }

    const refreshGames = async () => {
        setLoading(true)
        await fetchGames()
        setLoading(false)
    }

    const fetchGames = async () => {
        const q = query(collection(db, "games"), where("user", "==", user.uid))
        await getDocs(q).then(querySnapshot => {
            const newData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }))
            setGames(newData)
        })
    }

    const fetchTeams = async () => {
        await getDocs(collection(db, "teams")).then(querySnapshot => {
            const newData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }))
            setTeams(newData)
        })
    }

    const fetchTemplates = async () => {
        const matchingDates = [
            moment().startOf("week").add(2, "days").format("YYYY-MM-DD"),
            moment().startOf("week").add(3, "days").format("YYYY-MM-DD"),
            moment().startOf("week").add(4, "days").format("YYYY-MM-DD"),
        ]
        const q = query(
            collection(db, "templates"),
            where("date", "in", matchingDates)
        )
        await getDocs(q).then(querySnapshot => {
            const newData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }))
            setTemplates(newData)
        })
    }

    const deleteGame = async gameID => {
        if (window.confirm("Are you sure you want to delete this game?")) {
            await dataService.deleteDoc(gameID, "games", setGames, games)
            const newCredits = gameCredits + 1
            setGameCredits(newCredits)
            dataService.saveField(
                userData.id,
                "gameCredits",
                newCredits,
                "users",
                () => {}
            )
            dataService.insertIntoCollection("transactions", {
                receiver: user.uid,
                giver: user.uid,
                amount: 0,
                credits: 1,
                date: moment().format("YYYY-MM-DD"),
                time: moment().format("HH:mm:ss"),
                type: "game",
                status: "cancelled",
                note: gameID,
            })
            useTransactionStore.getState().getAllTransactionsByUser(user.uid)
        }
    }

    useEffect(() => {
        if (user) {
            setLoading(true)
            fetchGames()
            fetchTemplates()
            dataService.getData("seasons", () => {}).then(setSeasons)
            getUserData()
            setLoading(false)
        }
    }, [user])

    const getUserData = async () => {
        if (user) {
            const q = query(
                collection(db, "users"),
                where("uid", "==", user.uid)
            )
            const querySnapshot = await getDocs(q)
            querySnapshot.forEach(doc => {
                setUserData({
                    ...doc.data(),
                    id: doc.id,
                    gameCredits: doc.data().gameCredits || 0,
                })
                setGameCredits(doc.data().gameCredits)
                useTransactionStore
                    .getState()
                    .getAllTransactionsByUser(user.uid)
            })
        }
    }

    const validDates = [
        moment().startOf("week").add(2, "days").format("YYYY-MM-DD"),
        moment().startOf("week").add(3, "days").format("YYYY-MM-DD"),
        moment().startOf("week").add(4, "days").format("YYYY-MM-DD"),
    ]

    const handleEditGame = (game, e) => {
        e.stopPropagation()
        if (game.teams.length === 0) {
            setEditingGame(game)
        } else {
            alert("Cannot edit a game with teams")
        }
    }

    return (
        <section className="scores-enter-page host-dashboard">
            <div className="host-dashboard-header">
                <GiHamburgerMenu
                    className="host-dashboard-hamburger"
                    onClick={() => setShowMobileMenu(!showMobileMenu)}
                />
                <h2 className="host-dashboard-title">
                    {admin ? "Scores Admin" : "Host Dashboard"}
                </h2>
                <li
                    className={`host-dashboard-button game-credits-button game-credits-button-mobile  pending-${transactions.some(
                        t => t.status === "pending"
                    )}`}
                    onClick={() => setShowPurchaseModal(true)}
                >
                    <span className="icon">
                        <FaCoins />
                        <span className="game-credits">{gameCredits}</span>
                    </span>
                    <span className="label">Credits</span>
                </li>
                <ul
                    className={`host-dashboard-buttons ${
                        showMobileMenu ? "show" : "no-show"
                    }`}
                >
                    <li
                        className={`host-dashboard-button game-credits-button game-credits-button-desktop  pending-${transactions.some(
                            t => t.status === "pending"
                        )}`}
                        onClick={() => setShowPurchaseModal(true)}
                    >
                        <span className="icon">
                            <FaCoins />
                            <span className="game-credits">{gameCredits}</span>
                        </span>
                        <span className="label">Credits</span>
                    </li>
                    <li
                        className="host-dashboard-button discord-button"
                        onClick={() => {
                            setShowMobileMenu(false)
                            window.open(
                                "https://discord.gg/662jw2qSF3",
                                "_blank"
                            )
                        }}
                    >
                        <span className="icon">
                            <FaDiscord />
                        </span>
                        <span className="label">Discord</span>
                    </li>
                    <li
                        className="host-dashboard-button venmo-button"
                        onClick={() => {
                            setShowMobileMenu(false)
                            setShowVenmo(!showVenmo)
                        }}
                    >
                        <span className="icon">
                            <BiLogoVenmo />
                        </span>
                        <span className="label">Venmo</span>
                    </li>
                    <li
                        className="host-dashboard-button profile-button"
                        onClick={() => navigate(`/user/${user.uid}`)}
                    >
                        <span className="icon">
                            <FaUser />
                        </span>
                        <span className="label">Profile</span>
                    </li>
                    <li
                        className="host-dashboard-button score-sheets-button"
                        onClick={() => {
                            const link = document.createElement("a")
                            link.href = `/answersheets.zip`
                            link.download = "answersheets.zip"
                            document.body.appendChild(link)
                            link.click()
                            document.body.removeChild(link)
                        }}
                    >
                        <span className="icon">
                            <LuFileSpreadsheet />
                        </span>
                        <span className="label">Scores</span>
                    </li>
                    <li
                        className="host-dashboard-button help-button"
                        onClick={() => {
                            setShowMobileMenu(false)
                            setShowHelp(true)
                        }}
                    >
                        <span className="icon">
                            <TbHelpHexagonFilled />
                        </span>
                        <span className="label">Help</span>
                    </li>
                    <li
                        className="host-dashboard-button logout-button"
                        onClick={() => {
                            if (
                                window.confirm(
                                    "Are you sure you want to log out?"
                                )
                            ) {
                                logout()
                                navigate("/")
                            }
                        }}
                    >
                        <span className="icon">
                            <RiLogoutBoxRFill />
                        </span>
                        <span className="label">Logout</span>
                    </li>
                </ul>
            </div>

            {!admin && showVenmo && (
                <p className="host-venmo">
                    <span className="venmo-label">Venmo:</span>
                    <input
                        type="text"
                        placeholder="Venmo Handle"
                        defaultValue={userData.venmoHandle}
                        onChange={e => setUpdatedVenmo(e.target.value)}
                    />
                    {updatedVenmo && (
                        <span
                            className="save-venmo"
                            onClick={() => {
                                setShowVenmo(false)
                                updateVenmoHandle()
                            }}
                        >
                            Save
                        </span>
                    )}
                    <span
                        className="cancel-venmo"
                        onClick={() => setShowVenmo(false)}
                    >
                        Cancel
                    </span>
                </p>
            )}

            <hr />

            <div className="host-intelligent-buttons">
                {games.some(game =>
                    moment(game.date).isSame(moment(), "day")
                ) && (
                    <button
                        className="launch-todays-game-button black-text"
                        onClick={() => showLiveScoring(moment())}
                    >
                        <GiPlayButton />
                        <span>Launch Today's Game</span>
                    </button>
                )}
                <button
                    className="schedule-game-without-date"
                    onClick={() => showVenueWizard()}
                >
                    <FaRegCalendarPlus />
                    <span>Schedule Game</span>
                </button>
            </div>

            <div className="day-buttons-wrapper">
                <Calendar
                    buttonData={[]}
                    buttonItem={game => (
                        <Link to={`/game/${game.id}`}>{game.name}</Link>
                    )}
                    data={games}
                    setData={setGames}
                    buttonClickAdd={null}
                    buttonClickEdit={showLiveScoring}
                    dateChanged={() => {}}
                    view="host-dashboard"
                    showLiveScoring={showLiveScoring}
                    isAdmin={admin}
                    gameCredits={gameCredits}
                    handleEditGame={handleEditGame}
                />
            </div>

            {(showGame || selectedDate) && wizardStep === 0 && (
                <LiveScoring
                    gameVenue={gameVenue}
                    setGameVenue={setGameVenue}
                    gameID={showGame.id}
                    close={closeLiveScoring}
                    selectedDate={selectedDate}
                    refreshGames={refreshGames}
                    numberOfRoundsPerGame={
                        legacy
                            ? gameOptions.legacyNumberOfRoundsPerGame
                            : gameOptions.numberOfRoundsPerGame
                    }
                    questionsPerRound={
                        legacy
                            ? gameOptions.legacyQuestionsPerRound
                            : gameOptions.questionsPerRound
                    }
                    // currentRound={currentRound}
                    // setCurrentRound={setCurrentRound}
                    // rounds={rounds}
                    // categories={categories}
                    // setCategory={setCategory}
                />
            )}

            {wizardStep > 0 && (
                <section className="wizard-overlay">
                    <section className="wizard">
                        {wizardStep === 1 && (
                            <ScoresWizardStep1
                                seasons={seasons}
                                venues={venues}
                                teams={teams}
                                setUpdating={setUpdating}
                                fetchTeams={fetchTeams}
                                setWizardStep={setWizardStep}
                                setSelectedSeason={setSelectedSeason}
                                setSelectedDate={setSelectedDate}
                                setSelectedVenue={setSelectedVenue}
                            />
                        )}
                        {wizardStep === 2 && (
                            <ScoresWizardStep2
                                setWizardStep={setWizardStep}
                                setSelectedDate={setSelectedDate}
                                setSelectedSeason={setSelectedSeason}
                                setSelectedVenue={setSelectedVenue}
                            />
                        )}
                        {wizardStep === 3 && (
                            <ScoresWizardStep3
                                venues={venues}
                                setWizardStep={setWizardStep}
                                setSelectedVenue={setSelectedVenue}
                                selectedDate={selectedDate}
                                setSelectedSeason={setSelectedSeason}
                                setSelectedDate={setSelectedDate}
                            />
                        )}

                        {wizardStep === 4 && (
                            <ScoresWizardStep4
                                teams={teams}
                                setWizardStep={setWizardStep}
                                venue={venues.find(
                                    venue => venue.id === selectedVenue
                                )}
                                date={selectedDate}
                                season={seasons.find(
                                    season => season.id === selectedSeason
                                )}
                                fetchTeams={fetchTeams}
                                setSelectedSeason={setSelectedSeason}
                                setSelectedDate={setSelectedDate}
                                setSelectedVenue={setSelectedVenue}
                            />
                        )}
                        {wizardStep === 5 && (
                            <ScoresWizardStep5
                                setWizardStep={setWizardStep}
                                venue={venues.find(
                                    venue => venue.id === selectedVenue
                                )}
                                date={selectedDate}
                                season={seasons.find(
                                    season => season.id === selectedSeason
                                )}
                                setSelectedSeason={setSelectedSeason}
                                setSelectedDate={setSelectedDate}
                                setSelectedVenue={setSelectedVenue}
                            />
                        )}
                    </section>
                </section>
            )}

            {creatingGame && (
                <Modal
                    title="Schedule Game"
                    onClose={() => {
                        setCreatingGame(false)
                    }}
                >
                    <CreateGameForm
                        chosenSeason={selectedSeason}
                        chosenDate={creatingGame}
                        setCreatingGame={setCreatingGame}
                        fetchGames={fetchGames}
                        user={user.uid}
                        gameCredits={gameCredits}
                        consumeGameCredit={consumeGameCredit}
                        games={games}
                    />
                </Modal>
            )}

            {editingGame && (
                <Modal
                    title="Edit Game"
                    onClose={() => {
                        setEditingGame(false)
                    }}
                >
                    <CreateGameForm
                        chosenSeason={editingGame.season}
                        chosenDate={editingGame.date}
                        setCreatingGame={setEditingGame}
                        fetchGames={fetchGames}
                        user={user.uid}
                        gameCredits={gameCredits}
                        consumeGameCredit={consumeGameCredit}
                        game={editingGame}
                        games={games}
                        deleteGame={deleteGame}
                    />
                </Modal>
            )}

            {showHelp && (
                <section className="help-overlay">
                    <section className="help">
                        <span
                            className="close"
                            onClick={() => setShowHelp(false)}
                        >
                            x
                        </span>
                        <h1>Host Training</h1>
                        <div className="host-training-video">
                            <iframe
                                src="https://www.loom.com/embed/9a3b23b6b33d4ddf9f359d0dbe61ae16?sid=7e921e21-4410-42d7-bd98-a6a740adf95e"
                                frameborder="0"
                                webkitallowfullscreen
                                mozallowfullscreen
                                allowFullScreen
                                className="host-training-video-iframe"
                                title="host training"
                            ></iframe>
                            <div className="info">
                                <h2>How to Schedule a Game</h2>
                                <p>
                                    How to quickly schedule a game
                                    <span className="time"> &lt; 1 minute</span>
                                    .
                                </p>
                                <a
                                    href="https://www.loom.com/share/9a3b23b6b33d4ddf9f359d0dbe61ae16?sid=0276207b-6413-4947-82c8-4cf1569c7003"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Video Link
                                </a>
                            </div>
                        </div>
                    </section>
                </section>
            )}

            {showPurchaseModal && (
                <Modal
                    title="Manage Game Credits"
                    onClose={() => setShowPurchaseModal(false)}
                    className="manage-game-credits"
                >
                    <div id="card-container"></div>
                    <div className="manage-game-credits-buttons">
                        <span className="current-game-credits">
                            <FaCoins />
                            <span className="game-credits">{gameCredits}</span>
                        </span>
                        <button
                            className="transfer-credits-button"
                            onClick={() => setTransferCredits(!transferCredits)}
                        >
                            Transfer
                        </button>
                    </div>
                    {transferCredits && (
                        <>
                            <h2>Transfer Credits</h2>
                            <div className="transfer-credits">
                                <p>
                                    Please enter the email of the host who you
                                    want to transfer credits to and the amount.
                                    Credits will be transferred out of your
                                    account and held for their acceptance.
                                    Transfers can be cancelled prior to their
                                    acceptance.
                                </p>
                                <form
                                    className="transfer-credits-form"
                                    onSubmit={initiateTransfer}
                                >
                                    <input
                                        type="text"
                                        placeholder="Host Email"
                                        onChange={e => {
                                            setTransferEmail(e.target.value)
                                            setTransferEmailVerified(
                                                hosts.find(
                                                    host =>
                                                        host.email ===
                                                        e.target.value
                                                ) &&
                                                    e.target.value !==
                                                        user.email
                                            )
                                        }}
                                        value={transferEmail}
                                    />
                                    <input
                                        type="number"
                                        placeholder="Credits"
                                        onChange={e =>
                                            setTransferAmount(e.target.value)
                                        }
                                        value={transferAmount}
                                        min="1"
                                        max={gameCredits}
                                    />
                                    <input
                                        type="submit"
                                        value={
                                            transferEmailVerified
                                                ? "Initiate Transfer"
                                                : "Enter Valid Host Email"
                                        }
                                        disabled={!transferEmailVerified}
                                    />
                                </form>
                            </div>
                        </>
                    )}
                    <PurchaseCredits
                        user={user}
                        userData={userData}
                        setGameCredits={setGameCredits}
                        gameCredits={gameCredits}
                        setShowPurchaseModal={setShowPurchaseModal}
                    />
                    <div className="transaction-history">
                        <h2>Transaction History</h2>
                        <ul className="transactions-list">
                            <li className="transaction transaction-header">
                                <span className="transaction-date">Date</span>
                                <span className="transaction-type">Type</span>
                                <span className="transaction-amount">
                                    Amount
                                </span>
                                <span className="transaction-credits">
                                    Credits
                                </span>
                                <span className="transaction-actiions">
                                    Actions
                                </span>
                            </li>
                            {transactions
                                .sort((a, b) => {
                                    if (a.date > b.date) return -1
                                    if (a.date < b.date) return 1
                                    return a.time > b.time ? -1 : 1
                                })
                                .map((transaction, index, transactionArray) => (
                                    <li
                                        key={transaction.id}
                                        className="transaction"
                                    >
                                        <span className="transaction-date">
                                            <span>
                                                {moment(
                                                    transaction.date
                                                ).format("ll")}
                                            </span>
                                            <span className="transaction-time">
                                                {moment(
                                                    transaction.time,
                                                    "HH:mm:ss"
                                                ).format("LT")}
                                            </span>
                                        </span>
                                        <span className="transaction-type">
                                            {transaction.type === "game" &&
                                            !transactionArray.some(
                                                tA =>
                                                    tA.note ===
                                                        transaction.note &&
                                                    tA.status === "cancelled"
                                            ) &&
                                            transaction.status !==
                                                "cancelled" ? (
                                                <Link
                                                    to={`/game/${transaction.note}`}
                                                    target="_blank"
                                                    className={`game-credit-status-${transaction.status}`}
                                                >
                                                    Game{" "}
                                                    {transaction.note.slice(-4)}
                                                </Link>
                                            ) : (
                                                <>
                                                    <span className="transaction-type-title">
                                                        {transaction.type ===
                                                            "transfer" &&
                                                        transaction.giver ===
                                                            user.uid
                                                            ? `Transfer to ${
                                                                  hosts.find(
                                                                      host =>
                                                                          host.uid ===
                                                                          transaction.receiver
                                                                  )?.name
                                                              }`
                                                            : transaction.type ===
                                                                  "transfer" &&
                                                              transaction.receiver ===
                                                                  user.uid
                                                            ? `Transfer from ${
                                                                  hosts.find(
                                                                      host =>
                                                                          host.uid ===
                                                                          transaction.giver
                                                                  )?.name
                                                              }`
                                                            : transaction.type ===
                                                                  "game" &&
                                                              transaction.status ===
                                                                  "cancelled"
                                                            ? `Refund ${transaction.note.slice(
                                                                  -4
                                                              )}`
                                                            : transaction.type ===
                                                              "game"
                                                            ? `Game ${transaction.note.slice(
                                                                  -4
                                                              )}`
                                                            : transaction.type}
                                                    </span>

                                                    {transaction.type !==
                                                        "game" && (
                                                        <span className="transaction-type-status">
                                                            {transaction.status}
                                                        </span>
                                                    )}
                                                </>
                                            )}
                                        </span>
                                        <span className="transaction-amount">
                                            ${Number(transaction.amount)}
                                        </span>
                                        <span
                                            className={`transaction-credits transaction-credit-type-${
                                                transaction.type
                                            } ${
                                                transaction.type ===
                                                    "transfer" &&
                                                transaction.giver === user.uid
                                                    ? "negative"
                                                    : "positive"
                                            } ${
                                                transaction.type === "admin" &&
                                                transaction.credits > 0
                                                    ? "admin-positive"
                                                    : ""
                                            } ${
                                                transaction.type === "game" &&
                                                transaction.status ===
                                                    "cancelled"
                                                    ? "game-positive"
                                                    : "game-negative"
                                            }`}
                                        >
                                            {transaction.type === "game" &&
                                                transaction.status ===
                                                    "cancelled" &&
                                                "+"}
                                            {transaction.type === "game" &&
                                                transaction.status !==
                                                    "cancelled" &&
                                                "-"}
                                            {transaction.type === "purchase" &&
                                                "+"}
                                            {transaction.type === "transfer" &&
                                                (transaction.giver === user.uid
                                                    ? "-"
                                                    : "+")}
                                            {transaction.type === "admin" &&
                                                transaction.credits > 0 &&
                                                "+"}

                                            {transaction.credits}
                                        </span>
                                        <span className="transaction-actions">
                                            {transaction.type === "transfer" &&
                                                transaction.receiver ===
                                                    user.uid &&
                                                transaction.status ===
                                                    "pending" && (
                                                    <>
                                                        <button
                                                            className="accept-transaction"
                                                            onClick={() => {
                                                                if (
                                                                    window.confirm(
                                                                        "Are you sure you want to accept this transfer?"
                                                                    )
                                                                ) {
                                                                    dataService.saveField(
                                                                        transaction.id,
                                                                        "status",
                                                                        "completed",
                                                                        "transactions",
                                                                        () => {}
                                                                    )
                                                                    const newCredits =
                                                                        Number(
                                                                            gameCredits
                                                                        ) +
                                                                        Number(
                                                                            transaction.credits
                                                                        )
                                                                    setGameCredits(
                                                                        newCredits
                                                                    )
                                                                    useTransactionStore
                                                                        .getState()
                                                                        .addCreditsToUser(
                                                                            transaction.receiver,
                                                                            transaction.credits
                                                                        )
                                                                    useTransactionStore
                                                                        .getState()
                                                                        .getAllTransactionsByUser(
                                                                            user.uid
                                                                        )
                                                                }
                                                            }}
                                                        >
                                                            Accept
                                                        </button>
                                                        <button
                                                            className="decline-transaction"
                                                            onClick={() => {
                                                                if (
                                                                    window.confirm(
                                                                        "Are you sure you want to decline this transfer?"
                                                                    )
                                                                ) {
                                                                    dataService.saveField(
                                                                        transaction.id,
                                                                        "status",
                                                                        "declined",
                                                                        "transactions",
                                                                        () => {}
                                                                    )
                                                                    useTransactionStore
                                                                        .getState()
                                                                        .addCreditsToUser(
                                                                            transaction.giver,
                                                                            transaction.credits
                                                                        )
                                                                    useTransactionStore
                                                                        .getState()
                                                                        .getAllTransactionsByUser(
                                                                            user.uid
                                                                        )
                                                                }
                                                            }}
                                                        >
                                                            Decline
                                                        </button>
                                                    </>
                                                )}

                                            {transaction.type === "transfer" &&
                                                transaction.giver ===
                                                    user.uid &&
                                                transaction.status ===
                                                    "pending" && (
                                                    <button
                                                        className="cancel-transaction"
                                                        onClick={() => {
                                                            if (
                                                                window.confirm(
                                                                    "Are you sure you want to cancel this transfer?"
                                                                )
                                                            ) {
                                                                dataService.saveField(
                                                                    transaction.id,
                                                                    "status",
                                                                    "cancelled",
                                                                    "transactions",
                                                                    () => {}
                                                                )
                                                                const newCredits =
                                                                    Number(
                                                                        gameCredits
                                                                    ) +
                                                                    Number(
                                                                        transaction.credits
                                                                    )
                                                                setGameCredits(
                                                                    newCredits
                                                                )
                                                                useTransactionStore
                                                                    .getState()
                                                                    .addCreditsToUser(
                                                                        transaction.giver,
                                                                        transaction.credits
                                                                    )
                                                                useTransactionStore
                                                                    .getState()
                                                                    .getAllTransactionsByUser(
                                                                        user.uid
                                                                    )
                                                            }
                                                        }}
                                                    >
                                                        Cancel
                                                    </button>
                                                )}
                                        </span>
                                    </li>
                                ))}
                        </ul>
                    </div>
                </Modal>
            )}
        </section>
    )
}
