import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import useTeamStore from "../../data/teamStore"
import useSeasonStore from "../../data/seasonStore"

import homepageService from "../../data/homepageService"
import useUserStore from "../../data/userStore"

import Modal from "../Modal/Modal"
import TeamPage from "../../pages/Team"
import SeasonCountdown from "../SeasonCountdown/SeasonCountdown"

export default function Standings() {
    const [top20Teams, setTop20Teams] = useState([])
    const activeSeason = useSeasonStore(state => state.activeSeason)
    const [currentTeam, setCurrentTeam] = useState(0)
    const currentUser = useUserStore(state => state.currentUser)

    useEffect(() => {
        let isMounted = true

        homepageService.getTop20TeamsOfCurrentSeason().then(teams => {
            if (isMounted) {
                setTop20Teams(
                    teams.map(t => {
                        return {
                            ...useTeamStore
                                .getState()
                                .teams.find(team => team.id === t.id),
                            total: t.total,
                        }
                    })
                )
            }
        })
        return () => {
            isMounted = false
        }
    }, [activeSeason])

    return (
        <div className="home__standings">
            <h2>{activeSeason?.name} Standings</h2>
            <SeasonCountdown />
            <div className="home__standings-list">
                <div className="home__standings-team home__standings-team-header">
                    <span className="home__standings-team-rank">Rank</span>
                    <span className="home__standings-team-name">Team</span>
                    <span className="home__standings-team-total">Total</span>
                </div>
                {top20Teams.map((team, index) => (
                    <div
                        key={team.id}
                        className={`home__standings-team home__standings-team-${index} ${
                            currentUser?.teams?.includes(team.id)
                                ? "user-team"
                                : ""
                        }`}
                        onClick={() => setCurrentTeam(team.id)}
                    >
                        <span className="home__standings-team-rank">
                            {index + 1}
                        </span>
                        <span className="home__standings-team-name">
                            {team.name}
                        </span>
                        <span className="home__standings-team-total">
                            {team.total}
                        </span>
                    </div>
                ))}
            </div>
            <Link
                to={`/standings/${activeSeason?.slug}`}
                className="home__view-more splash-bg splash-border"
            >
                Full Standings
            </Link>
            {currentTeam !== 0 && (
                <Modal
                    title="Team Details"
                    onClose={() => setCurrentTeam(0)}
                    className="team-modal small-header"
                >
                    <TeamPage id={currentTeam} />
                </Modal>
            )}
        </div>
    )
}
