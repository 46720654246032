import React from "react"
import QRCode from "qrcode.react"

export default function Rules() {
    const localGame = JSON.parse(localStorage.getItem("smt-game"))
    return (
        <div className="slide rules-slide">
            <h1 className="slide-title">Website Stats</h1>
            <div className="slide-black-box">
                <main className="split">
                    <section className="split-left">
                        <ul className="slide-rules-list">
                            <li>
                                Sign in your team, join a team or create a team
                                to play and track your stats.
                            </li>
                            <li>
                                Scan the QR code to the right to join the game.
                            </li>
                            <li>
                                <strong>
                                    If you miss this opportunity, be sure to see
                                    the host after the game to track your users
                                    stats.
                                </strong>
                            </li>
                        </ul>
                    </section>
                    <section className="split-right">
                        <QRCode
                            className="game-qr-for-slides"
                            value={`${window.location.origin}/live-game/${localGame.id}/${localGame.template}`}
                            size={500}
                        />
                    </section>
                </main>
            </div>
        </div>
    )
}
