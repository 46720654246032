import React, { useState } from "react"

import moment from "moment"

import VenueList from "../LiveScoring/VenueList"
import useVenueStore from "../../data/venueStore"
import useTemplateStore from "../../data/templateStore"
import { gameOptions } from "../../utils/dateUtils"
import dataService from "../../data/dataService"
import useUserStore from "../../data/userStore"

export default function CreateGameForm({
    chosenSeason,
    chosenDate,
    setCreatingGame,
    user,
    fetchGames,
    gameCredits,
    consumeGameCredit,
    game = {},
    games = [],
    deleteGame = () => {},
}) {
    const [visibility, setVisibility] = useState("public")
    const [date, setDate] = useState(chosenDate)
    const editing = !!game.id
    const createGame = async (venue, chosenTime) => {
        // if the date is not a Tue, Wed or Thur then alert and return
        if (
            moment(date).day() !== 2 &&
            moment(date).day() !== 3 &&
            moment(date).day() !== 4
        ) {
            alert(
                "Games can only be created on Tuesday, Wednesday or Thursday."
            )
            return
        }
        if (games.filter(g => g.id !== game.id).some(g => g.date === date)) {
            alert("You already have a game for this date.")
            return
        }
        if (editing) {
            const template = await useTemplateStore
                .getState()
                .findTemplateForDate(chosenDate)

            const updatedGame = {
                venue,
                time: chosenTime,
                visibility,
                date,
                template: template ? template.id : "",
            }
            dataService
                .updateCollection("games", game.id, updatedGame)
                .then(() => {
                    fetchGames()
                    setCreatingGame(false)
                })
        } else {
            const confirm = window.confirm(
                `You currently have ${gameCredits} game credits. Are you sure you want to create a game and consume one? This will leave you with ${
                    gameCredits - 1
                }.`
            )
            if (!confirm) {
                return
            }
            const template = await useTemplateStore
                .getState()
                .findTemplateForDate(chosenDate)
            const game = {
                date: chosenDate !== "no-date" ? chosenDate : date,
                dateString: chosenDate !== "no-date" ? chosenDate : date,
                currentSlide: 0,
                venue,
                time: chosenTime,
                season: chosenSeason,
                user,
                status: "created",
                visibility: visibility,
                teams: [],
                template: template ? template.id : "",
                rounds: Array.from(
                    { length: gameOptions.numberOfRoundsPerGame },
                    (_, i) => ({
                        round: i + 1,
                        category: "",
                    })
                ),
            }
            dataService.insertIntoCollection("games", game).then(newGame => {
                consumeGameCredit(newGame)
                fetchGames()
                setCreatingGame(false)
            })
        }

        //   setCreatingGame({ venue, date })
    }
    return (
        <div className="create-game-form">
            <h2 className="create-game-form-title">
                {chosenDate !== "no-date" && !editing ? (
                    moment(chosenDate).format("MMMM Do YYYY")
                ) : (
                    <span className="create-game-form-date">
                        <label htmlFor="date">Date:</label>
                        <input
                            type="date"
                            id="date"
                            value={date}
                            onChange={e => setDate(e.target.value)}
                            placeholder="Select a Date"
                        />
                    </span>
                )}
            </h2>
            {(chosenDate !== "no-date" || date) && (
                <p className="create-game-form-visiblity">
                    <input
                        type="radio"
                        name="visibility"
                        value="public"
                        checked={
                            visibility === "public" ||
                            game.visibility === "public"
                        }
                        onChange={() => setVisibility("public")}
                        id="public"
                    />
                    <label htmlFor="public">Public</label>
                    <input
                        type="radio"
                        name="visibility"
                        value="private"
                        checked={
                            visibility === "private" ||
                            game.visibility === "private"
                        }
                        onChange={() => setVisibility("private")}
                        id="private"
                    />
                    <label htmlFor="private">Private</label>
                </p>
            )}
            {visibility === "private" && (
                <p className="create-game-form-visibility-management">
                    Private games are only visible to you and your friends. It
                    will not be available on the Upcoming Games Schedule. You
                    can invite friends to your game by sharing the game link.
                </p>
            )}

            <VenueList
                venues={useVenueStore(state => state.venues)}
                createGame={createGame}
                date={date}
                selectedDate={date}
                admin={false}
                editing={editing}
                game={game}
                deleteGame={deleteGame}
                setCreatingGame={setCreatingGame}
            />
        </div>
    )
}
