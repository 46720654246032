const sampleQuestions = [
    {
        category: "History",
        question:
            "What country gained it's independence from Great Britain in 1947?",
        answer: "India",
    },
    {
        category: "History",
        question:
            "Which empire, known for its terracotta army, was the first to unify China?",
        answer: "The Qin Dynasty",
    },
    {
        category: "History",
        question:
            "What year marked the beginning of the Protestant Reformation, initiated by Martin Luther’s Ninety-Five Theses?",
        answer: "1517",
    },
    {
        category: "History",
        question:
            "Which treaty, signed in 1783, formally ended the American Revolutionary War?",
        answer: "The Treaty of Paris",
    },
    {
        category: "History",
        question:
            "Who was the first female monarch of England to rule in her own right, beginning her reign in the 16th century?",
        answer: "Mary I (Mary Tudor)",
    },
    {
        category: "History",
        question:
            "Which year marked the start of the Great Depression, following the stock market crash in the United States?",
        answer: "1929",
    },
    {
        category: "History",
        question:
            "What year marked the founding of the League of Nations, intended to prevent future conflicts following World War I?",
        answer: "1920",
    },
    {
        category: "History",
        question:
            "Which Norse explorer is believed to have reached North America around the year 1000 CE, long before Columbus?",
        answer: "Leif Erikson",
    },
    {
        category: "Mystery",
        subcategory: "Spooky Movies",
        question:
            "In Zombieland, what is Columbus’ number one rule for surviving a zombie apocalypse?",
        answer: "Cardio (Zombies aren't fast)",
    },
    {
        category: "Mystery",
        subcategory: "Spooky Movies",
        question:
            "In Practical Magic, what plant do the Owens sisters put into Jimmy's tequila to sedate him, but inadvertently kills him instead?",
        answer: "Belladonna",
    },
    {
        category: "Mystery",
        subcategory: "Spooky Movies",
        question:
            "In The Nightmare Before Christmas, what is Jack Skellington's title in Halloween Town?",
        answer: "The Pumpkin King",
    },
    {
        category: "Mystery",
        subcategory: "Spooky Movies",
        question:
            "What is the name of the possessed doll in The Conjuring universe?",
        answer: "Annabelle",
    },
    {
        category: "Mystery",
        subcategory: "Spooky Movies",
        question: "In Hotel Transylvania, who is Dracula’s daughter?",
        answer: "Mavis",
    },
    {
        category: "Mystery",
        subcategory: "Spooky Movies",
        question:
            "In The Rocky Horror Picture Show who plays the role of Dr. Frank-N-Furter?",
        answer: "Tim Curry",
    },
    {
        category: "Mystery",
        subcategory: "Spooky Movies",
        question:
            "What 1996 movie, staring Robin Tunney, Fairuza Balk, and Neve Campbell, follows a group of Catholic prep high school students who practice witchcraft against those who anger them?",
        answer: "The Craft",
    },
    {
        category: "Mystery",
        subcategory: "Spooky Movies",
        question: "Who directed the 1984 film A Nightmare on Elm Street?",
        answer: "Wes Craven",
    },
    {
        category: "Places",
        question: "What is the capital of Switzerland?",
        answer: "Bern",
    },
    {
        category: "Places",
        question:
            "What river forms part of the border between Germany and Poland?",
        answer: "The Oder River",
    },
    {
        category: "Places",
        question: "What country is located directly south-east of Bangladesh?",
        answer: "Myanmar",
    },
    {
        category: "Places",
        question: "Which U.S. state is home to Yellowstone National Park?",
        answer: "Wyoming",
    },
    {
        category: "Places",
        question:
            "What famous landmark in San Francisco is known for its red suspension design?",
        answer: "Golden Gate Bridge",
    },
    {
        category: "Places",
        question: "What river runs through Cairo, Egypt?",
        answer: "The Nile River",
    },
    {
        category: "Places",
        question:
            "Which South American country is known for the ancient city of Machu Picchu?",
        answer: "Peru",
    },
    {
        category: "Places",
        question:
            "What large rock formation is located in Australia’s Northern Territory?",
        answer: "Uluru (Ayers Rock)",
    },
    {
        category: "Science",
        question:
            "What gas do humans exhale as a waste product of respiration?",
        answer: "Carbon dioxide",
    },
    {
        category: "Science",
        question: "What is the hardest natural substance on Earth?",
        answer: "Diamond",
    },
    {
        category: "Science",
        question: "What is the term for an organism’s genetic makeup?",
        answer: "Genotype",
    },
    {
        category: "Science",
        question:
            "What is the scientific term for the outer shell of an electron?",
        answer: "Valence shell",
    },
    {
        category: "Science",
        question:
            "What is the term for a solid that forms from a solution during a chemical reaction?",
        answer: "Precipitate",
    },
    {
        category: "Science",
        question: "What element has the atomic number 7?",
        answer: "Nitrogen",
    },
    {
        category: "Science",
        question: "What is the study of cells called?",
        answer: "Cytology",
    },
    {
        category: "Science",
        question: "What type of energy is stored in food?",
        answer: "Chemical energy",
    },
    {
        category: "Pop Culture",
        question:
            'Who is the main character in the "Diary of a Wimpy Kid" book series?',
        answer: "Greg Heffley",
    },
    {
        category: "Pop Culture",
        question:
            "What American fast food restaurant, specializing in New York–style pizza sold by the slice, can often be found in food courts?",
        answer: "Sbarro",
    },
    {
        category: "Pop Culture",
        question:
            'What was the name of the high school in the TV show "Saved by the Bell"?',
        answer: "Bayside High",
    },
    {
        category: "Pop Culture",
        question:
            "Which star made history as the youngest person to become a coveted EGOT (Emmy, Grammy, Oscar, and Tony) award winner?",
        answer: "Jennifer Hudson (40 years old)",
    },
    {
        category: "Pop Culture",
        question:
            "What internet company's first sale was a broken laser pointer in 1995?",
        answer: "eBay",
    },
    {
        category: "Pop Culture",
        question:
            "What former Saturday Night Live star has been romantically linked to Ariana Grande, Kim Kardashian, and Kate Beckinsale?",
        answer: "Pete Davidson",
    },
    {
        category: "Pop Culture",
        question:
            "What is considered to be the most valuable Beanie Baby of all time?",
        answer: "The Princess (Diana) Bear",
    },
    {
        category: "Pop Culture",
        question:
            "What is the name of the alternate dimension often explored on Stranger Things?",
        answer: "“The Upside Down”",
    },
    {
        category: "Sports",
        question: "What was Muhammad Ali's most famous nickname?",
        answer: '"The Greatest"',
    },
    {
        category: "Sports",
        question:
            "What is the name of the trophy awarded to the best college football player in the United States?",
        answer: "Heisman Trophy",
    },
    {
        category: "Sports",
        question: "What line separates the two halves of a football field?",
        answer: "The 50-yard line",
    },
    {
        category: "Sports",
        question:
            "What sports equipment is set at 7'11\" high for men, 7'8\" for coed, and 7' 4\" for women?",
        answer: "Volleyball Net",
    },
    {
        category: "Sports",
        question:
            "What is the maximum number of players on a basketball roster in the NBA during the regular season?",
        answer: "15",
    },
    {
        category: "Sports",
        question:
            "What is the USA's record for consecutive Olympic gold medals in men's basketball?",
        answer: "7 consecutive titles",
    },
    {
        category: "Sports",
        question: "In which sport is the Davis Cup awarded?",
        answer: "Tennis",
    },
    {
        category: "Sports",
        question: "What is the most common nickname for a hole-in-one?",
        answer: "Ace",
    },
    {
        category: "Movies",
        question:
            "In Once Upon a Time in Hollywood, what killer family does the film portray in an alternate history?",
        answer: "The Manson Family",
    },
    {
        category: "Movies",
        question:
            "In The Silence of the Lambs, what insect does Clarice find in one of the victim's throats?",
        answer: "A Moth",
    },
    {
        category: "Movies",
        question:
            "In Good Will Hunting, what job does Will have at the start of the film?",
        answer: "Janitor at MIT",
    },
    {
        category: "Movies",
        question:
            "In Boogie Nights, what is the stage name of the main character played by Mark Wahlberg?",
        answer: "Dirk Diggler",
    },
    {
        category: "Movies",
        question:
            "In Shutter Island, what is Teddy Daniels searching for on the island?",
        answer: "A missing patient",
    },
    {
        category: "Movies",
        question:
            "In Cast Away, what is the name of the volleyball that becomes Chuck's companion?",
        answer: "Wilson",
    },
    {
        category: "Movies",
        question:
            "In Reservoir Dogs, what is the color alias of the character played by Harvey Keitel?",
        answer: "Mr. White",
    },
    {
        category: "Movies",
        question:
            "In The Devil Wears Prada, what is the name of Miranda Priestly’s assistant, played by Anne Hathaway?",
        answer: "Andrea (Andy) Sachs",
    },
    {
        category: "Music",
        question:
            "Who performed a duet with Elton John on the song “Don’t Let the Sun Go Down on Me” in 1991?",
        answer: "George Michael",
    },
    {
        category: "Music",
        question:
            "Who is known for their signature dance moves and the hit “Super Freak”?",
        answer: "Rick James",
    },
    {
        category: "Music",
        question:
            "Which jazz singer is known for her renditions of “Summertime” and “Cry Me a River”?",
        answer: "Ella Fitzgerald",
    },
    {
        category: "Music",
        question:
            "Who was the original lead singer of The Commodores before he launched a solo career?",
        answer: "Lionel Richie",
    },
    {
        category: "Music",
        question:
            "What iconic pop group was composed of sisters June, Bonnie, and Ruth?",
        answer: "The Pointer Sisters",
    },
    {
        category: "Music",
        question: "Who is known as the “King of Reggae”?",
        answer: "Bob Marley",
    },
    {
        category: "Music",
        question: "What song did Johnny Cash record live at prison in 1968?",
        answer: '"Folsom Prison Blues"',
    },
    {
        category: "Music",
        question:
            "What popular 1990s boy band had members AJ McLean, Howie Dorough, Nick Carter, Kevin Richardson, and Brian Littrell?",
        answer: "Backstreet Boys",
    },
]

export default sampleQuestions
