export function Loading() {
    return (
        <span className="loading-wrapper">
            <img
                src="/images/blackLoading.gif"
                alt="loading"
                className="loading"
                width="500"
            />
        </span>
    )
}

export function SmallLoading() {
    return (
        <span className="small-loading-wrapper">
            <img
                src="/images/blackLoading.gif"
                alt="loading"
                className="loading"
                width="300"
            />
        </span>
    )
}
