import React from "react"
import { FaPencilAlt } from "react-icons/fa" // Import pencil icon

import useOneGameStore from "../../data/oneGameStore"
import useCategoryStore from "../../data/categoryStore"
import useTemplateStore from "../../data/templateStore"
import { gameOptions } from "../../utils/dateUtils"

export default function RoundList({ processCurrentRound, setCategory }) {
    const game = useOneGameStore(state => state.game)
    const categories = useCategoryStore(state => state.categories)
    const gameTeams = useOneGameStore(state => state.gameTeams)
    const subCat = useTemplateStore(state => state.subCat)

    return (
        <ul className="live-scoring-list">
            {game.rounds.map((round, i) =>
                i < gameOptions.numberOfRoundsPerGame ? (
                    <li
                        key={round.round}
                        onClick={e => {
                            if (
                                e.target.tagName !== "svg" &&
                                e.target.tagName !== "path"
                            ) {
                                processCurrentRound(round.round)
                            }
                        }}
                    >
                        <span className="round-item-label">
                            Round {round.round}
                        </span>
                        <span className="round-item-category">
                            {round.category !== "" ? (
                                <span className="round-item-category-label">
                                    {
                                        categories.find(
                                            cat => cat.id === round.category
                                        )?.name
                                    }

                                    <FaPencilAlt
                                        className="edit-category-icon"
                                        onClick={() =>
                                            processCurrentRound(
                                                round.round,
                                                true
                                            )
                                        }
                                    />
                                </span>
                            ) : (
                                <span
                                    className="round-item-category-label"
                                    onClick={() =>
                                        processCurrentRound(round.round, true)
                                    }
                                >
                                    <small>No Category Yet</small>
                                </span>
                            )}
                            {round.category !== ""
                                ? categories.find(
                                      cat => cat.id === round.category
                                  )?.name === "Mystery" && (
                                      <span className="round-item-subcategory">
                                          {subCat}
                                      </span>
                                  )
                                : ""}
                        </span>
                        <span className="round-item-score">
                            {
                                // count how many scores are touched for this round
                                gameTeams.reduce((count, team) => {
                                    const score = team.scores.find(
                                        score => score.round === round.round
                                    )
                                    return score?.touched ? count + 1 : count
                                }, 0)
                            }
                            /{gameTeams.length}
                        </span>
                    </li>
                ) : null
            )}
        </ul>
    )
}
