import React, { useState, useEffect } from "react"
import { Link, useLocation } from "react-router-dom"
import { useAuthState } from "react-firebase-hooks/auth"
import { auth, db } from "../../firebase"
import dataService from "../../data/dataService"
import { collection, getDocs, query, where } from "firebase/firestore"
import { MdAdminPanelSettings } from "react-icons/md"
import { GiMoneyStack } from "react-icons/gi"

import "./Layout.css"
import Modal from "../Modal/Modal"
import TeamPage from "../../pages/Team"
import useHeaderStore from "../../data/headerStore"
import Search from "./Search"

export default function Header({ dark, setDark, isMobile }) {
    const [user] = useAuthState(auth)
    const [userData, setUserData] = useState({})
    const [seasons, setSeasons] = useState([])
    const [mobileMenu, setMobileMenu] = useState(false)
    const [currentTeam, setCurrentTeam] = useState(null)
    const [liveGameURL, setLiveGameURL] = useState("")
    const showHeader = useHeaderStore(state => state.showHeader)

    const location = useLocation()

    useEffect(() => {
        dataService.getData("seasons", () => {}).then(setSeasons)
        getUserData()
    }, [user])

    const getUserData = async () => {
        if (user) {
            const q = query(
                collection(db, "users"),
                where("uid", "==", user.uid)
            )
            const querySnapshot = await getDocs(q)
            querySnapshot.forEach(doc => {
                setUserData(doc.data())
            })
        }
    }

    useEffect(() => {
        const ttlurl = localStorage.getItem("ttl-game-url")
        if (ttlurl) {
            setLiveGameURL(ttlurl)
        }
    }, [])

    const convertPathNameToCSSClass = pathname => {
        const path = pathname.split("/")[1]
        if (pathname === "/") {
            return "home"
        }
        return path
    }

    return (
        <header
            className={`header gray ${dark && "dark"} show-${showHeader} ${
                isMobile && "mobile"
            } header-${convertPathNameToCSSClass(location.pathname)}`}
        >
            <nav className="header__nav">
                <Link className="header__nav__link" to="/">
                    <img
                        className="header__nav__logo"
                        alt="Trivia Takeover Live"
                        src="/images/newLogo300.png"
                        height="70"
                    />
                    <span className="header__nav__title">
                        Trivia Takeover Live
                    </span>
                </Link>
                {/* {user && liveGameURL !== "" && (
                        <span className="live-game">
                            <Link to={liveGameURL}>Live Game</Link>
                        </span>
                    )} */}
                {/* <a
                    href="#menu"
                    className="menu"
                    onClick={e => {
                        e.preventDefault()
                        setMobileMenu(!mobileMenu)
                    }}
                >
                    <Bars3Icon height="50" />
                </a> */}
                <Search
                    currentTeam={currentTeam}
                    setCurrentTeam={setCurrentTeam}
                    view="header"
                />
                <ul className={`mobile-menu-${mobileMenu}`}>
                    {user === null || userData.role !== "host" ? (
                        <li className="make-money">
                            <Link
                                to="/host"
                                onClick={() => setMobileMenu(false)}
                            >
                                <GiMoneyStack />
                                <small>Make Money</small>
                            </Link>
                        </li>
                    ) : null}

                    {user &&
                        (userData.role === "host" ||
                            userData.role === "admin") && (
                            <>
                                <li>
                                    <Link
                                        to="/host-dashboard"
                                        onClick={() => setMobileMenu(false)}
                                    >
                                        Dashboard
                                    </Link>
                                </li>
                            </>
                        )}

                    {user && userData.role === "admin" && (
                        <li className="admin-header-link">
                            <Link
                                to="/admin"
                                onClick={() => setMobileMenu(false)}
                            >
                                <MdAdminPanelSettings />
                            </Link>
                        </li>
                    )}
                    {!user && (
                        <li>
                            <Link
                                to="/login"
                                onClick={() => setMobileMenu(false)}
                            >
                                <button className="splash-bg">Login</button>
                            </Link>
                        </li>
                    )}
                </ul>
            </nav>
            {currentTeam && (
                <Modal
                    className="team-modal small-header"
                    onClose={() => setCurrentTeam(null)}
                >
                    <TeamPage id={currentTeam} />
                </Modal>
            )}
        </header>
    )
}
