import create from "zustand"
import { collection, getDocs } from "firebase/firestore"
import { db } from "../firebase"

const useVenueStore = create(set => ({
    venues: [],
    setVenues: venues => set({ venues }),
    getVenues: async () => {
        const venueCollection = collection(db, "venues")
        const venueSnapshot = await getDocs(venueCollection)
        const venues = venueSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }))
        set({ venues: venues.sort((a, b) => (a.name > b.name ? 1 : -1)) })
    },
}))

export default useVenueStore
