import React, { useState, useEffect } from "react"
import { useParams, useNavigate } from "react-router-dom"
import moment from "moment"

import useTemplateStore from "../../data/templateStore"
import useSeasonStore from "../../data/seasonStore"
import useUserStore from "../../data/userStore"
import useCategoryStore from "../../data/categoryStore"
import useLoadingStore from "../../data/loadingStore"

import "./GameTemplateDetail.css"

export default function GameTemplateDetail() {
    const { templateid } = useParams()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true) // State to track loading status
    const [currentQuestion, setCurrentQuestion] = useState(0)
    const { template, questions } = useTemplateStore(state => ({
        template: state.template,
        questions: state.questions,
    }))

    useEffect(() => {
        const loadTemplate = async () => {
            if (templateid !== undefined) {
                await useTemplateStore.getState().getTemplate(templateid)
                setLoading(false) // Set loading to false after data is fetched
            }
        }
        loadTemplate()
    }, [templateid])

    const saveGameTemplate = e => {
        e.preventDefault()
        useLoadingStore.getState().setLoading(true)
        const form = e.target
        const questions = Array.from(form.elements).filter(element =>
            element.id.includes("question")
        )
        const answers = Array.from(form.elements).filter(element =>
            element.id.includes("answer")
        )

        const templateQuestions = questions.map((question, index) => {
            return {
                question: question.value,
                answer: answers.find(
                    answer =>
                        answer.id === question.id.replace("question", "answer")
                ).value,
                category: question.dataset?.category,
                template: templateid,
                order: question.dataset?.order,
                questionId: question.dataset?.question,
            }
        })

        /*  for each question we need to save it in the database to the questions collection like this:

      {
        question: "What is the capital of France?",
         answer: "Paris",
         category: "1",
      }

      then we need to save it to the gameQuestions collection like this:
      {
        question: "1",
        category: "1",
         template: "1",
         order: 1,
      }

      */

        // first can I find a matching gameQuestion in the  gameQuestions array
        // if not, then I need to create a new gameQuestion
        // if yes, then I need to update the existing gameQuestion

        // then if I find a matching question in the questions array
        // if not, then I need to create
        // if yes, then I need to update

        templateQuestions.forEach(async question => {
            if (
                question.question !== "" &&
                question.answer !== "" &&
                question.category !== "" &&
                question.template !== "" &&
                question.order !== "" &&
                question.questionId !== ""
            ) {
                // if (question.gameQuestion === "0") {
                //     // create a new gameQuestion
                //     //  create a new question
                //     useTemplateStore.getState().createQuestion(question)
                // } else {
                //     // update the existing gameQuestion
                //     // update the existing question
                //     useTemplateStore.getState().updateQuestion(question)
                // }
            }
        })

        setTimeout(() => useLoadingStore.getState().setLoading(false), 1000)
        navigate("/admin/game-templates")
    }

    return (
        <section className="game-template-detail">
            {loading && <div>Loading...</div>}
            {!loading && (
                <>
                    <header>
                        <h1>Game Template Detail</h1>
                        <div className="game-template-details">
                            <span>
                                {moment(template.date).format(
                                    "dddd MM/DD/YYYY"
                                )}
                                <strong>- Date</strong>
                            </span>
                            <span>
                                {
                                    useSeasonStore
                                        .getState()
                                        .seasons.find(
                                            s => s.id === template.season
                                        )?.name
                                }
                                <strong>- Season</strong>
                            </span>
                            <span>
                                {
                                    useUserStore
                                        .getState()
                                        .hosts.find(
                                            h => h.uid === template.user
                                        )?.name
                                }
                                <strong>- Host</strong>
                            </span>
                        </div>
                    </header>
                    <form
                        className="game-template-detail-form"
                        onSubmit={saveGameTemplate}
                    >
                        <section className="categories">
                            {useCategoryStore
                                .getState()
                                .categories.filter(c => c.active)
                                .map(category => (
                                    <section
                                        className="category-block"
                                        key={category.id}
                                    >
                                        <h2 className="category-block-header">
                                            {category.name}
                                        </h2>
                                        <ul className="category-question-tabs">
                                            {Array.from({ length: 8 }).map(
                                                (_, index) => (
                                                    <li
                                                        key={index}
                                                        onClick={() =>
                                                            setCurrentQuestion(
                                                                index
                                                            )
                                                        }
                                                        className={
                                                            currentQuestion ===
                                                            index
                                                                ? "category-question-tab active"
                                                                : "category-question-tab"
                                                        }
                                                    >
                                                        {index + 1}
                                                    </li>
                                                )
                                            )}
                                        </ul>
                                        <section className="questions">
                                            {Array.from({ length: 8 }).map(
                                                (_, index) => (
                                                    <section
                                                        className={`question-block ${
                                                            index ===
                                                            currentQuestion
                                                                ? "show"
                                                                : "hide"
                                                        }`}
                                                        key={index}
                                                    >
                                                        <input
                                                            type="text"
                                                            id={`question-${category.id}-${index}`}
                                                            data-category={
                                                                category.id
                                                            }
                                                            data-order={index}
                                                            data-question={
                                                                index
                                                            }
                                                            placeholder={`Question ${
                                                                index + 1
                                                            }`}
                                                            defaultValue={
                                                                questions.find(
                                                                    q => true
                                                                )?.question || ""
                                                            }
                                                        />

                                                        <input
                                                            type="text"
                                                            id={`answer-${category.id}-${index}`}
                                                            placeholder={`Answer ${
                                                                index + 1
                                                            }`}
                                                            defaultValue={
                                                                questions.find(
                                                                    q => true
                                                                )?.answer || ""
                                                            }
                                                        />
                                                    </section>
                                                )
                                            )}
                                        </section>
                                    </section>
                                ))}
                        </section>
                        <button className="btn">Save</button>
                    </form>
                </>
            )}
        </section>
    )
}
