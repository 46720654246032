import React from "react"

import useOneGameStore from "../../data/oneGameStore"
import useTeamStore from "../../data/teamStore"
import { BsFillBackspaceFill } from "react-icons/bs"

export default function ScoringList({
    currentRound,
    currentRoundCategory,
    setScoring,
    setScoringRound,
    setCurrentRound,
    currentTeam,
    setCurrentTeam,
    numberOfRoundsPerGame,
    questionsPerRound,
}) {
    const gameTeams = useOneGameStore(state => state.gameTeams)
    const gameScores = useOneGameStore(state => state.gameScores)
    const gameRounds = useOneGameStore(state => state.gameRounds)
    const teams = useTeamStore(state => state.teams)

    return (
        <ul className="live-scoring-list scoring-round">
            <li
                className="live-scoring-go-back"
                onClick={() => setCurrentRound(0)}
            >
                <BsFillBackspaceFill /> Go Back
            </li>
            {gameTeams.length === 0 && (
                <h2>
                    Please add some teams using the Teams button in the header
                </h2>
            )}
            {gameTeams.map(gameTeam => {
                const touched = gameTeam.scores.find(
                    score => score.round === currentRound
                )?.touched
                const correctAnswers = gameTeam.scores
                    .filter(score => score.round === currentRound)
                    .reduce((count, score) => {
                        return (
                            count +
                            score.questions.filter(q => q.correct === true)
                                .length
                        )
                    }, 0)

                return (
                    <li
                        className={`scoring-team ${touched ? "" : "missing"}`}
                        key={gameTeam.team}
                        onClick={() => setCurrentTeam(gameTeam.team)}
                    >
                        <p className="team-display">
                            <span className="display-name">
                                {gameTeam.displayName !== ""
                                    ? gameTeam.displayName
                                    : teams.find(
                                          team => team.id === gameTeam.team
                                      )?.name}
                            </span>
                        </p>
                        <span className="team-touched">
                            {touched ? "" : "MISSING SCORE"}
                        </span>
                        {/* Count correct answers */}
                        <span className="team-correct">
                            {correctAnswers}/
                            {questionsPerRound[currentRound - 1]} Correct
                        </span>
                    </li>
                )
            })}
        </ul>
    )
}
