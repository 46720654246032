import React, { useState } from "react"
import {
    collection,
    doc,
    getDocs,
    updateDoc,
    writeBatch,
    query,
    where,
    limit,
    getDoc,
} from "firebase/firestore"
import { db } from "../../firebase"

export default function GameMigrationTool() {
    const [loading, setLoading] = useState(false)
    const [operationMessage, setOperationMessage] = useState("")

    const migrateOldGamesToNewFormat = async () => {
        setLoading(true)
        setOperationMessage("Starting migration...")

        // Fetch one game document without the teams property
        const gamesRef = collection(db, "games")
        // Use the doc method to directly reference the game by ID
        const gameRef = doc(db, "games", "XKPRNTyidnGrtNMOdnzo")
        const gameDoc = await getDoc(gameRef)

        if (gameDoc.exists()) {
        } else {
            setOperationMessage("No game found.")
            setLoading(false)
            return
        }

        const gameId = gameDoc.id

        const gameData = gameDoc.data()

        // Fetch related scores, teams, and rounds based on gameId
        const gameScoresRef = collection(db, "gamescores")
        const gameTeamsRef = collection(db, "gameteams")
        const roundsRef = collection(db, "rounds")

        const gameScoresSnapshot = await getDocs(
            query(gameScoresRef, where("game", "==", gameId))
        )
        const gameTeamsSnapshot = await getDocs(
            query(gameTeamsRef, where("game", "==", gameId))
        )
        const roundsSnapshot = await getDocs(
            query(roundsRef, where("game", "==", gameId))
        )

        const relatedTeams = gameTeamsSnapshot.docs.map(doc => doc.data())
        const relatedScores = gameScoresSnapshot.docs.map(doc => doc.data())
        const relatedRounds = roundsSnapshot.docs.map(doc => doc.data())

        // Combine scores inside respective teams
        const updatedTeams = relatedTeams.map(team => {
            const teamScores = relatedScores.filter(
                score => score.team === team.team
            )
            return {
                ...team,
                scores: teamScores, // Add the scores for this team inside the team object
            }
        })

        // Update the game document with teams (including scores) and rounds
        const updatedGameData = {
            ...gameData,
            teams: updatedTeams, // Add teams array with scores inside each team
            rounds: relatedRounds, // Add rounds array
        }

        // Update the game document with the updated data
        // await updateDoc(doc(db, "games", gameId), updatedGameData)

        setOperationMessage("Migration completed successfully.")
        setLoading(false)
    }

    return (
        <div>
            <h1>Game Migration Tool</h1>
            <button onClick={migrateOldGamesToNewFormat} disabled={loading}>
                {loading ? "Migrating..." : "Migrate Games to New Format"}
            </button>
            <p>{operationMessage}</p>
        </div>
    )
}
