import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import useUserStore from "../../data/userStore"
import dataService from "../../data/dataService"
import "./HostSalesPage.css"
import { GiMoneyStack } from "react-icons/gi"
import { useAuthState } from "react-firebase-hooks/auth"

import PurchaseCredits from "../../components/PurchaseCredits/PurchaseCredits"
import Modal from "../../components/Modal/Modal"

import { collection, getDocs, query, where } from "firebase/firestore"
import { db, auth } from "../../firebase"

import { FaChevronCircleRight, FaChevronCircleLeft } from "react-icons/fa"

import useTransactionStore from "../../data/transactionStore"

// https://dribbble.com/shots/25000009-ChronoTask-Landing-Page - this is our design inspiration for this page

export default function HostSalesPage() {
    const [currentTestimonial, setCurrentTestimonial] = useState(0)
    const [showPurchaseModal, setShowPurchaseModal] = useState(false)
    const [user] = useAuthState(auth)
    const [userData, setUserData] = useState({})
    const [gameCredits, setGameCredits] = useState(0)

    useEffect(() => {
        getUserData()
    }, [user])

    const getUserData = async () => {
        if (user) {
            const q = query(
                collection(db, "users"),
                where("uid", "==", user.uid)
            )
            const querySnapshot = await getDocs(q)
            querySnapshot.forEach(doc => {
                setUserData({
                    ...doc.data(),
                    id: doc.id,
                    gameCredits: doc.data().gameCredits || 0,
                })
                setGameCredits(doc.data().gameCredits)
                useTransactionStore
                    .getState()
                    .getAllTransactionsByUser(user.uid)
            })
        }
    }

    return (
        <div className="host-sales-page black-text">
            <div className="host-sales-page-header">
                <div className="host-sales-page-header-left">
                    <h1>Less Adminis-Trivia, More Trivia!</h1>
                    <p className="price">
                        Host unforgettable trivia nights with Trivia Takeover
                        Live—a streamlined platform that automates scoring,
                        tracks player stats, and delivers engaging, in-person
                        games with ease. Own your success—grab a game credit and
                        start running your own trivia events like a pro.{" "}
                        <strong>No approval process required!</strong>
                    </p>
                    {user ? (
                        <button
                            onClick={() => setShowPurchaseModal(true)}
                            className="become-a-host-now-button"
                        >
                            <GiMoneyStack />
                            <span>Become a Host Now!!!</span>
                        </button>
                    ) : (
                        <Link
                            to="/login?redirectTo=/host"
                            className="become-a-host-now-button"
                        >
                            <GiMoneyStack />
                            <span>Login to become a Host</span>
                        </Link>
                    )}
                </div>
                <div className="host-sales-page-header-right">
                    <img src="/images/newLogo.png" alt="Host Sales Header" />
                </div>
            </div>

            {showPurchaseModal && (
                <Modal
                    title="Become a Host"
                    onClose={() => setShowPurchaseModal(false)}
                >
                    <PurchaseCredits
                        user={useUserStore.getState().currentUser}
                        userData={userData}
                        gameCredits={gameCredits}
                        setGameCredits={setGameCredits}
                        setShowPurchaseModal={setShowPurchaseModal}
                        newHost={true}
                    />
                </Modal>
            )}

            <div className="host-sales-page-testimonials">
                <FaChevronCircleLeft
                    className="testimonial-arrow testimonial-arrow-left"
                    onClick={() =>
                        setCurrentTestimonial(
                            currentTestimonial === 0
                                ? 2
                                : currentTestimonial - 1
                        )
                    }
                />
                <FaChevronCircleRight
                    className="testimonial-arrow testimonial-arrow-right"
                    onClick={() =>
                        setCurrentTestimonial(
                            currentTestimonial === 2
                                ? 0
                                : currentTestimonial + 1
                        )
                    }
                />
                {currentTestimonial === 0 && (
                    <div className="host-sales-page-testimonial testimonial-first">
                        <p className="testimonial-text">
                            "I used to host with my own PowerPoint slides,
                            queestions and answers. It was a lot of work and I
                            was always worried about the scoring. Trivia
                            Takeover Live has made my life so much easier. I can
                            focus on hosting and engaging with my players."
                        </p>
                        <p className="host-sales-page-testimonial-author">
                            <span className="testimonial-author-image">
                                <img
                                    src="/images/headshot1.png"
                                    alt="John Doe"
                                />
                            </span>
                            <span className="testimonial-author-name">
                                - Host Name
                            </span>
                        </p>
                    </div>
                )}
                {currentTestimonial === 1 && (
                    <div className="host-sales-page-testimonial testimonial-second">
                        <p className="testimonial-text">
                            "As a bar owner, I was looking for a way to attract
                            patrons on Tuesdays, as it used to be our slowest
                            night. Trivia Takeover Live has been a game changer.
                            We have a full house every Tuesday now and our sales
                            have increased by 30%."
                        </p>
                        <p className="host-sales-page-testimonial-author">
                            <span className="testimonial-author-image">
                                <img
                                    src="/images/headshot2.png"
                                    alt="John Doe"
                                />
                            </span>
                            <span className="testimonial-author-name">
                                - Bar Owner
                            </span>
                        </p>
                    </div>
                )}
                {currentTestimonial === 2 && (
                    <div className="host-sales-page-testimonial testimonial-second">
                        <p className="testimonial-text">
                            "I love playing trivia, but I was always
                            disappointed by the quality of the games at bars and
                            restaurants. Trivia Takeover Live has been a breath
                            of fresh air. The games are engaging, the host is
                            fun, and I earn badges and medals just for playing.
                            I look forward to playing every week."
                        </p>
                        <p className="host-sales-page-testimonial-author">
                            <span className="testimonial-author-image">
                                <img
                                    src="/images/headshot3.png"
                                    alt="John Doe"
                                />
                            </span>
                            <span className="testimonial-author-name">
                                - Player Name
                            </span>
                        </p>
                    </div>
                )}
            </div>
            <div className="features host-features splash-bg">
                <h2>Host Features</h2>
                <ul className="features-list">
                    <li className="feature-item">
                        <img
                            src="/images/feature1.png"
                            alt="Feature 1"
                            className="feature-image"
                        />
                        <h3 className="feature-title">Game</h3>
                        <p className="feature-description">
                            Don't build your own trivia game from scratch
                            anymore. Daily trivia games are ready to go. 7
                            rounds of General Trivia with 8 questions per round.
                        </p>
                    </li>
                    <li className="feature-item">
                        <img
                            src="/images/feature2.png"
                            alt="Feature 1"
                            className="feature-image"
                        />
                        <h3 className="feature-title">Score</h3>
                        <p className="feature-description">
                            No more spreadsheets or separate apps. Built in
                            scoring system and scoreboard slides. Tie-breaker
                            support as well.
                        </p>
                    </li>
                    <li className="feature-item">
                        <img
                            src="/images/feature3.png"
                            alt="Feature 1"
                            className="feature-image"
                        />
                        <h3 className="feature-title">Schedule</h3>
                        <p className="feature-description">
                            Purchase credits one at a time for $30 or in bulk.
                            Consume a credit and schedule a trivia game. Public
                            games automatically appear on the schedule and start
                            attracting players.
                        </p>
                    </li>
                </ul>
            </div>
            <div className="features host-features player-features">
                <h2>Player Features</h2>
                <ul className="features-list">
                    <li className="feature-item">
                        <img
                            src="/images/feature4.png"
                            alt="Feature 4"
                            className="feature-image"
                        />
                        <h3 className="feature-title">Stats</h3>
                        <p className="feature-description">
                            Each game tracks stats for teams and their
                            individual players. These are then used for Medals,
                            Badges, Accuracy Scores and Player Progression
                            System. These stats reset twice a year at the end of
                            each Season.
                        </p>
                    </li>
                    <li className="feature-item">
                        <img
                            src="/images/feature5.png"
                            alt="Feature 5"
                            className="feature-image"
                        />
                        <h3 className="feature-title">Leaderboards</h3>
                        <p className="feature-description">
                            Teams are able to complete locally, globaly and even
                            down to a specific venue. See how your team stacks
                            up against the competition on more than just Trivia
                            night. These scores reset twice a year at the end of
                            each Season.
                        </p>
                    </li>
                    <li className="feature-item">
                        <img
                            src="/images/feature6.png"
                            alt="Feature 6"
                            className="feature-image"
                        />
                        <h3 className="feature-title">Live Games</h3>
                        <p className="feature-description">
                            While you are expected to have your phone away
                            during questions, you will be able to use your phone
                            during downtime to manage your team and teammates.
                            Follow along during scoring slides to see which
                            questions your team scored correctly.
                        </p>
                    </li>
                </ul>
            </div>
            <div className="features host-features venue-features splash-bg">
                <h2>Venue Features</h2>
                <ul className="features-list">
                    <li className="feature-item">
                        <img
                            src="/images/feature7.png"
                            alt="Feature 7"
                            className="feature-image"
                        />
                        <h3 className="feature-title">Attendance</h3>
                        <p className="feature-description">
                            Our trivia games typically attract an average of 10
                            teams, each with an average of 4 players. Your venue
                            will be listed right on the homepage leading up to
                            the night of, guaranting to draw a crowd.
                        </p>
                    </li>
                    <li className="feature-item">
                        <img
                            src="/images/feature8.png"
                            alt="Feature 8"
                            className="feature-image"
                        />
                        <h3 className="feature-title">Marketing</h3>
                        <p className="feature-description">
                            Venue Pages showcase contact information, brand
                            awareness and leaderboards. Daily emails are sent
                            reminding players and hosts of the days games.
                            Social Media marketing is in place to advertise
                            upcoming games. Just sit back and watch the magic.
                        </p>
                    </li>
                    <li className="feature-item">
                        <img
                            src="/images/feature9.png"
                            alt="Feature 9"
                            className="feature-image"
                        />
                        <h3 className="feature-title">Retention</h3>
                        <p className="feature-description">
                            Once players attend their first game at your venue,
                            we know they have a 80% chance of attending that
                            same game on a weekly basis. Turn your slow nights
                            into your best nights
                        </p>
                    </li>
                </ul>
            </div>
            <div className="host-sales-page-testimonials call-to-action">
                <p className="call-to-action-text">
                    There's no time like the present to grab a credit, become a
                    host and schedule your first Trivia Game.
                </p>
                {user ? (
                    <button
                        onClick={() => setShowPurchaseModal(true)}
                        className="become-a-host-now-button"
                    >
                        <GiMoneyStack />
                        <span>Become a Host Now!!!</span>
                    </button>
                ) : (
                    <Link
                        to="/login?redirectTo=/host"
                        className="become-a-host-now-button"
                    >
                        <GiMoneyStack />
                        <span>Login to become a Host</span>
                    </Link>
                )}
            </div>
        </div>
    )
}
