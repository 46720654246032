import { Link } from "react-router-dom"

import "./Admin.css"

export default function Admin() {
    return (
        <section className="admin-page">
            <header>
                <h1>Admin Interface</h1>
            </header>
            <section>
                <h2>Points</h2>
                {/* <Link to="/admin/scores">Scores</Link> */}
                <Link to="/admin/games">Games</Link>
                <Link to="/admin/seasons">Seasons</Link>
            </section>
            <section className="content-admin">
                <h2>Content</h2>
                <Link to="/admin/categories">Categories</Link>
                <Link to="/admin/questions">Questions</Link>
                <Link to="/admin/game-templates">Game Templates</Link>
            </section>
            <section className="administrative-admin">
                <h2>Administrative</h2>
                <Link to="/admin/users">Users</Link>
                <Link to="/admin/teams">Teams</Link>
                <Link to="/admin/venues">Venues</Link>
                <Link to="/admin/pages">Pages</Link>
                <Link to="/admin/tools">Tools</Link>
                <Link to="/admin/game-migration">Game Migration</Link>
            </section>
        </section>
    )
}
