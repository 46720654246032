import React from "react"
import QRCode from "qrcode.react"

import useUserStore from "../../../data/userStore"

export default function End() {
    const localGame = JSON.parse(localStorage.getItem("smt-game"))
    const hosts = useUserStore(state => state.hosts)
    const host = hosts.find(h => h.uid === localGame.user)

    return (
        <div className="slide end-slide">
            <main className="split">
                <section className="split-left">
                    <p className="trivia-title">Thank You for Playing</p>
                    <p className="end-title">Visit the host to track stats</p>
                </section>
                <section className="split-right">
                    {host?.venmoHandle && (
                        <>
                            <QRCode
                                value={`https://venmo.com/${host.venmoHandle.replace("@","")}`}
                                size={256}
                                className="end-venmo-qr"
                            />

                            <p className="venmo-handle">
                                Venmo: @{host.venmoHandle.replace("@","")}
                            </p>

                            <p className="leave-host-tip">
                                Leave your host a tip
                            </p>
                        </>
                    )}
                </section>
            </main>
            <footer className="slidefooter">
                <p className="footer-logo">
                    <img src="/images/logo white no text.png" alt="logo" />
                </p>
                <p className="footer-facebook">
                    <a href="https://www.facebook.com/smtrivia">
                        Southern Maryland Trivia
                    </a>
                </p>
                <p className="footer-instagram">
                    <a href="https://www.instagram.com/southernmarylandtrivia">
                        @SouthernMarylandTrivia
                    </a>
                </p>
            </footer>
        </div>
    )
}
