import React, { useState } from "react"
import moment from "moment"
import useVenueStore from "../../data/venueStore"
import useSeasonStore from "../../data/seasonStore"
import useUserStore from "../../data/userStore"
import useTeamStore from "../../data/teamStore"
import { formatDate, getNextOccurrence } from "../../utils/dateUtils"
import { Link } from "react-router-dom"
import { FaLocationDot } from "react-icons/fa6"

const GameHeader = ({
    game,
    gameVenue,
    setAddTeam,
    user,
    presentation,
    setAddTeamMate,
    setJoinTeamMate,
    templateid,
    teamid,
    isUserAtVenue,
    distance,
}) => {
    const [updating, setUpdating] = useState(false)
    const venue =
        game && game.venue
            ? useVenueStore.getState().venues.find(v => v.id === game.venue)
            : gameVenue
            ? gameVenue
            : {}
    const host = game
        ? useUserStore.getState().hosts.find(u => u.uid === game.user)?.name ||
          game.user
        : ""
    const season = game
        ? useSeasonStore.getState().seasons.find(s => s.id === game.season)
              ?.name
        : ""

    const gameUrl = `${window.location.origin}${window.location.pathname}`
    const isMember =
        user && game?.teams?.some(t => t.members?.includes(user.uid))

    const memberOf = isMember
        ? game?.teams?.find(t => t.members?.includes(user.uid))
        : false

    const matchingTeam = game?.teams?.find(t => t.team === teamid)
    const matchingTeamObj = useTeamStore
        .getState()
        .teams.find(t => t.id === teamid)

    return (
        <header className="game-page-header">
            <div className="game-page-header-left">
                <Link
                    to={venue ? `/venue/${venue?.id}` : "/"}
                    className="link-to-venue"
                >
                    <h2 className="game-venue">
                        {venue ? (
                            <>
                                <span className="game-venue-label">
                                    {venue.name}
                                </span>
                            </>
                        ) : (
                            "Venue TBD"
                        )}
                    </h2>
                </Link>
                <p className="game-address">
                    {venue ? `${venue.city}, ${venue.state}` : ""}
                </p>
                {host && <h3 className="game-host">Hosted By: {host}</h3>}
            </div>
            <div className="game-page-header-center">
                {!isMember &&
                user &&
                presentation === "liveuser" &&
                game?.template === templateid &&
                isUserAtVenue ? (
                    <button
                        className="add-team"
                        onClick={() => setAddTeam(true)}
                    >
                        Add Team to Game
                    </button>
                ) : presentation === "liveuser" &&
                  !user &&
                  game?.template === templateid ? (
                    <Link
                        to={`/login?redirectTo=${window.location.pathname}${window.location.search}`}
                        className="add-team"
                    >
                        Log in to add your team to the game
                    </Link>
                ) : presentation === "liveteammate" &&
                  matchingTeam &&
                  user &&
                  isUserAtVenue &&
                  !isMember ? (
                    <h2 className="game-team-name">
                        <button
                            className="add-team"
                            onClick={() => setJoinTeamMate(matchingTeam.team)}
                        >
                            Join Team :{" "}
                            {matchingTeam.displayName || matchingTeamObj?.name}
                        </button>
                    </h2>
                ) : presentation === "liveteammate" && !user ? (
                    <Link
                        to={`/login?redirectTo=${window.location.pathname}${window.location.search}`}
                        className="add-team"
                    >
                        Log in to join{" "}
                        {matchingTeam.displayName || matchingTeamObj?.name}{" "}
                    </Link>
                ) : presentation === "liveteammate" && isMember ? (
                    <span className="game-team-name">
                        Playing w/{" "}
                        {matchingTeam.displayName || matchingTeamObj?.name}
                    </span>
                ) : presentation === "liveuser" &&
                  isMember &&
                  isUserAtVenue &&
                  game.template === templateid ? (
                    <button
                        className="add-other-team-members"
                        onClick={() => setAddTeamMate(memberOf.team)}
                    >
                        {/* team name */}
                        Add {memberOf?.displayName ||
                            matchingTeamObj?.name}{" "}
                        Members
                    </button>
                ) : (
                    ""
                )}

                {!isUserAtVenue && (
                    <>
                        <p className="game-distance">
                            You are {Number(distance).toFixed(2)} mi away from
                            the venue for this game and cannot join.
                        </p>
                        <button
                            className="update-location"
                            onClick={() => {
                                setUpdating(true)
                                useUserStore
                                    .getState()
                                    .getUserLocation(setUpdating, true)
                            }}
                        >
                            {updating ? (
                                "Updating....."
                            ) : (
                                <>
                                    <FaLocationDot /> Update Location
                                </>
                            )}
                        </button>
                    </>
                )}

                {/* <QRCode value={gameUrl} size={64} />
                <h3 className="qr-code-tagline">Live Scoreboard</h3> */}
            </div>
            <div className="game-page-header-right">
                <h2 className="game-date">
                    {venue
                        ? `${formatDate(
                              game?.date
                                  ? game?.date
                                  : getNextOccurrence(venue.day)
                          )} - ${moment(venue.time, "HH:mm").format("h:mm a")}`
                        : "TBD"}
                </h2>
                <h3 className="game-season">{season}</h3>
            </div>
        </header>
    )
}

export default GameHeader
