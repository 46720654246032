import React from "react"

export default function Start() {
    return (
        <div className="slide start-slide">
            <section className="start-slide-wrapper">
                <p className="trivia-title">Trivia</p>
                <p className="starts-now-title">Starts Now</p>
            </section>
        </div>
    )
}
