import create from "zustand"
import {
    collection,
    getDocs,
    query,
    where,
    orderBy,
    doc,
    updateDoc,
    or,
} from "firebase/firestore"

import { db } from "../firebase"

const useUserStore = create((set, get) => ({
    users: [],
    hosts: [],
    currentUser: null,
    userLocation: null,
    discount: 0,
    setDiscount: discount => set({ discount }),
    setUsers: users => set({ users }),
    setHosts: hosts => set({ hosts }),
    getUsers: async () => {
        const q = query(collection(db, "users"), orderBy("name"))
        const querySnapshot = await getDocs(q)
        const users = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }))
        set({ users })
    },
    getHosts: async () => {
        const q = query(
            collection(db, "users")
            // or(where("role", "==", "admin"), where("role", "==", "host"))
        )
        const querySnapshot = await getDocs(q)
        const hosts = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }))
        set({
            hosts: hosts
                .filter(user => user.role === "host" || user.role === "admin")
                .map(user => {
                    return {
                        ...user,
                    }
                })
                .sort((a, b) => a.name.localeCompare(b.name)),
        })
    },
    setUserLocation: location => {
        set({ userLocation: location })
    },
    getUserLocation: (updating = () => {}, force = false) => {
        const somd = {
            lat: 38.292465,
            lng: -76.636951,
        }
        const defaultLocation = somd
        if (!get().userLocation || force) {
            navigator.geolocation.getCurrentPosition(
                position => {
                    set({
                        userLocation: {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                        },
                    })
                    updating()
                },
                error => {
                    set({ userLocation: defaultLocation })
                    console.error("Error getting location", error)
                    setTimeout(
                        () => {
                            updating()
                        },
                        force ? 1000 : 100
                    )
                }
            )
        }
        setTimeout(() => {
            updating()
        }, 1000)
    },
    setCurrentUser: async user => {
        set({ currentUser: user })
    },
    searchUsers: async searchTerm => {
        if (searchTerm.length < 3) return []
        const q = query(collection(db, "users"))
        const querySnapshot = await getDocs(q)
        const users = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }))
        return users.filter(
            user =>
                user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                user.email.toLowerCase().includes(searchTerm.toLowerCase())
        )
    },
    getUserData: async user => {
        const id = user?.uid ? user.uid : user
        const q = query(collection(db, "users"), where("uid", "==", id))
        const querySnapshot = await getDocs(q)
        querySnapshot.forEach(doc => {
            set({ currentUser: { ...doc.data(), id: doc.id } })
        })
    },
    updateUserField: async (id, field, value) => {
        const userRef = doc(db, "users", id)
        await updateDoc(userRef, {
            [field]: value,
        })
    },
    consumeGameCredit: () => {
        const newCredits = useUserStore.getState().currentUser.gameCredits
            ? useUserStore.getState().currentUser.gameCredits - 1
            : 0
        useUserStore.getState().setCurrentUser({
            ...useUserStore.getState().currentUser,
            gameCredits: newCredits,
        })
        useUserStore
            .getState()
            .updateUserField(
                useUserStore.getState().currentUser.id,
                "gameCredits",
                newCredits
            )
    },
}))

export default useUserStore
