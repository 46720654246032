import React, { useState, useEffect } from "react"

import useSeasonStore from "../../data/seasonStore"
import useCategoryStore from "../../data/categoryStore"
import useUserStore from "../../data/userStore"

import sampleQuestions from "../../data/questions"

import "./GameTemplateForm.css"
import Modal from "../Modal/Modal"

export default function GameTemplateForm({
    onSubmit,
    user,
    selectedDate,
    action,
    data,
}) {
    const isEdit = action === "edit"
    const [date, setDate] = useState("")
    const [season, setSeason] = useState(
        isEdit ? data.season : useSeasonStore.getState().activeSeason?.id
    )
    const [selectedCategory, setSelectedCategory] = useState(
        useCategoryStore
            .getState()
            .categories.find(category => category.name === "History")?.id ||
            useCategoryStore.getState().categories[0].id
    )
    const [selectedQuestion, setSelectedQuestion] = useState(1)
    const [question, setQuestion] = useState("")
    const [answer, setAnswer] = useState("")
    const [showImport, setShowImport] = useState(false)

    const [inputText, setInputText] = useState("")
    const [quizData, setQuizData] = useState([])

    const [tiebreakerQuestion, setTiebreakerQuestion] = useState(
        data?.tiebreakerQuestion || ""
    )
    const [tiebreakerAnswer, setTiebreakerAnswer] = useState(
        data?.tiebreakerAnswer || ""
    )

    const handleImport = (useSample = false) => {
        const lines = useSample ? sampleQuestions : inputText.split("\n")
        let subCat = ""
        const parsedData = lines
            .map(line => {
                const parts = useSample
                    ? [
                          line.category,
                          line.subcategory || "",
                          line.question,
                          line.answer,
                      ].filter(part => part.trim() !== "")
                    : line.split("\t").filter(part => part.trim() !== "")
                if (parts.length >= 3) {
                    if (parts[0] === "Mystery") {
                        subCat = parts[1]
                    }
                    return {
                        category: parts[0],
                        subcategory: parts[0] === "Mystery" ? parts[1] : "",
                        question: parts[0] === "Mystery" ? parts[2] : parts[1],
                        answer: parts[0] === "Mystery" ? parts[3] : parts[2],
                    }
                }
                return null
            })
            .filter(item => item !== null)

        setQuizData(parsedData)
        setShowImport(false)
        const updatedQuestions = {}
        useCategoryStore.getState().categories.forEach(category => {
            updatedQuestions[category.id] = []
        })

        parsedData.forEach(item => {
            const category = useCategoryStore
                .getState()
                .categories.find(category => category.name === item.category)
            if (category) {
                updatedQuestions[category.id].push({
                    question: item.question,
                    answer: item.answer,
                })
            }
        })

        const updatedGameTemplate = {
            ...data,
            tiebreakerQuestion,
            tiebreakerAnswer,
            subCat,
            questions: {
                ...data.questions,
                ...updatedQuestions,
            },
        }

        onSubmit({
            data: updatedGameTemplate,
            keepEditing: true,
        })
    }

    useEffect(() => {
        if (isEdit && selectedCategory !== "" && data.questions) {
            const question =
                data.questions[selectedCategory][selectedQuestion - 1]?.question
            const answer =
                data.questions[selectedCategory][selectedQuestion - 1]?.answer

            setQuestion(question)
            setAnswer(answer)
            setDate(data.date)
        } else {
            setQuestion("")
            setAnswer("")
            setDate(selectedDate)
        }
    }, [selectedQuestion, selectedCategory])

    const handleSubmit = e => {
        e.preventDefault()
        onSubmit({
            data: {
                date,
                season,
                host: user.uid,
                tiebreakerQuestion,
                tiebreakerAnswer,
            },
            keepEditing: false,
        })
    }

    const categories = useCategoryStore.getState().categories.map(category => {
        return {
            id: category.id,
            name: category.name,
            questions: category.questions,
        }
    })

    const updateQuestionAnswer = () => {
        let updatedGameTemplate = {
            ...data,
        }

        /*
        This is how the questions are structured in the database
        const questions = {}
        useCategoryStore.getState().categories.forEach(category => {
            questions[category.id] = []
        })

        that array is a list of objects, where each object has a question and answer property

        */

        if (isEdit) {
            updatedGameTemplate = {
                ...data,
                questions: {
                    ...data.questions,
                    [selectedCategory]: data.questions[selectedCategory].map(
                        (questionObj, index) => {
                            if (index === selectedQuestion - 1) {
                                return {
                                    question,
                                    answer,
                                }
                            }
                            return questionObj
                        }
                    ),
                },
            }

            onSubmit({
                data: updatedGameTemplate,
                keepEditing: true,
            })
        }
    }

    return (
        <form className="modal-form" onSubmit={handleSubmit}>
            <div className="modal-form-field">
                <label htmlFor="date" className="modal-form__label">
                    Date
                </label>
                <input
                    type="date"
                    id="date"
                    name="date"
                    className="modal-form__input"
                    value={date}
                    onChange={e => setDate(e.target.value)}
                />
            </div>
            <div className="modal-form-field">
                <label htmlFor="season" className="modal-form__label">
                    Season
                </label>
                <select
                    name="season"
                    id="season"
                    value={season}
                    onChange={e => setSeason(e.target.value)}
                    className="modal-form__select"
                >
                    <option value="">Select Season</option>
                    {useSeasonStore.getState().seasons.map(season => (
                        <option key={season.id} value={season.id}>
                            {season.name}
                        </option>
                    ))}
                </select>
            </div>
            <div className="modal-form-field">
                <label htmlFor="user" className="modal-form__label">
                    User
                </label>
                <input
                    type="text"
                    id="user"
                    name="user"
                    value={
                        isEdit
                            ? useUserStore
                                  .getState()
                                  .hosts.find(host => host.uid === data.user)
                                  ?.name
                            : useUserStore
                                  .getState()
                                  .hosts.find(host => host.uid === user.uid)
                                  ?.name
                    }
                    readOnly={true}
                    className="modal-form__input"
                />
            </div>
            {isEdit && (
                <div className="game-template-edit-section">
                    <hr />

                    <h2 className="modal-form__section-subtitle">
                        Questions
                        <span
                            className="modal-form__section-subtitle--import"
                            onClick={() => setShowImport(true)}
                        >
                            Import
                        </span>
                    </h2>

                    <p className="modal-form__tiebreaker_question">
                        <span className="modal-form__tiebreaker_question--label">
                            Tiebreaker:
                        </span>
                        <span className="modal-form__tiebreaker_question--value">
                            <input
                                type="text"
                                value={tiebreakerQuestion}
                                onChange={e => {
                                    setTiebreakerQuestion(e.target.value)
                                }}
                            />
                        </span>
                    </p>
                    <p className="modal-form__tiebreaker_answer">
                        <span className="modal-form__tiebreaker_question--label">
                            Answer:
                        </span>
                        <span className="modal-form__tiebreaker_question--value">
                            <input
                                type="number"
                                value={tiebreakerAnswer}
                                onChange={e => {
                                    setTiebreakerAnswer(e.target.value)
                                }}
                            />
                        </span>
                    </p>

                    <select
                        value={selectedCategory}
                        onChange={e => {
                            setSelectedCategory(e.target.value)
                            setSelectedQuestion(1)
                        }}
                        className="game-template-form-category-select"
                    >
                        <option value="">Select Category</option>
                        {categories
                            .filter(c => c.name !== "Pictures")
                            .map(category => (
                                <option key={category.id} value={category.id}>
                                    {category.name}
                                </option>
                            ))}
                    </select>

                    {selectedCategory && data.questions && (
                        <ul className="game-template-question-number-list">
                            {Array.from({ length: 8 }, (_, i) => i + 1).map(
                                question => (
                                    <li
                                        key={question}
                                        className={`game-template-question-number ${
                                            data.questions[selectedCategory][
                                                question - 1
                                            ]?.question === "" ||
                                            data.questions[selectedCategory][
                                                question - 1
                                            ]?.answer === ""
                                                ? "game-template-question-number--empty"
                                                : "game-template-question-number--filled"
                                        } ${
                                            question === selectedQuestion
                                                ? "game-template-question-number--selected"
                                                : ""
                                        }`}
                                        onClick={() =>
                                            setSelectedQuestion(question)
                                        }
                                    >
                                        {question}
                                    </li>
                                )
                            )}
                        </ul>
                    )}

                    {selectedCategory && selectedQuestion && (
                        <div
                            className={`game-template-question ${useCategoryStore
                                .getState()
                                .categories.find(
                                    category => category.id === selectedCategory
                                )
                                .name.replace(" ", "-")
                                .toLowerCase()}-background`}
                        >
                            <label
                                htmlFor="question"
                                className="game-template-question-label"
                            >
                                Question {selectedQuestion}
                            </label>
                            <textarea
                                name="question"
                                id="question"
                                className="modal-form__textarea"
                                defaultValue={question}
                                onChange={e => setQuestion(e.target.value)}
                                onBlur={updateQuestionAnswer}
                            ></textarea>

                            <label
                                htmlFor="answer"
                                className="game-template-question-label"
                            >
                                Answer {selectedQuestion}
                            </label>
                            <textarea
                                name="answer"
                                id="answer"
                                className="modal-form__textarea"
                                defaultValue={answer}
                                onChange={e => setAnswer(e.target.value)}
                                onBlur={updateQuestionAnswer}
                            ></textarea>
                        </div>
                    )}
                </div>
            )}

            {showImport && (
                <Modal
                    title="Import Questions"
                    className="modal-form__import-modal"
                    onClose={() => setShowImport(false)}
                >
                    <p>Import questions by copy pasting</p>
                    <p>
                        <strong>
                            Warning: This will overwrite any questions/answers
                            currently in the template
                        </strong>
                    </p>
                    <textarea
                        className="modal-form__textarea"
                        placeholder="Paste questions / answers here"
                        value={inputText}
                        onChange={e => setInputText(e.target.value)}
                    ></textarea>
                    <button
                        className="modal-form__save-btn"
                        type="submit"
                        onClick={() => handleImport(false)}
                    >
                        Finish Import
                    </button>
                    <button
                        className="modal-form__save-btn"
                        type="button"
                        onClick={() => handleImport(true)}
                    >
                        Load Sample Questions
                    </button>
                </Modal>
            )}

            <button className="modal-form__save-btn" type="submit">
                {action === "add"
                    ? "Create Game Template"
                    : "Update Game Template"}
            </button>
        </form>
    )
}
