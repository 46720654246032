import create from "zustand"
import {
    collection,
    getDocs,
    query,
    where,
    updateDoc,
    arrayUnion,
    doc,
    addDoc,
} from "firebase/firestore"
import { db } from "../firebase"

const useTeamStore = create((set, get) => ({
    teams: [],
    setTeams: teams => set({ teams }),
    getTeams: async () => {
        const teamCollection = collection(db, "teams")
        const teamSnapshot = await getDocs(teamCollection)
        const teams = teamSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }))
        set({ teams })
    },
    handleJoinTeam: async (teamID, userID) => {
        const q = query(collection(db, "users"), where("uid", "==", userID))
        const querySnapshot = await getDocs(q)
        let userRef
        querySnapshot.forEach(doc => {
            userRef = doc.ref
        })
        const userData = querySnapshot.docs[0].data()
        if (userData?.teams?.includes(teamID)) return

        await updateDoc(userRef, {
            teams: arrayUnion(teamID),
        })

        const teamRef = doc(db, "teams", teamID)
        await updateDoc(teamRef, {
            users: arrayUnion(userID),
        })
        useTeamStore.getState().getTeams()
    },
    handleLeaveTeam: async (teamID, userID) => {
        const team = useTeamStore
            .getState()
            .teams.find(team => team.id === teamID)

        const q = query(collection(db, "users"), where("uid", "==", userID))
        const querySnapshot = await getDocs(q)
        let userRef
        querySnapshot.forEach(doc => {
            userRef = doc.ref
        })
        const userData = querySnapshot.docs[0].data()
        await updateDoc(userRef, {
            teams: userData.teams.filter(team => team !== teamID),
        })

        const teamRef = doc(db, "teams", teamID)
        await updateDoc(teamRef, {
            users: team.users.filter(user => user !== userID),
        })
        useTeamStore.getState().getTeams()
    },
    createNewTeam: async teamName => {
        if (!teamName) return
        if (useTeamStore.getState().teams.some(team => team.name === teamName))
            return
        const teamRef = await addDoc(collection(db, "teams"), {
            name: teamName,
            users: [],
        })
        useTeamStore.getState().getTeams()
        return teamRef.id
    },
    addTeam: team => {
        set({ teams: [...get().teams, team] })
    },
}))

export default useTeamStore
