import React from "react"
import QRCode from "qrcode.react"

export default function Welcome() {
    const localGame = JSON.parse(localStorage.getItem("smt-game"))
    return (
        <div className="slide welcome-slide">
            <main className="split">
                <section className="split-left">
                    <img
                        src="/images/slideshowLogo.gif"
                        alt="Trivia"
                        className="welcome-trivia-img"
                    />
                </section>
                <section className="split-right">
                    <p className="join-the-game">Join the Game Now</p>
                    <QRCode
                        className="game-qr-for-slides"
                        value={`${window.location.origin}/live-game/${localGame.id}/${localGame.template}`}
                        size={480}
                    />
                </section>
            </main>
        </div>
    )
}
