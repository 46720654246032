import { useEffect, useState } from "react"
import { doc, updateDoc, deleteDoc } from "firebase/firestore"
import { db } from "../../firebase"
import Modal from "../../components/Modal/Modal"
import useVenueStore from "../../data/venueStore"
import useUserStore from "../../data/userStore"

export default function VenuesAdmin() {
    const venues = useVenueStore(state => state.venues)
    const setVenues = useVenueStore(state => state.setVenues)
    const [currentVenue, setCurrentVenue] = useState(null)
    const [updating, setUpdating] = useState(false)
    const userLocation = useUserStore(state => state.userLocation)
    const [saveMessage, setSaveMessage] = useState("")
    const [sortedVenues, setSortedVenues] = useState([])

    const calculateDistance = (lat1, lng1, lat2, lng2) => {
        const toRad = value => (value * Math.PI) / 180
        const R = 6371 // Radius of the Earth in km
        const dLat = toRad(lat2 - lat1)
        const dLng = toRad(lng2 - lng1)
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(toRad(lat1)) *
                Math.cos(toRad(lat2)) *
                Math.sin(dLng / 2) *
                Math.sin(dLng / 2)
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
        const distance = R * c // Distance in km
        return distance * 0.621371 // Convert to miles
    }

    useEffect(() => {
        if (userLocation) {
            const sorted = venues.sort(
                (a, b) =>
                    calculateDistance(
                        userLocation.lat,
                        userLocation.lng,
                        a.lat,
                        a.lng
                    ) -
                    calculateDistance(
                        userLocation.lat,
                        userLocation.lng,
                        b.lat,
                        b.lng
                    )
            )
            setSortedVenues(sorted)
        } else {
            setSortedVenues(venues)
        }
    }, [userLocation, venues])

    const saveField = async (id, field, value) => {
        setUpdating(true)
        const venueRef = doc(db, "venues", id)
        await updateDoc(venueRef, {
            [field]: value,
        })
        setUpdating(false)
        setSaveMessage("Changes saved successfully!")
        setTimeout(() => setSaveMessage(""), 3000)
        setVenues(
            venues.map(venue => {
                if (venue.id === id) {
                    return {
                        ...venue,
                        [field]: value,
                    }
                }
                return venue
            })
        )
    }

    const deleteVenue = async id => {
        if (window.confirm("Are you sure you want to delete this venue?")) {
            setUpdating(true)
            await deleteDoc(doc(db, "venues", id))
            setUpdating(false)
            setVenues(venues.filter(venue => venue.id !== id))
            setCurrentVenue(null)
        }
    }

    return (
        <section className="venues-admin-page">
            <h1>Venues</h1>
            {saveMessage && <div className="save-banner">{saveMessage}</div>}
            <ul className="venues-list">
                {sortedVenues.map(venue => (
                    <li
                        key={venue.id}
                        className="venue-item"
                        onClick={() => setCurrentVenue(venue)}
                    >
                        <span className="venue-name black-text">
                            {venue.name}
                        </span>
                        <span className="venue-location">
                            {venue.city}, {venue.state}
                        </span>
                        {userLocation && (
                            <span className="venue-distance">
                                {calculateDistance(
                                    userLocation.lat,
                                    userLocation.lng,
                                    venue.lat,
                                    venue.lng
                                ).toFixed(2)}{" "}
                                miles
                            </span>
                        )}
                    </li>
                ))}
            </ul>

            {currentVenue && (
                <Modal
                    title="Edit Venue"
                    onClose={() => setCurrentVenue(null)}
                    className="venue-modal black-text"
                >
                    <form>
                        <label htmlFor="name">Name</label>
                        <input
                            type="text"
                            id="name"
                            value={currentVenue.name}
                            onChange={e =>
                                setCurrentVenue({
                                    ...currentVenue,
                                    name: e.target.value,
                                })
                            }
                            onBlur={e =>
                                saveField(
                                    currentVenue.id,
                                    "name",
                                    e.target.value
                                )
                            }
                        />
                        <label htmlFor="url">URL</label>
                        <input
                            type="text"
                            id="url"
                            value={currentVenue.url}
                            onChange={e =>
                                setCurrentVenue({
                                    ...currentVenue,
                                    url: e.target.value,
                                })
                            }
                            onBlur={e =>
                                saveField(
                                    currentVenue.id,
                                    "url",
                                    e.target.value
                                )
                            }
                        />
                        <label htmlFor="day">Day</label>
                        <select
                            id="day"
                            value={currentVenue.day}
                            onChange={e =>
                                setCurrentVenue({
                                    ...currentVenue,
                                    day: e.target.value,
                                })
                            }
                            onBlur={e =>
                                saveField(
                                    currentVenue.id,
                                    "day",
                                    e.target.value
                                )
                            }
                        >
                            <option value="">Select a Day</option>
                            <option value="Tuesday">Tuesday</option>
                            <option value="Wednesday">Wednesday</option>
                            <option value="Thursday">Thursday</option>
                        </select>
                        <label htmlFor="time">Start Time</label>
                        <input
                            type="text"
                            id="time"
                            value={currentVenue.time}
                            onChange={e =>
                                setCurrentVenue({
                                    ...currentVenue,
                                    time: e.target.value,
                                })
                            }
                            onBlur={e =>
                                saveField(
                                    currentVenue.id,
                                    "time",
                                    e.target.value
                                )
                            }
                        />
                        <label htmlFor="address">Address</label>
                        <input
                            type="text"
                            id="address"
                            value={currentVenue.address}
                            onChange={e =>
                                setCurrentVenue({
                                    ...currentVenue,
                                    address: e.target.value,
                                })
                            }
                            onBlur={e =>
                                saveField(
                                    currentVenue.id,
                                    "address",
                                    e.target.value
                                )
                            }
                        />
                        <label htmlFor="city">City</label>
                        <input
                            type="text"
                            id="city"
                            value={currentVenue.city}
                            onChange={e =>
                                setCurrentVenue({
                                    ...currentVenue,
                                    city: e.target.value,
                                })
                            }
                            onBlur={e =>
                                saveField(
                                    currentVenue.id,
                                    "city",
                                    e.target.value
                                )
                            }
                        />
                        <label htmlFor="state">State</label>
                        <input
                            type="text"
                            id="state"
                            value={currentVenue.state}
                            onChange={e =>
                                setCurrentVenue({
                                    ...currentVenue,
                                    state: e.target.value,
                                })
                            }
                            onBlur={e =>
                                saveField(
                                    currentVenue.id,
                                    "state",
                                    e.target.value
                                )
                            }
                        />
                        <label htmlFor="zip">Zip</label>
                        <input
                            type="text"
                            id="zip"
                            value={currentVenue.zip}
                            onChange={e =>
                                setCurrentVenue({
                                    ...currentVenue,
                                    zip: e.target.value,
                                })
                            }
                            onBlur={e =>
                                saveField(
                                    currentVenue.id,
                                    "zip",
                                    e.target.value
                                )
                            }
                        />
                        <label htmlFor="lat">Lat</label>
                        <input
                            type="text"
                            id="lat"
                            value={currentVenue.lat}
                            onChange={e =>
                                setCurrentVenue({
                                    ...currentVenue,
                                    lat: e.target.value,
                                })
                            }
                            onBlur={e =>
                                saveField(
                                    currentVenue.id,
                                    "lat",
                                    e.target.value
                                )
                            }
                        />
                        <label htmlFor="lng">Lng</label>
                        <input
                            type="text"
                            id="lng"
                            value={currentVenue.lng}
                            onChange={e =>
                                setCurrentVenue({
                                    ...currentVenue,
                                    lng: e.target.value,
                                })
                            }
                            onBlur={e =>
                                saveField(
                                    currentVenue.id,
                                    "lng",
                                    e.target.value
                                )
                            }
                        />
                        <button
                            type="button"
                            className="delete-button"
                            onClick={() => deleteVenue(currentVenue.id)}
                        >
                            Delete Venue
                        </button>
                    </form>
                </Modal>
            )}
        </section>
    )
}
