import React, { useState } from "react"
import moment from "moment"

import CalendarHeader from "./CalendarHeader"

import "./Calendar.css"
import CalendarBody from "./CalendarBody"

export default function Calendar({
    buttonData,
    buttonItem,
    data,
    setData,
    buttonClickAdd,
    buttonClickEdit,
    dateChanged,
    view = "weekly",
    gameCredits = 0,
    handleEditGame = () => {},
}) {
    const [date, setDate] = useState(
        moment().startOf(
            view === "weekly" || view === "host-dashboard" ? "week" : "month"
        )
    )
    const [days, setDays] = useState([])

    //  when date is changed, I want to change days
    React.useEffect(() => {
        const days = []
        if (view === "weekly") {
            for (let i = 0; i < 7; i++) {
                days.push({
                    day: moment(date)
                        .startOf("week")
                        .add(i, "days")
                        .format("dddd"),
                    date: moment(date)
                        .startOf("week")
                        .add(i, "days")
                        .format("Do"),
                    lll: moment(date)
                        .startOf("week")
                        .add(i, "days")
                        .format("lll"),
                    ll: moment(date)
                        .startOf("week")
                        .add(i, "days")
                        .format("ll"),
                })
            }
        } else if (view === "host-dashboard") {
            for (let i = 0; i < 3; i++) {
                days.push({
                    day: moment(date)
                        .startOf("week")
                        .add(i + 2, "days")
                        .format("dddd"),
                    date: moment(date)
                        .startOf("week")
                        .add(i + 2, "days")
                        .format("Do"),
                    lll: moment(date)
                        .startOf("week")
                        .add(i + 2, "days")
                        .format("lll"),
                    ll: moment(date)
                        .startOf("week")
                        .add(i + 2, "days")
                        .format("ll"),
                })
            }
        } else if (view === "monthly") {
            const startOfMonth = moment(date).startOf("month")
            const endOfMonth = moment(date).endOf("month")
            let day = startOfMonth

            while (day <= endOfMonth) {
                days.push({
                    day: day.format("dddd"),
                    date: day.format("Do"),
                    lll: day.format("lll"),
                    ll: day.format("ll"),
                })
                day = day.clone().add(1, "day")
            }
        }
        setDays(days)
        dateChanged(date)
    }, [date, view])

    return (
        <div className={`calendar calendar--${view}`}>
            <CalendarHeader date={date} setDate={setDate} view={view} />
            {view === "monthly" && (
                <ul className="calendar_days_of_the_week_header">
                    {["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"].map(day => (
                        <li key={day} className="calendar__week-top-item">
                            <span className="calendar__week-top-day">
                                {day}
                            </span>
                        </li>
                    ))}
                </ul>
            )}
            <ul className="calendar__week-top">
                {days.map(day => (
                    <li
                        key={day.lll}
                        className={`${
                            moment().format("ll") === day.ll
                                ? "calendar__week-top-item calendar__week-top-item--today"
                                : "calendar__week-top-item"
                        }`}
                    >
                        <span className="calendar__week-top-day">
                            {day.day}
                        </span>
                        <span className="calendar__week-top-date">
                            {day.date}
                        </span>
                    </li>
                ))}
            </ul>
            <CalendarBody
                buttonData={buttonData}
                buttonItem={buttonItem}
                data={data}
                setData={setData}
                days={days}
                buttonClickAdd={buttonClickAdd}
                buttonClickEdit={buttonClickEdit}
                date={date}
                view={view}
                gameCredits={gameCredits}
                handleEditGame={handleEditGame}
            />
        </div>
    )
}
